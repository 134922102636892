import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";

import Card from "components/card";
import { Typography, IconButton, Container, Grid } from "@mui/material";
import { TypographySpan } from "components/styledComponents";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,

    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
} from '@mui/material';

import "./hazard.scss";
import styles from '../../styles/_sys.scss';
import { updateShiftChecklist } from "redux/shiftRedux/shiftActions";
import EditIcon from "static/icons/editicon";
import ChecklistIcon from "static/icons/checklisticon";

function Hazard({title, issues, type, editable, mode, collapsed}) {
    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);

    const [hazards, setHazards] = useState([...issues]);
    const [hazardsWrap, setHazardsWrap] = useState([]);

    const [showCard, setShowCard] = useState(false);
    const [hazardsModal, setHazardsModal] = useState(false);

    const openHazardsModal = () => {
        setHazardsModal(true);
    }

    const closeHazardsModal = () => {
        setUpdatedSelectedIssues(Object.fromEntries(selectedIssues));
        setHazardsModal(false);
    }

    function toTitleCase(str) {
        const words = str.split('_');
        words.pop();
        const capitalizedWords = words?.map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        });
        const titleCaseStr = capitalizedWords?.join(' ');
        return titleCaseStr;
    }

    const chunkify = function* (itr, size) {
        let chunk = [];
        for (const v of itr) {
          chunk.push(v);
          if (chunk.length === size) {
            yield chunk;
            chunk = [];
          }
        }
        if (chunk.length) yield chunk;
      };


    const initialState = issues.reduce((acc, data) => {
        acc[data.name] = data.error;
        return acc;
    }, {});

    const [selectedIssues, setSelectedIssues] = useState(Object.entries(initialState));
    const [updatedSelectedIssues, setUpdatedSelectedIssues] = useState(initialState);

    const handleHazardChange = (groupName) => (event) => {
        const newValue = event.target.value === 'true';
        setUpdatedSelectedIssues({
            ...updatedSelectedIssues,
            [groupName]: newValue,
        });
    };

    const saveHazards = () => {
        const entries = Object.entries(updatedSelectedIssues);
        let hazards = [];

        entries.forEach(([key, value]) => {
            hazards.push({hazardType: type, error: value, name: key});
        })

        dispatch(updateShiftChecklist({prestartId: reducerState?.shift?.shiftData?.preStartChecklist?.ID, checklist: hazards, type: type}));

        setSelectedIssues(Object.entries(updatedSelectedIssues));
        setHazardsModal(false);
    }

    const isDisabled = () => {
        if (JSON.stringify(Object.fromEntries(selectedIssues)) !== JSON.stringify(updatedSelectedIssues)) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (selectedIssues.length > 30) {
            const size = Math.round(selectedIssues.length/2, 1);
            let hazardsArr = [...chunkify(selectedIssues, size)];
            hazardsArr.forEach(arr => {
                if (arr.length !== size) {
                    let difference = size-arr.length;
                    
                    for(let i = 0; i<difference; i++) {
                        arr.push(["empty_issue_new", true]); //pushes extra items into array and hides in ui so that the heights of the hazards don't mess up
                    }
                }
            })
            setHazardsWrap([...hazardsArr]);
        }
        setShowCard(true);
    }, [selectedIssues]);

  return (
    <>
        {showCard && (
            <Card title={title} 
            content={
                <div className="hazards">
                    <Grid>
                        {hazards.length > 30 && mode !== "normal" ? (
                            <Grid container xs={12} sm={12} md={12} lg={12} gap={2}>
                                {hazardsWrap.map((x) => {
                                    return (
                                        <Grid container xs={11.5/hazardsWrap.length} sm={11.5/hazardsWrap.length} md={11.5/hazardsWrap.length} lg={11.5/hazardsWrap.length}>
                                            {x.map(([key, value]) => {
                                                return (
                                                    <Grid container className="issues" style={{visibility: key === 'empty_issue_new' ? 'hidden' : 'visible'}}>
                                                        <Grid container className="line">
                                                            <Grid xs={9} sm={9} md={9} lg={9} item className="label" sx={{backgroundColor: value ? styles.errorRedBackground : '#f4f6f8'}}><TypographySpan color={value ? styles.errorRed : ''}>{toTitleCase(key)}</TypographySpan></Grid>
                                                            <Grid xs={3} sm={3} md={3} lg={3} item className="data" sx={{backgroundColor: value ? styles.errorRedBackground : ''}}><TypographySpan sx={{color: value ? styles.errorRed : '', fontWeight: value ? '600' : '400'}}>{value ? 'FAULT' : 'OK'}</TypographySpan></Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    )
                                })}
                            </Grid>    
                        ) : (
                            <Grid container>
                                {selectedIssues.map(([key, value]) => {
                                    return (
                                        <Grid container className="issues">
                                            <Grid container className="line">
                                                <Grid xs={9} sm={9} md={9} lg={9} item className="label" sx={{backgroundColor: value ? styles.errorRedBackground : '#f4f6f8'}}><TypographySpan color={value ? styles.errorRed : ''}>{toTitleCase(key)}</TypographySpan></Grid>
                                                <Grid xs={3} sm={3} md={3} lg={3} item className="data" sx={{backgroundColor: value ? styles.errorRedBackground : ''}}><TypographySpan sx={{color: value ? styles.errorRed : '', fontWeight: value ? '600' : '400'}}>{value ? 'FAULT' : 'OK'}</TypographySpan></Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        )}
                        
                    </Grid>
                </div>
            }
            edit={
                <>
                    {editable ? (
                        <IconButton className="edit" aria-label="edit" size="small" onClick={openHazardsModal}>
                            <EditIcon/>
                        </IconButton>
                    ) : (
                        <></>
                    )}
                </>
            }
            canCollapse={true}
            collapsed={collapsed}>
            </Card>
        )}
        
        <Dialog
            className="hazards-modal"
            fullWidth={true}
            maxWidth={hazards.length > 30 && mode !== "normal" ? 'lg' : 'sm'}
            open={hazardsModal}
            onClose={closeHazardsModal}>
            <DialogTitle className="dialog-title">
            <ChecklistIcon/>
            <TypographySpan>{title}</TypographySpan>
            </DialogTitle>
            <DialogContent>
            <DialogContentText className="dialog-content">
                <Container>
                    {hazards.length > 30 && mode !== 'normal' ? (
                        <Grid container xs={12} sm={12} md={12} lg={12} gap={2}>
                            {hazardsWrap.map((x) => {
                                return (
                                    <Grid container xs={11.5/hazardsWrap.length} sm={11.5/hazardsWrap.length} md={11.5/hazardsWrap.length} lg={11.5/hazardsWrap.length}>
                                        {x.map(([key, value]) => {
                                            return (
                                                <Grid container className="issues" sx={{backgroundColor: updatedSelectedIssues[key] ? styles.errorRedBackground : '', visibility: key === 'empty_issue_new' ? 'hidden' : 'visible'}}>
                                                    <Grid container className="line">
                                                        <Grid xs={7} sm={7} md={7} lg={7} item className="label" sx={{backgroundColor: updatedSelectedIssues[key] ? styles.errorRedBackground : '#f4f6f8'}}><TypographySpan color={updatedSelectedIssues[key] ? styles.errorRed : ''}>{toTitleCase(key)}</TypographySpan></Grid>
                                                        <Grid xs={5} sm={5} md={5} lg={5} item className="data">
                                                            <FormControl>
                                                                <RadioGroup
                                                                    row
                                                                    value={updatedSelectedIssues[key]}
                                                                    onChange={handleHazardChange(key)}
                                                                    name={key}
                                                                    >
                                                                    <FormControlLabel key={1} value={false} control={<Radio />} label="OK" />
                                                                    <FormControlLabel key={2} value={true} control={<Radio sx={{color: updatedSelectedIssues[key] ? styles.errorRed : '','&.Mui-checked': {color: updatedSelectedIssues[key] ? styles.errorRed : ''}}} />} label={<TypographySpan variant="caption" sx={{color: updatedSelectedIssues[key] ? styles.errorRed : ''}}>FAULT</TypographySpan>} />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                )
                            })}
                        </Grid>    
                    ) : (
                        <Grid container>
                            {selectedIssues.map(([key, value]) => {
                                return (
                                    <Grid container className="issues" sx={{backgroundColor: updatedSelectedIssues[key] ? styles.errorRedBackground : '', visibility: key === 'empty_issue_new' ? 'hidden' : 'visible'}}>
                                        <Grid container className="line">
                                            <Grid xs={7} sm={7} md={7} lg={7} item className="label" sx={{backgroundColor: updatedSelectedIssues[key] ? styles.errorRedBackground : '#f4f6f8'}}><TypographySpan color={updatedSelectedIssues[key] ? styles.errorRed : ''}>{toTitleCase(key)}</TypographySpan></Grid>
                                            <Grid xs={5} sm={5} md={5} lg={5} item className="data">
                                                <FormControl>
                                                    <RadioGroup
                                                        row
                                                        value={updatedSelectedIssues[key]}
                                                        onChange={handleHazardChange(key)}
                                                        name={key}
                                                        >
                                                        <FormControlLabel key={1} value={false} control={<Radio />} label="OK" />
                                                        <FormControlLabel key={2} value={true} control={<Radio sx={{color: updatedSelectedIssues[key] ? styles.errorRed : '','&.Mui-checked': {color: updatedSelectedIssues[key] ? styles.errorRed : ''}}} />} label={<TypographySpan variant="caption" sx={{color: updatedSelectedIssues[key] ? styles.errorRed : ''}}>FAULT</TypographySpan>} />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    )}
                </Container>
            </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-actions">
            <Button variant="contained" color="cancel" onClick={closeHazardsModal}>Cancel</Button>
            <Button variant="contained" onClick={saveHazards} autoFocus disabled={isDisabled()}>
                Save
            </Button>
            </DialogActions>
        </Dialog>
    </>
  );
}

export default Hazard;
