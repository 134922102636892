import { createSlice } from "@reduxjs/toolkit";

//Import actions
import { fetchShiftData, 
  fetchShiftReportData, 
  fetchAllShiftsForVehicleBySerial, 
  fetchFullShiftData,
  fetchShiftDrillTasks,
  fetchAvailableDrillTasks,
  fetchPrestartData,
  updateShiftNotes,
  updatePrestartNotes,
  updateShiftDrillingTime,
  updateShiftRockTools,
  updateShiftApproval,
  updatePrestartApproval,
  updateShiftDelays,
  updateShiftMachineHours,
  updatePrestartMachineHours,
  updateShiftChecklist,
  updateShiftFitness,
  updatePrestartFluidLevels,
  updateDrillingHole,
  updateExtraHole,
  removeExtraHole,
  fetchDrillTask,
  fetchShiftReportDataWithoutLoader,
  fetchFullShiftDataWithoutLoader,
  updateApproval,
  generatePDF,
 } from "redux/shiftRedux/shiftActions";

const initialState = {
  vehicles: [],
  patterns: [],
  shiftData: [],
  availableDrillTasks: [],
};

export const shiftSlice = createSlice({
  name: "shiftReducer",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // // FETCH SHIFT DATA REDUCER
      .addCase(fetchShiftData.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchShiftData.fulfilled, (state, action) => {
        state.vehicles = action.payload.vehicleList;
        state.patterns = action.payload.patterns;
        state.isLoading = false;
      })
      .addCase(fetchShiftData.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(fetchShiftReportData.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchShiftReportData.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchShiftReportData.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(fetchShiftReportDataWithoutLoader.pending, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchShiftReportDataWithoutLoader.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchShiftReportDataWithoutLoader.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(fetchAllShiftsForVehicleBySerial.pending, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchAllShiftsForVehicleBySerial.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchAllShiftsForVehicleBySerial.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(fetchFullShiftData.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchFullShiftData.fulfilled, (state, action) => {
        state.shiftData = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchFullShiftData.rejected, (state, action) => {
        state.isLoading = false;
      })
      
      .addCase(fetchFullShiftDataWithoutLoader.pending, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchFullShiftDataWithoutLoader.fulfilled, (state, action) => {
        state.shiftData = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchFullShiftDataWithoutLoader.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(fetchShiftDrillTasks.pending, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchShiftDrillTasks.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchShiftDrillTasks.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(fetchAvailableDrillTasks.pending, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchAvailableDrillTasks.fulfilled, (state, action) => {
        state.availableDrillTasks = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchAvailableDrillTasks.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(fetchDrillTask.pending, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchDrillTask.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchDrillTask.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(fetchPrestartData.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchPrestartData.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchPrestartData.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(updateShiftNotes.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateShiftNotes.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateShiftNotes.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(updatePrestartNotes.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updatePrestartNotes.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updatePrestartNotes.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(updateShiftDrillingTime.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateShiftDrillingTime.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateShiftDrillingTime.rejected, (state, action) => {
        state.isLoading = false;
      })
      
      .addCase(updateShiftRockTools.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateShiftRockTools.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateShiftRockTools.rejected, (state, action) => {
        state.isLoading = false;
      })
      
      .addCase(updateShiftApproval.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateShiftApproval.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateShiftApproval.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(updatePrestartApproval.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updatePrestartApproval.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updatePrestartApproval.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(updateShiftDelays.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateShiftDelays.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateShiftDelays.rejected, (state, action) => {
        state.isLoading = false;
      })
      
      .addCase(updateShiftMachineHours.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateShiftMachineHours.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateShiftMachineHours.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(updatePrestartMachineHours.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updatePrestartMachineHours.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updatePrestartMachineHours.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(updateShiftChecklist.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateShiftChecklist.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateShiftChecklist.rejected, (state, action) => {
        state.isLoading = false;
      })
      
      .addCase(updateShiftFitness.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateShiftFitness.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateShiftFitness.rejected, (state, action) => {
        state.isLoading = false;
      })
      
      .addCase(updatePrestartFluidLevels.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updatePrestartFluidLevels.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updatePrestartFluidLevels.rejected, (state, action) => {
        state.isLoading = false;
      })
      
      .addCase(updateDrillingHole.pending, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateDrillingHole.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateDrillingHole.rejected, (state, action) => {
        state.isLoading = false;
      })
      
      .addCase(updateExtraHole.pending, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateExtraHole.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateExtraHole.rejected, (state, action) => {
        state.isLoading = false;
      })
      
      .addCase(removeExtraHole.pending, (state, action) => {
        state.isLoading = false;
      })
      .addCase(removeExtraHole.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(removeExtraHole.rejected, (state, action) => {
        state.isLoading = false;
      })
      
      .addCase(updateApproval.pending, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateApproval.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateApproval.rejected, (state, action) => {
        state.isLoading = false;
      })
      
      .addCase(generatePDF.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(generatePDF.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(generatePDF.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});
