import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";


import Card from "components/card";
import { Typography, Grid } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';

import "./fitness.scss";
import styles from "../../styles/_sys.scss";
import { TypographySpan } from "components/styledComponents";
import { updateShiftFitness } from "redux/shiftRedux/shiftActions";

function Fitness({checked, editable}) {
    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);

    const [statement, setStatement] = useState(checked);
    const [showCard, setShowCard] = useState(false);

    useEffect(() => {
        setShowCard(true);
    }, [statement]);

    const handleFitnessChange = (event) => {
        if (editable) {
            dispatch(updateShiftFitness({prestartId: reducerState?.shift?.shiftData?.preStartChecklist?.ID, fitness: event?.target?.checked}));

            setStatement(event?.target?.checked);
        }
    }

  return (
    <>
        {showCard && (
            <Card title='Statement of Fitness' 
            content={
                <Grid container xs={12} sm={12} md={12} lg={12} className="fitness">
                    <Grid item xs={12} sm={12} md={12} lg={12} display={'flex'} alignItems={'center'} border={'1px solid ' + styles.border} borderRadius={'4px'}>
                        <Checkbox checked={statement} {...'fitness'} defaultChecked /> 
                        {/* <Checkbox checked={statement} {...'a'} onChange={handleFitnessChange} defaultChecked />  */}
                        <TypographySpan variant="caption">I hereby assure that I am not under the influence of alcohol, narcotics or medication and I am mentally and physically fit to operate the machine. </TypographySpan>
                    </Grid>
                </Grid>
            }>
            </Card>
        )}
    </>
  );
}

export default Fitness;
