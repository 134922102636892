import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";

import Card from "components/card";
import { Typography, IconButton, Grid, TextField, InputAdornment, RadioGroup, Select, MenuItem } from "@mui/material";
import { TypographySpan } from "components/styledComponents";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { TimelapseOutlined } from "@mui/icons-material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { operationalDelays as oDelays } from "static/delays";
import { mechanicalBreakdown as mBreakdowns } from "static/delays";
import { mechanicalNonProd as nProd } from "static/delays";

import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import "./reporteddelays.scss";
import { updateShiftDelays } from "redux/shiftRedux/shiftActions";
import EditIcon from "static/icons/editicon";
import { validateFloat, validateInt } from "utils/tools";

function ReportedDelays({operational, mechanical, nonproductive, editable, collapsed}) {
    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);

    const [showCard, setShowCard] = useState(false);
    const endTime = "0001-01-01T00:00:00Z";

    useEffect(() => {
        if (operational) {
            setOperationalDelays(operational);
            setUpdatedOperationalDelays(operational);
        }
        if (mechanical) {
            setMechanicalDelays(mechanical);
            setUpdatedMechanicalDelays(mechanical);
        }
        if (nonproductive) {
            setNonProductiveDelays(nonproductive);
            setUpdatedNonProductiveDelays(nonproductive);
        }
    }, []);

    useEffect(() => {
        setShowCard(true);
    }, [operationalDelays, mechanicalDelays, nonProductiveDelays]);

    // COMMON FUNCTIONS - WILL NEED TO ADD SEPARATELY
    function toTitleCase(str) {
        const words = str.split('_');
        
        const capitalizedWords = words?.map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        });
        
        const titleCaseStr = capitalizedWords?.join(' ');
        
        return titleCaseStr;
    }
    const DateToISO = (date) => {
        const isoDate = new Date(date);
      
        const isoDateString = isoDate?.toISOString();
      
        return isoDateString
    };
    const ISOToTime = (date) => {
        const isoDate = new Date(date);
      
        const timeString = isoDate?.toLocaleTimeString('en-au', {
          hour12: false,
          hour: '2-digit',
          minute: '2-digit',
        });

        return timeString;
    };
    const MinToDuration = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        const formattedDuration = `${hours}h ${remainingMinutes}m`;

        return formattedDuration;
    }

    const isOperationalDisabled = () => {
        if (operationalDelays !== updatedOperationalDelays) {
            return false;
        }
        return true;
    }

    const isNonProductiveDisabled = () => {
        if (nonProductiveDelays !== updatedNonProductiveDelays) {
            return false;
        }
        return true;
    }
    
    // ALL CODE NEEDED FOR OPERATIONAL DELAYS (COPY FOR MECHANICAL AND NON PRODUCTIVE)

    const [operationalDelays, setOperationalDelays] = useState([]);
    const [updatedOperationalDelays, setUpdatedOperationalDelays] = useState([]);

    const [selectedOperationalDelay, setSelectedOperationalDelay] = useState('');
    const [showOperational, setShowOperational] = useState(true);


    const [newOperationalDelay, setNewOperationalDelay] = useState();
    const [newOperationalDelayName, setNewOperationalDelayName] = useState('');
    const [newOperationalDelayStart, setNewOperationalDelayStart] = useState(new Date().toISOString());
    const [newOperationalDelayDuration, setNewOperationalDelayDuration] = useState(0);

    const [newOperationalDelayDisabled, setNewOperationalDelayDisabled] = useState(true);

    const [operationalDelayModal, setOperationalDelayModal] = useState(false);

    const [updatedOperationalDelayStart, setUpdatedOperationalDelayStart] = useState('');
    const [updatedOperationalDelayDuration, setUpdatedOperationalDelayDuration] = useState('');


    const handleOperationalDelayChange = (event) => {
        setSelectedOperationalDelay(event?.target?.value);
    };

    const operationalDelayStartChange = (x, event) => {
        // setUpdatedOperationalDelayStart(DateToISO(event?.$d));

        let delays = [];
        updatedOperationalDelays.forEach(d => {
            if (d?.startTime === x?.startTime) {
                delays.push({type: 'operational', name: d?.name, startTime: DateToISO(event?.$d), createdAt: d?.createdAt, duration: parseInt(d?.duration)});
            }
            else {
                delays.push(d);
            }
        })

        setSelectedOperationalDelay(DateToISO(event?.$d));
        setUpdatedOperationalDelays(delays);
    };
    const operationalDelayDurationChange = (x, event) => {
        setUpdatedOperationalDelayDuration(event?.target?.value);

        let delays = [];
        updatedOperationalDelays.forEach(d => {
            if (d?.startTime === x?.startTime) {
                delays.push({type: 'operational', name: d?.name, startTime: d?.startTime, createdAt: d?.createdAt, duration: parseInt(event?.target?.value)});
            }
            else {
                delays.push(d);
            }
        })

        setUpdatedOperationalDelays(delays);
    };

    const openOperationalDelaysModal = () => {
        setOperationalDelayModal(true);
    }

    const closeOperationalDelaysModal = () => {
        setUpdatedOperationalDelays(operationalDelays);
        setOperationalDelayModal(false);
    }

    const handleNewOperationalDelayName = (event) => {
        setNewOperationalDelayName(event?.target?.value);
    }
    const handleNewOperationalDelayStart = (event) => {
        if (event === null) {
            setNewOperationalDelayStart(new Date().toISOString());
            return;
        }
        if (!isNaN(event.$y)) {
            setNewOperationalDelayStart(DateToISO(event?.$d));
        }
    }
    const handleNewOperationalDelayDuration = (event) => {
        setNewOperationalDelayDuration(event?.target?.value);
    }

    const CheckOperationalDelayOverlap = () => {
        
        const start = Math.floor(new Date(newOperationalDelayStart).getTime()/60000);
        const end = start + parseInt(newOperationalDelayDuration);
        for (let ii = 0; ii < updatedOperationalDelays.length; ii++) {
            const existingStart = Math.floor(new Date(updatedOperationalDelays[ii].startTime).getTime()/60000);
            const existingEnd = existingStart + updatedOperationalDelays[ii]?.duration;
            if (start < existingEnd && end > existingStart) {
                return true; //They are overlapping
            }
        }
        return false; //No Overlap
    }

    const addNewOperationalDelay = () => {
        setNewOperationalDelay({type: 'operational', name: newOperationalDelayName, startTime: newOperationalDelayStart, createdAt: new Date().toISOString(), duration: parseInt(newOperationalDelayDuration)});
    }

    const deleteOperationalDelay = (delay) => {
        setShowOperational(false);
        setUpdatedOperationalDelays(updatedOperationalDelays?.filter((d) => d !== delay));
    }

    const saveOperationalDelays = () => {
        dispatch(updateShiftDelays({shiftId: reducerState.shift.shiftData.shiftReport._id, delays: updatedOperationalDelays, type: 'operational'}));

        setOperationalDelays(updatedOperationalDelays);
        setSelectedOperationalDelay('');
        setOperationalDelayModal(false);
    }

    useEffect(() => {
        setNewOperationalDelayDisabled(true);
        if (newOperationalDelayName !== '') {
            if (newOperationalDelayStart !== '' && newOperationalDelayStart !== null) {
                if (parseInt(newOperationalDelayDuration) !== 0 && !isNaN(parseInt(newOperationalDelayDuration))) {
                    if (!CheckOperationalDelayOverlap()) {
                        setNewOperationalDelayDisabled(false);
                    }
                }
            }
        }
    }, [newOperationalDelayName, newOperationalDelayStart, newOperationalDelayDuration])

    useEffect(() => {
        if (newOperationalDelay) {
            setUpdatedOperationalDelays(delays => [...delays, newOperationalDelay]);
            clearOperationalDelay();
        }
    }, [newOperationalDelay])

    useEffect(() => {
        if (updatedOperationalDelays) {
            setShowOperational(true);
        }
    }, [updatedOperationalDelays])

    const clearOperationalDelay = () => {
        setNewOperationalDelay();
        setNewOperationalDelayDuration(0);
        setNewOperationalDelayName('');
        setNewOperationalDelayStart(new Date().toISOString());
        setNewOperationalDelayDisabled(true);
    }




    const [nonProductiveDelays, setNonProductiveDelays] = useState([]);
    const [updatedNonProductiveDelays, setUpdatedNonProductiveDelays] = useState([]);

    const [selectedNonProductiveDelay, setSelectedNonProductiveDelay] = useState('');
    const [showNonProds, setShowNonProds] = useState(true);


    const [newNonProductiveDelay, setNewNonProductiveDelay] = useState();
    const [newNonProductiveDelayName, setNewNonProductiveDelayName] = useState('');
    const [newNonProductiveDelayStart, setNewNonProductiveDelayStart] = useState(new Date().toISOString());
    const [newNonProductiveDelayDuration, setNewNonProductiveDelayDuration] = useState(0);

    const [newNonProductiveDelayDisabled, setNewNonProductiveDelayDisabled] = useState(true);

    const [nonProductiveDelayModal, setNonProductiveDelayModal] = useState(false);

    const [updatedNonProductiveDelayStart, setUpdatedNonProductiveDelayStart] = useState('');
    const [updatedNonProductiveDelayDuration, setUpdatedNonProductiveDelayDuration] = useState('');


    const handleNonProductiveDelayChange = (event) => {
        setSelectedNonProductiveDelay(event?.target?.value);
    };

    const nonProductiveDelayStartChange = (x, event) => {
        setUpdatedNonProductiveDelayStart(DateToISO(event?.$d));

        let delays = [];
        updatedNonProductiveDelays.forEach(d => {
            if (d?.startTime === x?.startTime) {
                delays.push({type: 'nonprod', name: d?.name, startTime: DateToISO(event?.$d), createdAt: d.createdAt, duration: parseInt(d?.duration)});
            }
            else {
                delays.push(d);
            }
        })

        setSelectedNonProductiveDelay(DateToISO(event?.$d));
        setUpdatedNonProductiveDelays(delays);
    };
    const nonProductiveDelayDurationChange = (x, event) => {
        setUpdatedNonProductiveDelayDuration(event?.target?.value);

        let delays = [];
        updatedNonProductiveDelays.forEach(d => {
            if (d.startTime === x.startTime) {
                delays.push({type: 'nonprod', name: d?.name, startTime: d?.startTime, createdAt: d?.createdAt, duration: parseInt(event?.target?.value)});
            }
            else {
                delays.push(d);
            }
        })

        setUpdatedNonProductiveDelays(delays);
    };

    const openNonProductiveDelaysModal = () => {
        setNonProductiveDelayModal(true);
    }

    const closeNonProductiveDelaysModal = () => {
        setUpdatedNonProductiveDelays(nonProductiveDelays);
        setNonProductiveDelayModal(false);
    }

    const handleNewNonProductiveDelayName = (event) => {
        setNewNonProductiveDelayName(event?.target?.value);
    }
    const handleNewNonProductiveDelayStart = (event) => {
        if (event === null) {
            setNewNonProductiveDelayStart(new Date().toISOString());
            return;
        }
        if (!isNaN(event.$y)) {
            setNewNonProductiveDelayStart(DateToISO(event?.$d));
        }
        
    }
    const handleNewNonProductiveDelayDuration = (event) => {
        setNewNonProductiveDelayDuration(event?.target?.value);
    }

    const addNewNonProductiveDelay = () => {
        setNewNonProductiveDelay({type: 'nonprod', name: newNonProductiveDelayName, startTime: newNonProductiveDelayStart, createdAt: new Date().toISOString(), duration: parseInt(newNonProductiveDelayDuration)});
    }

    const deleteNonProductiveDelay = (delay) => {
        setShowNonProds(false);
        setUpdatedNonProductiveDelays(updatedNonProductiveDelays?.filter((d) => d !== delay));
    }

    const saveNonProductiveDelays = () => {
        dispatch(updateShiftDelays({shiftId: reducerState.shift.shiftData.shiftReport._id, delays: updatedNonProductiveDelays, type: 'nonprod'}));

        setNonProductiveDelays(updatedNonProductiveDelays);
        setSelectedNonProductiveDelay('');
        setNonProductiveDelayModal(false);
    }

    useEffect(() => {
        setNewNonProductiveDelayDisabled(true);
        if (newNonProductiveDelayName !== '') {
            if (newNonProductiveDelayStart !== '' && newNonProductiveDelayStart !== null) {
                if (parseInt(newNonProductiveDelayDuration) !== 0 && !isNaN(parseInt(newNonProductiveDelayDuration))) {
                    setNewNonProductiveDelayDisabled(false);
                }
            }
        }
    }, [newNonProductiveDelayName, newNonProductiveDelayStart, newNonProductiveDelayDuration])

    useEffect(() => {
        if (newNonProductiveDelay) {
            setUpdatedNonProductiveDelays(delays => [...delays, newNonProductiveDelay]);
            clearNonProductiveDelay();
        }
    }, [newNonProductiveDelay])

    const clearNonProductiveDelay = () => {
        setNewNonProductiveDelay();
        setNewNonProductiveDelayDuration(0);
        setNewNonProductiveDelayName('');
        setNewNonProductiveDelayStart(new Date().toISOString());
        setNewNonProductiveDelayDisabled(true);
    }

    useEffect(() => {
        if (updatedNonProductiveDelays) {
            setShowNonProds(true);
        }
    }, [updatedNonProductiveDelays])


    const [mechanicalDelays, setMechanicalDelays] = useState([]);
    const [updatedMechanicalDelays, setUpdatedMechanicalDelays] = useState([]);

    const [selectedMechanicalDelay, setSelectedMechanicalDelay] = useState('');


    const [newMechanicalDelay, setNewMechanicalDelay] = useState();
    const [newMechanicalDelayName, setNewMechanicalDelayName] = useState('');
    const [newMechanicalDelayStart, setNewMechanicalDelayStart] = useState(new Date().toISOString());
    const [newMechanicalDelayDuration, setNewMechanicalDelayDuration] = useState(0);

    const [newMechanicalDelayDisabled, setNewMechanicalDelayDisabled] = useState(true);

    const [mechanicalDelayModal, setMechanicalDelayModal] = useState(false);

    const [updatedMechanicalDelayStart, setUpdatedMechanicalDelayStart] = useState('');
    const [updatedMechanicalDelayDuration, setUpdatedMechanicalDelayDuration] = useState('');


    const handleMechanicalDelayChange = (event) => {
        setSelectedMechanicalDelay(event?.target?.value);
    };

    const mechanicalDelayStartChange = (x, event) => {
        setUpdatedMechanicalDelayStart(DateToISO(event?.$d));

        let delays = [];
        updatedMechanicalDelays.forEach(d => {
            if (d?.startTime === x?.startTime) {
                delays.push({name: d?.name, startTime: DateToISO(event?.$d), createdAt: d?.createdAt, duration: d?.duration});
            }
            else {
                delays.push(d);
            }
        })

        setSelectedMechanicalDelay(DateToISO(event?.$d));
        setUpdatedMechanicalDelays(delays);
    };
    const mechanicalDelayDurationChange = (x, event) => {
        setUpdatedMechanicalDelayDuration(event?.target?.value);

        let delays = [];
        updatedMechanicalDelays.forEach(d => {
            if (d.startTime === x.startTime) {
                delays.push({name: d?.name, startTime: d?.startTime, createdAt: d?.createdAt, duration: event?.target?.value});
            }
            else {
                delays.push(d);
            }
        })

        setUpdatedMechanicalDelays(delays);
    };

    const openMechanicalDelaysModal = () => {
        setMechanicalDelayModal(true);
    }

    const closeMechanicalDelaysModal = () => {
        setUpdatedMechanicalDelays(mechanicalDelays);
        setMechanicalDelayModal(false);
    }

    const handleNewMechanicalDelayName = (event) => {
        setNewMechanicalDelayName(event?.target?.value);
    }
    const handleNewMechanicalDelayStart = (event) => {
        if (event === null) {
            setNewMechanicalDelayStart(new Date().toISOString());
            return;
        }
        setNewMechanicalDelayStart(DateToISO(event?.$d));
        
    }
    const handleNewMechanicalDelayDuration = (event) => {
        setNewMechanicalDelayDuration(event?.target?.value);
    }

    const addNewMechanicalDelay = () => {
        setNewMechanicalDelay({type: 'mechanical', name: newMechanicalDelayName, startTime: newMechanicalDelayStart, createdAt: new Date().toISOString(), duration: parseInt(newMechanicalDelayDuration)});
    }

    const deleteMechanicalDelay = (delay) => {
        setUpdatedMechanicalDelays(updatedMechanicalDelays?.filter((d) => d !== delay));
    }

    const saveMechanicalDelays = () => {
        //save api

        setMechanicalDelays(updatedMechanicalDelays);
        setSelectedMechanicalDelay('');
        setMechanicalDelayModal(false);
    }

    useEffect(() => {
        if (newMechanicalDelayName !== '') {
            if (newMechanicalDelayStart !== '' && newMechanicalDelayStart !== null) {
                if (newMechanicalDelayDuration !== 0 && newMechanicalDelayDuration !== '') {
                    setNewMechanicalDelayDisabled(false);
                }
            }
        }
    }, [newMechanicalDelayName, newMechanicalDelayStart, newMechanicalDelayDuration])

    useEffect(() => {
        if (newMechanicalDelay) {
            setUpdatedMechanicalDelays(delays => [...delays, newMechanicalDelay]);
            clearMechanicalDelay();
        }
    }, [newMechanicalDelay])

    const clearMechanicalDelay = () => {
        setNewMechanicalDelay();
        setNewMechanicalDelayDuration(0);
        setNewMechanicalDelayName('');
        setNewMechanicalDelayStart(new Date().toISOString());
        setNewMechanicalDelayDisabled(true);
    }

  return (
    <>
        {showCard && (
            <Card title='Reported Delays' 
            content={
                <div className="delays">
                    <Grid>
                        <Grid container className="operational">
                            <Grid container className="line">
                                <Grid xs={6} sm={6} md={6} lg={6} item className="label"><TypographySpan>Operational Delays</TypographySpan></Grid>
                                <Grid xs={6} sm={6} md={6} lg={6} item className="data">
                                    {editable ? (
                                        <IconButton className="edit" aria-label="edit" size="small" onClick={openOperationalDelaysModal}>
                                            <EditIcon/>
                                        </IconButton>
                                    ) : (
                                        <></>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        {operationalDelays?.map((x) => {
                            return (
                                <Grid container className="operational">
                                    <Grid container className="line">
                                        <Grid xs={6} sm={6} md={6} lg={6} item className="label"><TypographySpan>{toTitleCase(x?.name)}</TypographySpan></Grid>
                                        <Grid container xs={6} sm={6} md={6} lg={6}>
                                            <Grid xs={6} sm={6} md={6} lg={6} item className="data"><TypographySpan>Start time: {ISOToTime(x?.startTime)}</TypographySpan></Grid>
                                            <Grid xs={6} sm={6} md={6} lg={6} item className="data"><TypographySpan>Duration: {MinToDuration(x?.duration)}</TypographySpan></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                    <Grid>
                        <Grid container className="mechanical">
                            <Grid container className="line">
                                <Grid xs={6} sm={6} md={6} lg={6} item className="label"><TypographySpan>Mechanical Breakdowns</TypographySpan></Grid>
                                <Grid xs={6} sm={6} md={6} lg={6} item className="data">
                                    {/* <IconButton className="edit" aria-label="edit" size="small" onClick={openMechanicalDelaysModal}>
                                        <ModeOutlinedIcon/>
                                    </IconButton> */}
                                </Grid>
                            </Grid>
                        </Grid>
                        {mechanicalDelays?.map((x) => {
                            return (
                                <Grid container className="mechanical">
                                    <Grid container className="line">
                                        <Grid xs={6} sm={6} md={6} lg={6} item className="label"><TypographySpan>{toTitleCase(x?.name)}</TypographySpan></Grid>
                                        <Grid container xs={6} sm={6} md={6} lg={6}>
                                            <Grid xs={6} sm={6} md={6} lg={6} item className="data"><TypographySpan>Start time: {ISOToTime(x?.startTime)}</TypographySpan></Grid>
                                            <Grid xs={6} sm={6} md={6} lg={6} item className="data">
                                                {x?.endTime === endTime ? (<></>) : (<TypographySpan>End time: {ISOToTime(x?.endTime)}</TypographySpan>)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                    <Grid>
                        <Grid container className="non-productive">
                            <Grid container className="line">
                                <Grid xs={6} sm={6} md={6} lg={6} item className="label"><TypographySpan>Mechanical Non-productive Delays</TypographySpan></Grid>
                                <Grid xs={6} sm={6} md={6} lg={6} item className="data">
                                {editable ? (
                                        <IconButton className="edit" aria-label="edit" size="small" onClick={openNonProductiveDelaysModal}>
                                            <EditIcon/>
                                        </IconButton>
                                    ) : (
                                        <></>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        {nonProductiveDelays?.map((x) => {
                            return (
                                <Grid container className="non-productive">
                                    <Grid container className="line">
                                        <Grid xs={6} sm={6} md={6} lg={6} item className="label"><TypographySpan>{toTitleCase(x?.name)}</TypographySpan></Grid>
                                        <Grid container xs={6} sm={6} md={6} lg={6}>
                                            {/* <Grid xs={6} sm={6} md={6} lg={6} item className="data"><TypographySpan>Start time: {ISOToTime(x?.startTime)}</TypographySpan></Grid> */}
                                            <Grid xs={12} sm={12} md={12} lg={12} item className="data"><TypographySpan>Duration: {MinToDuration(x?.duration)}</TypographySpan></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
            }
            canCollapse={true}
            collapsed={collapsed}>
            </Card>
        )}
        
        <Dialog
            className="operational-modal"
            fullWidth={true}
            maxWidth='xl'
            open={operationalDelayModal}
            onClose={closeOperationalDelaysModal}>
            <DialogTitle className="dialog-title">
            <TimelapseOutlined/> 
            <TypographySpan>Edit Operational Delays</TypographySpan>
            </DialogTitle>
            <DialogContent>
            <DialogContentText className="dialog-content">
                    <Grid container xs={12} sm={12} md={12} lg={12}>
                        {showOperational && (
                            <FormControl>
                                <RadioGroup
                                value={selectedOperationalDelay}
                                onChange={handleOperationalDelayChange}
                                row>
                                    {updatedOperationalDelays?.map((x) => {
                                        return (
                                            <Grid container xs={12} sm={12} md={12} lg={12} gap={2} className="cont">
                                                <Grid item xs={12} sm={12} md={12} lg={4} className="title">
                                                    <FormControlLabel value={x?.startTime} control={<Radio />} label={toTitleCase(x?.name)}/>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={5} lg={3} className="start-time">
                                                    <TimePicker 
                                                        required
                                                        PaperProps={{sx: {minWidth: '400px'}}}
                                                        onChange={(e) => {operationalDelayStartChange(x, e)}}
                                                        renderInput={(params) => (
                                                            <TextField variant="standard" {...params} />
                                                        )}
                                                        inputProps={{readOnly: true}}
                                                        disabled={selectedOperationalDelay === x?.startTime ? false : true}
                                                        readOnly={selectedOperationalDelay === x?.startTime ? false : true}
                                                        label={<span>Start Time <span className="required">*</span></span>}
                                                        value={updatedOperationalDelayStart ? updatedOperationalDelayStart : x?.startTime}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={5} lg={3} className="duration">
                                                    <TextField required label="Duration (minutes)" onKeyDown={(e) => {if(!validateInt(e.key)) {e.preventDefault();}}} onChange={(e) => {operationalDelayDurationChange(x, e)}} variant="standard" defaultValue={x?.duration} InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: selectedOperationalDelay === x?.startTime ? '1' : '0.3'}}>mins</InputAdornment>, readOnly: selectedOperationalDelay === x?.startTime ? false : true}} disabled={selectedOperationalDelay === x?.startTime ? false : true}/>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={2} lg={1} className="delete">
                                                    {selectedOperationalDelay === x?.startTime ? (
                                                        <IconButton onClick={() => {deleteOperationalDelay(x)}}>
                                                            <DeleteOutlineOutlinedIcon style={{color: 'red'}}/>
                                                        </IconButton>
                                                    ) : (
                                                        <IconButton disabled>
                                                            <DeleteOutlineOutlinedIcon style={{color: 'red', opacity: '0.3'}}/>
                                                        </IconButton>
                                                    )}
                                                    
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                    <Grid container xs={12} sm={12} md={12} lg={12} gap={2} className="cont new">
                                        <Grid item xs={12} sm={12} md={12} lg={4} className="title">
                                            <FormControlLabel value="new" control={
                                                    <Radio /> 
                                            } label={
                                                <Select
                                                    title={toTitleCase(newOperationalDelayName)}
                                                    value={newOperationalDelayName}
                                                    disabled={selectedOperationalDelay === 'new' ? false : true}
                                                    onChange={handleNewOperationalDelayName}
                                                    displayEmpty
                                                    style={{height: '33px', minWidth: '300px', maxWidth: '300px'}}
                                                    variant="standard">

                                                    <MenuItem value="" disabled>
                                                        <TypographySpan variant="caption">Select...</TypographySpan>
                                                    </MenuItem>

                                                    {oDelays?.map((x) => {
                                                        return (
                                                            <MenuItem value={x?.name}>
                                                                <TypographySpan variant="caption">{toTitleCase(x?.name)}</TypographySpan>
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            } 
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={5} lg={3} className="start-time">
                                            <TimePicker 
                                                required
                                                PaperProps={{sx: {minWidth: '400px'}}}
                                                onChange={handleNewOperationalDelayStart}
                                                renderInput={(params) => (
                                                    <TextField
                                                        variant="standard" {...params}
                                                        error={newOperationalDelayDisabled}
                                                        // helperText={newOperationalDelayDisabled ? "Overlapping delays" : ""}
                                                    />
                                                )}
                                                inputProps={{readOnly: true}}
                                                disabled={selectedOperationalDelay === 'new' ? false : true}
                                                readOnly={selectedOperationalDelay === 'new' ? false : true}
                                                label={<span>Start Time <span className="required">*</span></span>}
                                                value={newOperationalDelayStart}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={5} lg={3} className="duration">
                                            <TextField required label="Duration (minutes)" onKeyDown={(e) => {if(!validateInt(e.key)) {e.preventDefault();}}} onChange={handleNewOperationalDelayDuration} variant="standard" defaultValue={newOperationalDelayDuration} InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: selectedOperationalDelay === 'new' ? '1' : '0.3'}}>mins</InputAdornment>, readOnly: selectedOperationalDelay === 'new' ? false : true}} disabled={selectedOperationalDelay === 'new' ? false : true}/>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={2} lg={1} className="add">
                                            <IconButton disabled={newOperationalDelayDisabled} onClick={addNewOperationalDelay}>
                                                <AddCircleIcon color="primary" style={{opacity: newOperationalDelayDisabled ? '0.3' : '1'}}/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        )}
                    </Grid>
            </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-actions">
            <Button variant="contained" color="cancel" onClick={closeOperationalDelaysModal}>Cancel</Button>
            <Button variant="contained" onClick={saveOperationalDelays} autoFocus disabled={isOperationalDisabled()}>
                Save
            </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            className="nonProductive-modal"
            fullWidth={true}
            maxWidth='lg'
            open={nonProductiveDelayModal}
            onClose={closeNonProductiveDelaysModal}>
            <DialogTitle className="dialog-title">
            <TimelapseOutlined/> 
            <TypographySpan>Edit Non-Productive Delays</TypographySpan>
            </DialogTitle>
            <DialogContent>
            <DialogContentText className="dialog-content">
                    <Grid container xs={12} sm={12} md={12} lg={12}>

                        {showNonProds && (
                            <FormControl>
                                <RadioGroup
                                value={selectedNonProductiveDelay}
                                onChange={handleNonProductiveDelayChange}
                                row>
                                    {updatedNonProductiveDelays?.map((x) => {
                                        return (
                                            <Grid container xs={12} sm={12} md={12} lg={12} gap={2} className="cont">
                                                <Grid item xs={12} sm={12} md={12} lg={4} className="title">
                                                    <FormControlLabel value={x?.startTime} control={<Radio />} label={toTitleCase(x?.name)}/>
                                                </Grid>
                                                {/* <Grid item xs={12} sm={12} md={5} lg={3} className="start-time">
                                                    <TimePicker 
                                                        required
                                                        PaperProps={{sx: {minWidth: '400px'}}}
                                                        onChange={(e) => {nonProductiveDelayStartChange(x, e)}}
                                                        renderInput={(params) => (
                                                            <TextField variant="standard" {...params} />
                                                        )}
                                                        disabled={selectedNonProductiveDelay === x.startTime ? false : true}
                                                        readOnly={selectedNonProductiveDelay === x.startTime ? false : true}
                                                        label={<span>Start Time <span className="required">*</span></span>}
                                                        value={updatedNonProductiveDelayStart ? updatedNonProductiveDelayStart : x.startTime}
                                                    />
                                                </Grid> */}
                                                <Grid item xs={12} sm={12} md={9} lg={6} className="duration">
                                                    <TextField required label="Duration (minutes)" onKeyDown={(e) => {if(!validateInt(e.key)) {e.preventDefault();}}} onChange={(e) => {nonProductiveDelayDurationChange(x, e)}} variant="standard" defaultValue={x?.duration} InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: selectedNonProductiveDelay === x?.startTime ? '1' : '0.3'}}>mins</InputAdornment>, readOnly: selectedNonProductiveDelay === x?.startTime ? false : true}} disabled={selectedNonProductiveDelay === x?.startTime ? false : true}/>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={2} lg={1} className="delete">
                                                    {selectedNonProductiveDelay === x?.startTime ? (
                                                        <IconButton onClick={() => {deleteNonProductiveDelay(x)}}>
                                                            <DeleteOutlineOutlinedIcon style={{color: 'red'}}/>
                                                        </IconButton>
                                                    ) : (
                                                        <IconButton disabled>
                                                            <DeleteOutlineOutlinedIcon style={{color: 'red', opacity: '0.3'}}/>
                                                        </IconButton>
                                                    )}
                                                    
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                    <Grid container xs={12} sm={12} md={12} lg={12} gap={2} className="cont new">
                                        <Grid item xs={12} sm={12} md={12} lg={4} className="title">
                                            <FormControlLabel value="new" control={
                                                    <Radio /> 
                                            } label={
                                                <Select
                                                    title={toTitleCase(newNonProductiveDelayName)}
                                                    value={newNonProductiveDelayName}
                                                    disabled={selectedNonProductiveDelay === 'new' ? false : true}
                                                    onChange={handleNewNonProductiveDelayName}
                                                    displayEmpty
                                                    style={{height: '33px', minWidth: '300px', maxWidth: '300px'}}
                                                    variant="standard">

                                                    <MenuItem value="" disabled>
                                                        <TypographySpan variant="caption">Select...</TypographySpan>
                                                    </MenuItem>

                                                    {nProd?.map((x) => {
                                                        return (
                                                            <MenuItem value={x?.name}>
                                                                <TypographySpan variant="caption">{toTitleCase(x?.name)}</TypographySpan>
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            } 
                                            />
                                        </Grid>
                                        {/* <Grid item xs={12} sm={12} md={5} lg={3} className="start-time">
                                            <TimePicker 
                                                required
                                                PaperProps={{sx: {minWidth: '400px'}}}
                                                onChange={handleNewNonProductiveDelayStart}
                                                renderInput={(params) => (
                                                    <TextField variant="standard" {...params} />
                                                )}
                                                disabled={selectedNonProductiveDelay === 'new' ? false : true}
                                                readOnly={selectedNonProductiveDelay === 'new' ? false : true}
                                                label={<span>Start Time <span className="required">*</span></span>}
                                                value={newNonProductiveDelayStart}
                                            />
                                        </Grid> */}
                                        <Grid item xs={12} sm={12} md={9} lg={6} className="duration">
                                            <TextField required label="Duration (minutes)" onKeyDown={(e) => {if(!validateInt(e.key)) {e.preventDefault();}}} onChange={handleNewNonProductiveDelayDuration} variant="standard" defaultValue={newNonProductiveDelayDuration} InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: selectedNonProductiveDelay === 'new' ? '1' : '0.3'}}>mins</InputAdornment>, readOnly: selectedNonProductiveDelay === 'new' ? false : true}} disabled={selectedNonProductiveDelay === 'new' ? false : true}/>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={2} lg={1} className="add">
                                            <IconButton disabled={newNonProductiveDelayDisabled} onClick={addNewNonProductiveDelay}>
                                                <AddCircleIcon color="primary" style={{opacity: newNonProductiveDelayDisabled ? '0.3' : '1'}}/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        )}   
                    </Grid>
            </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-actions">
            <Button variant="contained" color="cancel" onClick={closeNonProductiveDelaysModal}>Cancel</Button>
            <Button variant="contained" onClick={saveNonProductiveDelays} autoFocus disabled={isNonProductiveDisabled()}>
                Save
            </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            className="mechanical-modal"
            fullWidth={true}
            maxWidth='xl'
            open={mechanicalDelayModal}
            onClose={closeMechanicalDelaysModal}>
            <DialogTitle className="dialog-title">
            <TimelapseOutlined/> 
            <TypographySpan>Edit Mechanical Breakdowns</TypographySpan>
            </DialogTitle>
            <DialogContent>
            <DialogContentText className="dialog-content">
                    <Grid container xs={12} sm={12} md={12} lg={12}>

                        <FormControl>
                            <RadioGroup
                            value={selectedMechanicalDelay}
                            onChange={handleMechanicalDelayChange}
                            row>
                                {updatedMechanicalDelays?.map((x) => {
                                    return (
                                        <Grid container xs={12} sm={12} md={12} lg={12} gap={2} className="cont">
                                            <Grid item xs={12} sm={12} md={12} lg={4} className="title">
                                                <FormControlLabel value={x?.startTime} control={<Radio />} label={x?.name}/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={5} lg={3} className="start-time">
                                                <TimePicker 
                                                    required
                                                    PaperProps={{sx: {minWidth: '400px'}}}
                                                    onChange={(e) => {mechanicalDelayStartChange(x, e)}}
                                                    renderInput={(params) => (
                                                        <TextField variant="standard" {...params} />
                                                    )}
                                                    disabled={selectedMechanicalDelay === x?.startTime ? false : true}
                                                    readOnly={selectedMechanicalDelay === x?.startTime ? false : true}
                                                    label={<span>Start Time <span className="required">*</span></span>}
                                                    value={updatedMechanicalDelayStart ? updatedMechanicalDelayStart : x?.startTime}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={5} lg={3} className="duration">
                                                <TextField required label="Duration (minutes)" onChange={(e) => {mechanicalDelayDurationChange(x, e)}} variant="standard" defaultValue={x?.duration} InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: selectedMechanicalDelay === x.startTime ? '1' : '0.3'}}>mins</InputAdornment>, readOnly: selectedMechanicalDelay === x?.startTime ? false : true}} disabled={selectedMechanicalDelay === x?.startTime ? false : true}/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={2} lg={1} className="delete">
                                                {selectedMechanicalDelay === x?.startTime ? (
                                                    <IconButton onClick={() => {deleteMechanicalDelay(x)}}>
                                                        <DeleteOutlineOutlinedIcon style={{color: 'red'}}/>
                                                    </IconButton>
                                                ) : (
                                                    <IconButton disabled>
                                                        <DeleteOutlineOutlinedIcon style={{color: 'red', opacity: '0.3'}}/>
                                                    </IconButton>
                                                )}
                                                
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                                <Grid container xs={12} sm={12} md={12} lg={12} gap={2} className="cont">
                                    <Grid item xs={12} sm={12} md={12} lg={4} className="title">
                                        <FormControlLabel value="new" control={
                                                <Radio /> 
                                        } label={
                                            <Select
                                                value={newMechanicalDelayName}
                                                disabled={selectedMechanicalDelay === 'new' ? false : true}
                                                onChange={handleNewMechanicalDelayName}
                                                displayEmpty
                                                style={{height: '33px', minWidth: '300px', maxWidth: '300px'}}
                                                variant="standard">

                                                <MenuItem value="" disabled>
                                                    <TypographySpan variant="caption">Select...</TypographySpan>
                                                </MenuItem>

                                                {mBreakdowns?.map((x) => {
                                                    return (
                                                        <MenuItem value={toTitleCase(x?.name)}>
                                                            <TypographySpan variant="caption">{toTitleCase(x?.name)}</TypographySpan>
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        } 
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={5} lg={3} className="start-time">
                                        <TimePicker 
                                            required
                                            PaperProps={{sx: {minWidth: '400px'}}}
                                            onChange={handleNewMechanicalDelayStart}
                                            renderInput={(params) => (
                                                <TextField variant="standard" {...params} />
                                            )}
                                            disabled={selectedMechanicalDelay === 'new' ? false : true}
                                            readOnly={selectedMechanicalDelay === 'new' ? false : true}
                                            label={<span>Start Time <span className="required">*</span></span>}
                                            value={newMechanicalDelayStart}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={5} lg={3} className="duration">
                                        <TextField required label="Duration (minutes)" onChange={handleNewMechanicalDelayDuration} variant="standard" defaultValue={newMechanicalDelayDuration} InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: selectedMechanicalDelay === 'new' ? '1' : '0.3'}}>mins</InputAdornment>, readOnly: selectedMechanicalDelay === 'new' ? false : true}} disabled={selectedMechanicalDelay === 'new' ? false : true}/>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={2} lg={1} className="add">
                                        <IconButton disabled={newMechanicalDelayDisabled} onClick={addNewMechanicalDelay}>
                                            <AddCircleIcon color="primary" style={{opacity: newMechanicalDelayDisabled ? '0.3' : '1'}}/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
            </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-actions">
            <Button variant="contained" color="cancel" onClick={closeMechanicalDelaysModal}>Cancel</Button>
            <Button variant="contained" onClick={saveMechanicalDelays} autoFocus>
                Save
            </Button>
            </DialogActions>
        </Dialog>
    </>
    

    
  );
}

export default ReportedDelays;
