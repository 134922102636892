import React, {Fragment, useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";

import Card from "components/card";
import { Typography, IconButton, Grid, Button, Container, TablePagination } from "@mui/material";

import "./audit.scss";
import styles from "../../styles/_sys.scss";
import { fetchAuditLogs } from "redux/auditRedux/auditAction";
import matchers from "@testing-library/jest-dom/matchers";

function Audit({approved, approvalHistory, editable, mode, onApprovalClick}) {
    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);
    const nullDate = "0001-01-01T00:00:00Z";

    const [logs, setLogs] = useState()
    const [viewLogs, setViewLogs] = useState();
    const [page, setPage] = useState(0);
    const [show, setShow] = useState(false);

    const chunkify = function* (itr, size) {
      let chunk = [];
      for (const v of itr) {
        chunk.push(v);
        if (chunk.length === size) {
          yield chunk;
          chunk = [];
        }
      }
      if (chunk.length) yield chunk;
    };

    const formatDate = (iso) => {
      if (iso === nullDate) {
        return 'N/A';
      }
      const date = new Date(iso);
      return `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`;
    }

    const formatTime = (iso) => {
      if (iso === nullDate) {
        return 'N/A';
      }
      const date = new Date(iso);
      const timeString = date?.toLocaleTimeString('en-au', { 
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
      });
      return timeString;
    }

    const formatIfIncludesDate = (str) => {
      const regex = /\b\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}(?:\.\d{3})? [+-]\d{4} \w{3}\b/g;
      const matches = [...str.matchAll(regex)];
      if (matches.length > 0) {
        let updated = str;
        matches.forEach((match) => {
          updated = updated.replace(match[0], formatDate(match[0]) + " " + formatTime(match[0]));
        });
        return updated;
      } else {
          return str;
      }
    }

    const handlePageChange = (e, page) => {
        setPage(page);
    }

    useEffect(() => {
      dispatch(fetchAuditLogs()).then((data) => {
        setLogs(data?.payload);
      })
    }, [])

    useEffect(() => {
      if (logs) {
        setViewLogs([...chunkify(logs, 10)]);
      }
    }, [logs])

    useEffect(() => {
      if (viewLogs) {
        setShow(true);
      }
    }, [viewLogs])


  return (
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} padding='24px' className="audit">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="title">
            <Typography fontSize={'34px'}>Audit Log</Typography>
        </Grid>
        {show && (
            <Grid item xs={12} sm={12} md={12} lg={12} display={'flex'} flexDirection={'column'} className="table" marginTop={'8px'}>
              <Grid item className="header-row" xs={12} sm={12} md={12} lg={12} display={'flex'} flexDirection={'row'} paddingLeft={'12px'}>
                  <Grid item xs={1} sm={1} md={1} lg={1} display={'flex'} justifyContent={'left'} alignItems={'center'} title={'Date'}>
                      <Typography variant="caption">Date</Typography>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1} display={'flex'} justifyContent={'left'} alignItems={'center'} title={'Time'}>
                      <Typography variant="caption">Time</Typography>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1} display={'flex'} justifyContent={'left'} alignItems={'center'} className="border-left" title={'Machine ID'}>
                      <Typography variant="caption">Machine ID</Typography>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1} display={'flex'} justifyContent={'left'} alignItems={'center'} className="border-left" title={'User ID'}>
                      <Typography variant="caption">User ID</Typography>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1} display={'flex'} justifyContent={'left'} alignItems={'center'} className="border-left" title={'Type'}>
                      <Typography variant="caption">Type</Typography>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1} display={'flex'} justifyContent={'left'} alignItems={'center'} className="border-left" title={'Shift Date'}>
                      <Typography variant="caption">Shift Date</Typography>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1} display={'flex'} justifyContent={'left'} alignItems={'center'} className="border-left" title={'Shift Hours'}>
                      <Typography variant="caption">Shift Hours</Typography>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1} display={'flex'} justifyContent={'left'} alignItems={'center'} className="border-left" title={'Prev. Value'}>
                      <Typography variant="caption">Prev. Value</Typography>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1} display={'flex'} justifyContent={'left'} alignItems={'center'} className="border-left" title={'New Value'}>
                      <Typography variant="caption">New Value</Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} display={'flex'} justifyContent={'left'} alignItems={'center'} className="border-left" title={'Details'}>
                      <Typography variant="caption">Details</Typography>
                  </Grid>
              </Grid>
                {viewLogs[page]?.map((l) => {
                    return (
                      <Grid item className="header" xs={12} sm={12} md={12} lg={12} display={'flex'} flexDirection={'row'} paddingLeft={'12px'}>
                        <Grid item xs={1} sm={1} md={1} lg={1} display={'flex'} justifyContent={'left'} alignItems={'center'} title={formatDate(l?.createdAt)}>
                            <Typography variant="caption">{formatDate(l?.createdAt)}</Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} display={'flex'} justifyContent={'left'} alignItems={'center'} title={formatTime(l?.createdAt)}>
                            <Typography variant="caption">{formatTime(l?.createdAt)}</Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} display={'flex'} justifyContent={'left'} alignItems={'center'} title={l?.vehicleName}>
                            <Typography variant="caption">{l?.vehicleName}</Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} display={'flex'} justifyContent={'left'} alignItems={'center'} title={l?.user !== "" ? l?.user : "SYSTEM"}>
                            <Typography variant="caption">{l?.user !== "" ? l?.user : "SYSTEM"}</Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} display={'flex'} justifyContent={'left'} alignItems={'center'} title={l?.type}>
                            <Typography variant="caption">{l?.type}</Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} display={'flex'} justifyContent={'left'} alignItems={'center'} title={formatDate(l?.shiftDate)}>
                            <Typography variant="caption">{formatDate(l?.shiftDate)}</Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} display={'flex'} justifyContent={'left'} alignItems={'center'} title={formatTime(l?.shiftStartTime) + " - " + formatTime(l?.shiftEndTime)}>
                            <Typography variant="caption">{formatTime(l?.shiftStartTime)} - {formatTime(l?.shiftEndTime)}</Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} display={'flex'} justifyContent={'left'} alignItems={'center'} title={formatIfIncludesDate(l?.from)}>
                            <Typography variant="caption">{formatIfIncludesDate(l?.from)}</Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} display={'flex'} justifyContent={'left'} alignItems={'center'} title={formatIfIncludesDate(l?.to)}>
                            <Typography variant="caption">{formatIfIncludesDate(l?.to)}</Typography>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} display={'flex'} justifyContent={'left'} alignItems={'center'} title={l?.details}>
                            <Typography variant="caption">{l?.details}</Typography>
                        </Grid>
                    </Grid>
                    )
                  })}
                  <Grid item xs sm md lg xl display={'flex'} justifyContent={'center'} marginTop={'24px'} marginBottom={'12px'} className="pagination">
                    <TablePagination sx={{
                      '& .MuiTablePagination-selectLabel, & .MuiTablePagination-select': {
                        display: 'none',
                      },
                      '& .MuiToolbar-root': {
                        overflow: 'hidden',
                        paddingLeft: '0px',
                      },
                      '& .MuiTablePagination-displayedRows': {
                        margin: '0',
                      },
                    }} count={logs?.length} page={page} rowsPerPage={[10]} onPageChange={handlePageChange}/>
                </Grid>
              </Grid>
          )}
    </Grid>
  );
}

export default Audit;
