import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup } from "@mui/material";
import "./collapse.scss";

function CollapseExpand({onClick}) {
    const [expand, setExpand] = useState(true);
    const [collapse, setCollapse] = useState(false);

    const expandAll = () => {
        if (!expand) {
            setCollapse(false);
            setExpand(true);
            onClick(false);
        }
    }

    const collapseAll = () => {
        if (!collapse) {
            setExpand(false);
            setCollapse(true);
            onClick(true);
        }
    }

  return (
    <ButtonGroup size="small" className="expand-collapse">
        <Button variant={expand ? 'contained' : 'outlined'} color="primary" 
            sx={{color: expand ? 'white' : 'black', border: expand ? 'none' : '', 
                borderTop: !expand ? '1px solid grey' : '', borderLeft: !expand ? '1px solid grey' : '', 
                borderBottom: !expand ? '1px solid grey' : ''}}
            onClick={() => {expandAll()}}
        >Expand All</Button>
         <Button variant={collapse ? 'contained' : 'outlined'} color="primary" 
            sx={{color: collapse ? 'white' : 'black', border: collapse ? 'none' : '', 
                borderTop: !collapse ? '1px solid grey' : '', borderRight: !collapse ? '1px solid grey' : '', 
                borderBottom: !collapse ? '1px solid grey' : ''}}
            onClick={() => {collapseAll()}}
        >Collapse All</Button>
    </ButtonGroup>
  );
}

export default CollapseExpand;
