import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";


import Card from "components/card";
import { Typography, IconButton, Container, Grid } from "@mui/material";
import { TypographySpan } from "components/styledComponents";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slider from '@mui/material/Slider';

import "./fluid.scss";
import { updatePrestartFluidLevels } from "redux/shiftRedux/shiftActions";
import EditIcon from "static/icons/editicon";
import GaugeIcon from "static/icons/gaugeicon";

function Fluid({fuelLevel, oilLevel, waterLevel, editable, collapse, mode, typeId, collapsed}) {
    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);

    const [fuel, setFuel] = useState(fuelLevel);
    const [oil, setOil] = useState(oilLevel);
    const [water, setWater] = useState(waterLevel);

    const [updatedFuel, setUpdatedFuel] = useState(fuelLevel);
    const [updatedOil, setUpdatedOil] = useState(oilLevel);
    const [updatedWater, setUpdatedWater] = useState(waterLevel);

    const [showCard, setShowCard] = useState(false);

    const [fluidsModal, setFluidsModal] = useState(false);

    const getColour = (level) => {
        if (level >= 0 && level <= 33) {
            return 'red';
        }
        if (level >= 34 && level <= 66) {
            return 'orange';
        }
        if (level >= 67) {
            return 'green';
        }
    }   

    const handleFuelChange = (event) => {
        setUpdatedFuel(event?.target?.value);
    }

    const handleOilChange = (event) => {
        setUpdatedOil(event?.target?.value);
    }

    const handleWaterChange = (event) => {
        setUpdatedWater(event?.target?.value);
    }

    const openFluidsModal = () => {
        setFluidsModal(true);
    }

    const closeFluidsModal = () => {
        setUpdatedFuel(fuel);
        setUpdatedOil(oil);
        setUpdatedWater(water);
        setFluidsModal(false);
    }

    const saveFluids = () => {
        if (mode === 'vehicle') {
            dispatch(updatePrestartFluidLevels({prestartId: typeId, fuel: parseFloat(updatedFuel), oil: parseFloat(updatedOil), water: parseFloat(updatedWater)}));
        } 
        else {
            dispatch(updatePrestartFluidLevels({prestartId: reducerState?.shift?.shiftData?.preStartChecklist?.ID, fuel: parseFloat(updatedFuel), oil: parseFloat(updatedOil), water: parseFloat(updatedWater)}));
        }

        setFuel(updatedFuel);
        setOil(updatedOil);
        setWater(updatedWater);
        setFluidsModal(false);
    }

    const isDisabled = () => {
        if (fuel !== updatedFuel || oil !== updatedOil || water !== updatedWater) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        setShowCard(true);
    }, []);

  return (
    <>
        {showCard && (
            <Card title='Fluid Levels' 
            content={
                <Grid className="machine-fluids">
                    <Grid>
                        <Grid container className="fluid">
                            <Grid container className="line">
                                <Grid xs={9} sm={9} md={9} lg={9} item className="label"><TypographySpan color={getColour(fuel)}>Fuel level %</TypographySpan></Grid>
                                <Grid xs={3} sm={3} md={3} lg={3} item className="data"><TypographySpan>{fuel}</TypographySpan></Grid>
                            </Grid>
                        </Grid>
                        <Grid container className="fluid">
                            <Grid container className="line">
                                <Grid xs={9} sm={9} md={9} lg={9} item className="label"><TypographySpan color={getColour(oil)}>Rock oil level %</TypographySpan></Grid>
                                <Grid xs={3} sm={3} md={3} lg={3} item className="data"><TypographySpan>{oil}</TypographySpan></Grid>
                            </Grid>
                        </Grid>
                        <Grid container className="fluid">
                            <Grid container className="line">
                                <Grid xs={9} sm={9} md={9} lg={9} item className="label"><TypographySpan color={getColour(water)}>Water level %</TypographySpan></Grid>
                                <Grid xs={3} sm={3} md={3} lg={3} item className="data"><TypographySpan>{water}</TypographySpan></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            edit={
                <>
                    {editable ? (
                        <IconButton className="edit" aria-label="edit" size="small" onClick={openFluidsModal}>
                            <EditIcon/>
                        </IconButton>
                    ) : (
                        <></>
                    )}
                </>
            }
            canCollapse={collapse ?? true}
            collapsed={collapsed}>
            </Card>
        )}
        
        <Dialog
            className="fluid-modal"
            fullWidth={true}
            maxWidth='md'
            open={fluidsModal}
            onClose={closeFluidsModal}>
            <DialogTitle className="dialog-title">
            <GaugeIcon/>
            <TypographySpan>Fluid Level Checks</TypographySpan>
            </DialogTitle>
            <DialogContent>
            <DialogContentText className="dialog-content">
                <Container>
                    <Grid container xs={12} sm={12} md={12} lg={12} className="section">
                        <Grid item xs={3} sm={3} md={3} lg={3} display={'flex'} alignContent={'flex-start'} className="label">
                            <TypographySpan variant="caption">Fuel level (%)</TypographySpan>
                        </Grid>

                        <Grid container xs={9} sm={9} md={9} lg={9}>
                            <Slider
                                size="small"
                                defaultValue={fuel}
                                min={0}
                                max={100}
                                onChange={handleFuelChange}
                                sx={{
                                    '& .MuiSlider-thumb': {
                                      border: '1px solid black',
                                      borderRadius: '30px',
                                      width: '40px',
                                    },
                                }}
                            />
                            <Grid container xs={12} sm={12} md={12} lg={12} display={'flex'} justifyContent={'space-between'}>
                                <TypographySpan variant="caption">Empty</TypographySpan>
                                <TypographySpan variant="caption">{updatedFuel}%</TypographySpan>
                                <TypographySpan variant="caption">Full</TypographySpan>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container xs={12} sm={12} md={12} lg={12} className="section">
                        <Grid item xs={3} sm={3} md={3} lg={3} display={'flex'} alignContent={'flex-start'} className="label">
                            <TypographySpan variant="caption">Rock oil level (%)</TypographySpan>
                        </Grid>

                        <Grid container xs={9} sm={9} md={9} lg={9}>
                            <Slider
                                size="small"
                                defaultValue={oil}
                                min={0}
                                max={100}
                                onChange={handleOilChange}
                                sx={{
                                    '& .MuiSlider-thumb': {
                                      border: '1px solid black',
                                      borderRadius: '30px',
                                      width: '40px',
                                    },
                                }}
                            />
                            <Grid container xs={12} sm={12} md={12} lg={12} display={'flex'} justifyContent={'space-between'}>
                                <TypographySpan variant="caption">Empty</TypographySpan>
                                <TypographySpan variant="caption">{updatedOil}%</TypographySpan>
                                <TypographySpan variant="caption">Full</TypographySpan>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container xs={12} sm={12} md={12} lg={12} className="section">
                        <Grid item xs={3} sm={3} md={3} lg={3} display={'flex'} alignContent={'flex-start'} className="label">
                            <TypographySpan variant="caption">Water level (%)</TypographySpan>
                        </Grid>

                        <Grid container xs={9} sm={9} md={9} lg={9}>
                            <Slider
                                size="small"
                                defaultValue={water}
                                min={0}
                                max={100}
                                onChange={handleWaterChange}
                                sx={{
                                    '& .MuiSlider-thumb': {
                                      border: '1px solid black',
                                      borderRadius: '30px',
                                      width: '40px',
                                    },
                                }}
                            />
                            <Grid container xs={12} sm={12} md={12} lg={12} display={'flex'} justifyContent={'space-between'}>
                                <TypographySpan variant="caption">Empty</TypographySpan>
                                <TypographySpan variant="caption">{updatedWater}%</TypographySpan>
                                <TypographySpan variant="caption">Full</TypographySpan>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-actions">
            <Button variant="contained" color="cancel" onClick={closeFluidsModal}>Cancel</Button>
            <Button variant="contained" onClick={saveFluids} autoFocus disabled={isDisabled()}>
                Save
            </Button>
            </DialogActions>
        </Dialog>
    </>
  );
}

export default Fluid;
