import React from "react";
import PropTypes from 'prop-types';
import { Typography, IconButton } from "@mui/material";
import { TypographySpan } from "./styledComponents";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import "./card.scss";
import styles from "../styles/_sys.scss";

function Card(props) {
  const [collapsed, setCollapsed] = React.useState(props.collapsed ?? false);

  const collapse = () => {
    setCollapsed(!collapsed);
  }

  return (

    <div className="card" style={{paddingBottom: collapsed ? '8px' : '12px', backgroundColor: collapsed ? styles.backgroundLight : (props.backgroundColour ? props.backgroundColour : '')}}>
        <div className="title" style={{fontSize: props.titleSize ? props.titleSize : '22px'}}>
            <TypographySpan sx={{fontSize: '20px', fontWeight: '600', color: props.titleColour ? props.titleColour : 'black'}}>{props.title}</TypographySpan>
            <div className="title-section">
              <div>
                {props.edit}
              </div>
              {props.canCollapse && (
                <IconButton size="small" onClick={collapse}>
                  {collapsed ? (<ExpandMoreIcon/>) : (<ExpandLessIcon/>)}
                </IconButton>
              )}
            </div>
        </div>
        <div style={{display: collapsed ? 'none' : ''}}>
            {props.content}
        </div>
    </div>
    
  );
}

Card.prototype = {
  title: PropTypes.string
};

export default Card;
