import React from "react";
import { Container} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { matchPath } from "react-router";

import "./shift.scss";
import ReportsPage from "components/reports/reportspage";



function Reports() {
  const { t } = useTranslation();
  const location = useLocation();

  const liveReport = matchPath({ path: "/reports/:id" }, location.pathname);

  let pageContent = <ReportsPage mode = "select"/>;
  if (liveReport) {
    pageContent = <ReportsPage mode = "live" id={location.state.shiftId} machineId={location.state.machineId} machineSerial={location.state.machineSerial}/>;
  }

  return (
    <Container className="shiftContainer" style={{backgroundColor: 'white', padding: '0px'}}>
      {pageContent}
    </Container>
  );
}

export default Reports;
