import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";

import Card from "components/card";
import { Typography, IconButton, TextField, Grid } from "@mui/material";
import { TypographySpan } from "components/styledComponents";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import "./rocktoolsused.scss";
import { updateShiftRockTools } from "redux/shiftRedux/shiftActions";
import EditIcon from "static/icons/editicon";
import DrillIcon from "static/icons/drillicon";
import {validateInt } from "utils/tools";

function RockTools(props) {
    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);

    const [showCard, setShowCard] = useState(false);

    const [rockTools, setRockTools] = useState({shank: [], rod: [], bit: []});
    const [updatedRockTools, setUpdatedRockTools] = useState({shank: [], rod: [], bit: []});

    const [rockToolsModal, setRockToolsModal] = useState(false);

    const openRockToolsModal = () => {
        setRockToolsModal(true);
    }

    const closeRockToolsModal = () => {
        setRockToolsModal(false);
    }

    const shankToolChange = (i, event) => {
        const parsed = parseInt(event?.target?.value);
        if (!Number.isNaN(parsed)) {
            let shank = [];
            for(let x=0; x<rockTools?.shank?.length; x++) {
                if (x === i) {
                    shank[x] = parsed;
                }
                else {
                    shank[x] = updatedRockTools?.shank[x];
                }
            }
    
            setUpdatedRockTools({shank: shank, rod: updatedRockTools?.rod, bit: updatedRockTools?.bit});
        }
    }
    const rodToolChange = (i, event) => {
        const parsed = parseInt(event?.target?.value);
        if (!Number.isNaN(parsed)) {
            let rod = [];
            for(let x=0; x<rockTools?.rod?.length; x++) {
                if (x === i) {
                    rod[x] = parsed;
                }
                else {
                    rod[x] = updatedRockTools?.rod[x];
                }
            }
    
            setUpdatedRockTools({shank: updatedRockTools?.shank, rod: rod, bit: updatedRockTools?.bit});
        }
    }
    const bitToolChange = (i, event) => {
        const parsed = parseInt(event?.target?.value);
        if (!Number.isNaN(parsed)) {
            let bit = [];
            for(let x=0; x<rockTools?.bit?.length; x++) {
                if (x === i) {
                    bit[x] = parsed;
                }
                else {
                    bit[x] = updatedRockTools?.bit[x];
                }
            }
    
            setUpdatedRockTools({shank: updatedRockTools?.shank, rod: updatedRockTools?.rod, bit: bit});
        }
    }

    const saveRockTools = () => {
        dispatch(updateShiftRockTools({shiftId: reducerState?.shift?.shiftData?.shiftReport?._id, rockTools: {shank: updatedRockTools?.shank, rod: updatedRockTools?.rod, bit: updatedRockTools?.bit}}));
        
        setRockTools({shank: updatedRockTools?.shank, rod: updatedRockTools?.rod, bit: updatedRockTools?.bit});
        setShowCard(false);
        setRockToolsModal(false);
    }

    const isDisabled = () => {
        if (rockTools.bit !== updatedRockTools?.bit || rockTools?.rod !== updatedRockTools?.rod || rockTools?.shank !== updatedRockTools?.shank) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (props.rockTools) {
            setRockTools({shank: props?.rockTools?.shank, rod: props?.rockTools?.rod, bit: props?.rockTools?.bit});
            setUpdatedRockTools({shank: props?.rockTools?.shank, rod: props?.rockTools?.rod, bit: props?.rockTools?.bit});
        }
    }, []);

    useEffect(() => {
        if (rockTools) {
            setShowCard(true);
        }
    }, [rockTools]);

  return (
    <>
        {showCard && (
            <Card title='Rock Tools Used' 
            content={
                <div className="rock-tools-used">
                    <Grid container className="tools">
                        <Grid container className="line">
                            <Grid xs={3} sm={3} md={3} lg={3} item className="col main"><TypographySpan variant="caption">Tool</TypographySpan></Grid>
                            <Grid xs={3} sm={3} md={3} lg={3} item className="col"><TypographySpan variant="caption">Broken</TypographySpan></Grid>
                            <Grid xs={3} sm={3} md={3} lg={3} item className="col"><TypographySpan variant="caption">Used</TypographySpan></Grid>
                            <Grid xs={3} sm={3} md={3} lg={3} item className="col"><TypographySpan variant="caption">Lost</TypographySpan></Grid>
                        </Grid>
                    </Grid>
                    <Grid container className="tools">
                        <Grid container className="line">
                            <Grid xs={3} sm={3} md={3} lg={3} item className="col main"><TypographySpan variant="caption">Shank</TypographySpan></Grid>
                            {rockTools?.shank?.map((x) => {
                                return (
                                    <Grid xs={3} sm={3} md={3} lg={3} item className="col">
                                        <TextField variant="standard" defaultValue={x} InputProps={{readOnly: true}}/>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                    <Grid container className="tools">
                        <Grid container className="line">
                            <Grid xs={3} sm={3} md={3} lg={3} item className="col main"><TypographySpan variant="caption">Rod</TypographySpan></Grid>
                            {rockTools?.rod?.map((x) => {
                                return (
                                    <Grid xs={3} sm={3} md={3} lg={3} item className="col">
                                        <TextField variant="standard" defaultValue={x} InputProps={{readOnly: true}}/>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                    <Grid container className="tools">
                        <Grid container className="line">
                            <Grid xs={3} sm={3} md={3} lg={3} item className="col main"><TypographySpan variant="caption">Bit</TypographySpan></Grid>
                            {rockTools?.bit?.map((x) => {
                                return (
                                    <Grid xs={3} sm={3} md={3} lg={3} item className="col">
                                        <TextField variant="standard" defaultValue={x} InputProps={{readOnly: true}}/>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                </div>
            }
            edit={
                <>
                    {props.editable ? (
                        <IconButton className="edit" aria-label="edit" size="small" onClick={openRockToolsModal}>
                            <EditIcon/>
                        </IconButton>
                    ) : (
                        <></>
                    )}
                </>
            }
            canCollapse={true}
            collapsed={props.collapsed}>
            </Card>
        )}
        
        <Dialog
            className="rock-tools-modal"
            fullWidth={true}
            maxWidth='md'
            open={rockToolsModal}
            onClose={closeRockToolsModal}>
            <DialogTitle className="dialog-title">
            <DrillIcon/>
            <TypographySpan>Edit Rock Tools Used</TypographySpan>
            </DialogTitle>
            <DialogContent>
            <DialogContentText className="dialog-content">
                {showCard && (
                    <Grid container xl={12} lg={12} md={12} sm={12} xs={12} className="rock-tools-used">
                        <Grid xl={12} lg={12} md={12} sm={12} xs={12} container className="tools">
                            <Grid container xl={12} lg={12} md={12} sm={12} xs={12} className="line">
                                <Grid xs={3} sm={3} md={3} lg={3} item className="col main"><TypographySpan variant="caption">Tool</TypographySpan></Grid>
                                <Grid xs={3} sm={3} md={3} lg={3} item className="col"><TypographySpan variant="caption">Broken</TypographySpan></Grid>
                                <Grid xs={3} sm={3} md={3} lg={3} item className="col"><TypographySpan variant="caption">Used</TypographySpan></Grid>
                                <Grid xs={3} sm={3} md={3} lg={3} item className="col"><TypographySpan variant="caption">Lost</TypographySpan></Grid>
                            </Grid>
                        </Grid>
                        <Grid container xl={12} lg={12} md={12} sm={12} xs={12} className="tools">
                            <Grid container xl={12} lg={12} md={12} sm={12} xs={12} className="line">
                                <Grid xs={3} sm={3} md={3} lg={3} item className="col main"><TypographySpan variant="caption">Shank</TypographySpan></Grid>
                                {rockTools?.shank?.map((x, i) => {
                                    return (
                                        <Grid xs={3} sm={3} md={3} lg={3} item className="col">
                                            <TextField type="number" variant="standard" defaultValue={x} onKeyDown={(e) => {if(!validateInt(e.key)) {e.preventDefault();}}} onChange={(e) => {shankToolChange(i, e)}} InputProps={{inputMode: 'numeric'}}/>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                        <Grid container xl={12} lg={12} md={12} sm={12} xs={12} className="tools">
                            <Grid container xl={12} lg={12} md={12} sm={12} xs={12} className="line">
                                <Grid xs={3} sm={3} md={3} lg={3} item className="col main"><TypographySpan variant="caption">Rod</TypographySpan></Grid>
                                {rockTools?.rod?.map((x, i) => {
                                    return (
                                        <Grid xs={3} sm={3} md={3} lg={3} item className="col">
                                            <TextField type="number" variant="standard" defaultValue={x} onKeyDown={(e) => {if(!validateInt(e.key)) {e.preventDefault();}}} onChange={(e) => {rodToolChange(i, e)}}/>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                        <Grid container xl={12} lg={12} md={12} sm={12} xs={12} className="tools">
                            <Grid container xl={12} lg={12} md={12} sm={12} xs={12} className="line">
                                <Grid xs={3} sm={3} md={3} lg={3} item className="col main"><TypographySpan variant="caption">Bit</TypographySpan></Grid>
                                {rockTools?.bit?.map((x, i) => {
                                    return (
                                        <Grid xs={3} sm={3} md={3} lg={3} item className="col">
                                            <TextField type="number" variant="standard" defaultValue={x} onKeyDown={(e) => {if(!validateInt(e.key)) {e.preventDefault();}}} onChange={(e) => {bitToolChange(i, e)}}/>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-actions">
            <Button variant="contained" color="cancel" onClick={closeRockToolsModal}>Cancel</Button>
            <Button variant="contained" onClick={saveRockTools} autoFocus disabled={isDisabled()}>
                Save
            </Button>
            </DialogActions>
        </Dialog>
    </>
        
  );
}

export default RockTools;
