import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Divider,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";

//import actions
import { updateStateVehicle } from "redux/machinesRedux/machinesActions";

//import scss
import "./formmachine.scss";

//import utils
import { getRandomString } from "helper/function/cryptoHelper";
import {
  capitalizeString,
  getDateTime,
  getVehicleHours,
  isURL,
} from "utils/tools";

function FormMachine(props) {
  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();
  // const state = useSelector((state) => (state));

  //console.log(props.vehicle);
  const [vehicle, setVehicle] = React.useState({
    id: props.mode === "new" ? "" : props.vehicle.id,
    secret_key: props.mode === "new" ? "" : "",
    type: props.mode === "new" ? "" : props.vehicle.type,
    make: props.mode === "new" ? "" : props.vehicle.make,
    model: props.mode === "new" ? "" : props.vehicle.model,
    serial: props.mode === "new" ? "" : props.vehicle.serial,
    name: props.mode === "new" ? "" : props.vehicle.name,
    fuelType: props.mode === "new" ? "" : props.vehicle.fuelType,
    fuelCapacity: props.mode === "new" ? 0 : props.vehicle.fuelCapacity,
    manufacturerURL: props.mode === "new" ? "" : props.vehicle.manufacturerURL,
    manualURL: props.mode === "new" ? "" : props.vehicle.manualURL,
    serviceInterval: props.mode === "new" ? 0 : props.vehicle.serviceInterval,
    serviceFirst:
      props.mode === "new"
        ? dayjs(new Date())
        : new Date(props.vehicle.serviceFirst),
    deviceName: props.mode === "new" ? "" : props.vehicle.deviceName,
    prestartcheckList:
      props.mode === "new" ? "Rotary" : props.vehicle.prestartcheckList,
    runningcheckList:
      props.mode === "new" ? "Rotary" : props.vehicle.runningcheckList,
  });

  const [showSecretKey, setShowSecretKey] = React.useState(
    props.mode === "new" ? true : false
  );

  const [vehicleErrors, setVehicleErrors] = React.useState({
    type: "",
    make: "",
    model: "",
    serial: "",
    name: "",
    secret_key: "",
    fuelType: "",
    fuelCapacity: "",
    serviceInterval: "",
  });

  useEffect(() => {
    dispatch(updateStateVehicle({ ...vehicle }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAutocompleteChange = (fieldName, value) => {
    //console.log(fieldName,value);
    if (!value) value = "";
    setVehicle({ ...vehicle, [fieldName]: value });
    dispatch(updateStateVehicle({ ...vehicle, [fieldName]: value }));
  };

  const handleTextfieldChange = (e) => {
    setVehicle({ ...vehicle, [e.target.name]: e.target.value });
    dispatch(
      updateStateVehicle({ ...vehicle, [e.target.name]: e.target.value })
    );
  };

  const handleSecretKeyChange = (keyName) => {
    const secret_key = getRandomString();
    setVehicle({ ...vehicle, [keyName]: secret_key });
    dispatch(updateStateVehicle({ ...vehicle, [keyName]: secret_key }));
    setShowSecretKey(true);
  };

  const handleDatePickerChange = (d) => {
    setVehicle({ ...vehicle, serviceFirst: d });
    dispatch(updateStateVehicle({ ...vehicle, serviceFirst: d }));
  };

  const handleTextfieldNumberChange = (e) => {
    let fieldValue = 0;
    if (!isNaN(e.target.value)) fieldValue = parseInt(e.target.value);
    setVehicle({ ...vehicle, [e.target.name]: fieldValue });
    dispatch(updateStateVehicle({ ...vehicle, [e.target.name]: fieldValue }));
  };

  const formValidation = () => {
    let formValid = true;
    let newErrors = {
      type: "",
      make: "",
      model: "",
      serial: "",
      name: "",
      secret_key: "",
      fuelType: "",
      manufacturerURL: "",
      manualURL: "",
      fuelCapacity: "",
      serviceInterval: "",
    };
    if (vehicle.type === "") {
      newErrors.type = t("screens.machines_form.error_type_required");
    }
    if (vehicle.make === "") {
      newErrors.make = t("screens.machines_form.error_make_required");
    }
    if (vehicle.model === "") {
      newErrors.model = t("screens.machines_form.error_model_required");
    }
    if (vehicle.serial === "") {
      newErrors.serial = t("screens.machines_form.error_serial_required");
    }
    // if (vehicle.secret_key === "") {
    //     newErrors.secret_key = t('screens.machines_form.error_secretKey_required');
    // }
    if (vehicle.name === "") {
      newErrors.name = t("screens.machines_form.error_name_required");
    }
    if (vehicle.fuelType === "") {
      newErrors.fuelType = t("screens.machines_form.error_fuelType_required");
    }
    if (vehicle.fuelCapacity <= 0) {
      newErrors.fuelCapacity = t(
        "screens.machines_form.error_fuelCapacity_invalid"
      );
    }
    if (vehicle.serviceInterval <= 0) {
      newErrors.serviceInterval = t(
        "screens.machines_form.error_serviceInterval_invalid"
      );
    }

    if (vehicle.manufacturerURL !== "" && !isURL(vehicle.manufacturerURL)) {
      newErrors.manufacturerURL = t("screens.machines_form.error_URL_invalid");
    }
    if (vehicle.manualURL !== "" && !isURL(vehicle.manualURL)) {
      newErrors.manualURL = t("screens.machines_form.error_URL_invalid");
    }

    setVehicleErrors(newErrors);
    //check if there is any error
    Object.keys(newErrors).forEach(function (key, index) {
      if (newErrors[key] !== "") {
        formValid = false;
      }
    });
    props.dataChanged(formValid);
  };

  React.useEffect(() => {
    formValidation();
    // eslint-disable-next-line
  }, [vehicle]);
  //console.log(props.vehicle);
  //console.log(props.vehicle);
  if (props.mode === "info") {
    return (
      <Grid container direction="row">
        <Grid item xs={6}>
          <Grid container direction="column" className="infoMachineGrid">
            <Grid item className="dataElement">
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Typography variant="body1" className="label">
                    {t("screens.machines_form.text_vehicle_type")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" className="data">
                    {capitalizeString(props.vehicle.type)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="dataElement">
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Typography variant="body2" className="label">
                    {t("screens.machines_form.text_make")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" className="data">
                    {capitalizeString(props.vehicle.make)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="dataElement">
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Typography variant="body2" className="label">
                    {t("screens.machines_form.text_model")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" className="data">
                    {capitalizeString(props.vehicle.model)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="dataElement">
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Typography variant="body2" className="label">
                    {t("screens.machines_form.text_manufacturer_URL")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className="data">
                    <Link
                      variant="body2"
                      target="_blank"
                      rel="noopener"
                      href={props.vehicle.manufacturerURL}
                    >
                      {t("screens.machines_form.text_link")}
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Typography variant="body2" className="label">
                    {t("screens.machines_form.text_manual_URL")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className="data">
                    <Link
                      variant="body2"
                      target="_blank"
                      rel="noopener"
                      href={props.vehicle.manualURL}
                    >
                      {t("screens.machines_form.text_link")}
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item className="dataElement">
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Typography variant="body2" className="label">
                    {t("screens.machines_form.text_name")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" className="data">
                    {capitalizeString(props.vehicle.name)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="dataElement">
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Typography variant="body2" className="label">
                    {t("Machine Secret Key")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    className="data"
                    style={{ paddingLeft: 10 }}
                  >
                    ***************
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="dataElement">
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Typography variant="body2" className="label">
                    {t("screens.machines_form.text_serial")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" className="data">
                    {capitalizeString(props.vehicle.serial)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Divider />
            </Grid>
            {/* <Grid item className="dataElement">
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography variant="body2" className="label">{t('screens.machines_form.text_last_service')}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" className="data">{getDateTime(new Date())}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>*/}
            <Grid item className="dataElement">
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Typography variant="body2" className="label">
                    {t("screens.machines_form.text_next_service")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" className="data">
                    {getDateTime(new Date())}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item className="dataElement">
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Typography variant="body2" className="label">
                    {t("screens.machines_form.text_last_precheck")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" className="data">
                    {getDateTime(
                      new Date(
                        props.vehicle.prestartCheck
                          ? props.vehicle.prestartCheck.createdAt
                          : 0
                      )
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item>
              <Divider />
            </Grid>
            <Grid item className="dataElement">
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Typography variant="body2" className="label">
                    {t("screens.machines_form.text_engine_hours")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" className="data">
                    {getVehicleHours(props.vehicle.shiftReport).engine}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="dataElement">
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Typography variant="body2" className="label">
                    {t("screens.machines_form.text_percussion_hours")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" className="data">
                    {getVehicleHours(props.vehicle.shiftReport).percussive}
                  </Typography>
                </Grid>
              </Grid>
            </Grid> */}
            <Grid item>
              <Divider />
            </Grid>
            <Grid item className="dataElement">
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Typography variant="body2" className="label">
                    {t("screens.machines_form.text_fuel_type")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" className="data">
                    {capitalizeString(props.vehicle.fuelType)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    );
  }

  if (props.mode === "new" || props.mode === "edit") {
    return (
      <Grid container direction="column" className="formMachineGrid">
        <Grid item>
          <Autocomplete
            freeSolo
            options={props.vehicleOptions.vehicleTypes}
            id="type"
            onChange={(e, value) => handleAutocompleteChange("type", value)}
            getOptionLabel={(option) => capitalizeString(option)}
            value={vehicle.type || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={t("screens.machines_form.text_vehicle_type")}
                fullWidth
                margin="normal"
                variant="standard"
                className="textfieldVehicleType"
                helperText={vehicleErrors.type}
              />
            )}
            autoSelect
          />
        </Grid>
        <Grid item>
          <Autocomplete
            freeSolo
            options={props.vehicleOptions.makes}
            id="make"
            onChange={(e, value) => handleAutocompleteChange("make", value)}
            getOptionLabel={(option) => capitalizeString(option)}
            value={vehicle.make || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={t("screens.machines_form.text_make")}
                fullWidth
                margin="normal"
                variant="standard"
                className="textfieldMake"
                helperText={vehicleErrors.make}
              />
            )}
            autoSelect
          />
        </Grid>
        <Grid item>
          <Autocomplete
            freeSolo
            options={props.vehicleOptions.models}
            id="model"
            onChange={(e, value) => handleAutocompleteChange("model", value)}
            getOptionLabel={(option) => capitalizeString(option)}
            value={vehicle.model || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={t("screens.machines_form.text_model")}
                fullWidth
                margin="normal"
                variant="standard"
                className="textfieldModel"
                helperText={vehicleErrors.model}
              />
            )}
            autoSelect
          />
        </Grid>
        <Grid item>
          <TextField
            required
            label={t("screens.machines_form.text_name")}
            fullWidth
            margin="normal"
            variant="standard"
            className="textfieldName"
            name="name"
            id="new_machine_form-machine_id"
            onChange={handleTextfieldChange}
            value={vehicle.name || ""}
            helperText={vehicleErrors.name}
          />
        </Grid>
        <Grid item>
          <div className="formMachineGrid__secretKeyContainer">
            <TextField
              required
              label={t("Machine Secret Key")}
              fullWidth
              margin="normal"
              variant="standard"
              className="textfieldName"
              name="secret_key"
              id="new_machine_form-machine_secret_key"
              value={showSecretKey ? vehicle.secret_key : "*****************"}
              helperText={vehicleErrors.secret_key}
            />

            <button
              className="secretKeyBtn"
              onClick={() => handleSecretKeyChange("secret_key")}
            >
              Secret Key
            </button>
          </div>
        </Grid>

        {props.mode === "new" && (
          <Grid item>
            <TextField
              required
              label={t("screens.machines_form.text_serial")}
              fullWidth
              margin="normal"
              variant="standard"
              className="textfieldSerial"
              name="serial"
              id="new_machine_form-machine_serial"
              onChange={handleTextfieldChange}
              value={vehicle.serial || ""}
              helperText={vehicleErrors.serial}
            />
          </Grid>
        )}
        <Grid item>
          <TextField
            label={t("screens.machines_form.text_manufacturer_URL")}
            fullWidth
            margin="normal"
            variant="standard"
            className="textfieldManufacturerURL"
            name="manufacturerURL"
            id="new_machine_form-manufacturer_url"
            onChange={handleTextfieldChange}
            value={vehicle.manufacturerURL || ""}
            helperText={vehicleErrors.manufacturerURL}
          />
        </Grid>
        <Grid item>
          <TextField
            label={t("screens.machines_form.text_manual_URL")}
            fullWidth
            margin="normal"
            variant="standard"
            className="textfieldManualURL"
            name="manualURL"
            id="new_machine_form-manual_url"
            onChange={handleTextfieldChange}
            value={vehicle.manualURL || ""}
            helperText={vehicleErrors.manualURL}
          />
        </Grid>
        <Grid item style={{ marginTop: 8 }}>
          <MobileDatePicker
            className="serviceFirst"
            id="datepicker-servicefirst-field"
            label={t("screens.machines_form.text_service_first")}
            inputFormat="DD/MM/YYYY"
            value={vehicle.serviceFirst || ""}
            onChange={handleDatePickerChange}
            renderInput={(params) => (
              <TextField
                variant="standard"
                helperText={vehicleErrors.serviceFirst}
                {...params}
              />
            )}
          />
        </Grid>
        <Grid item>
          <TextField
            required
            label={t("screens.machines_form.text_service_interval")}
            fullWidth
            margin="normal"
            variant="standard"
            className="textfieldServiceInterval"
            name="serviceInterval"
            id="new_machine_form-service_interval"
            type="number"
            onChange={handleTextfieldNumberChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {t("common.units.hours")}
                </InputAdornment>
              ),
            }}
            value={vehicle.serviceInterval || ""}
            helperText={vehicleErrors.serviceInterval}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            freeSolo
            options={props.vehicleOptions.fuelTypes}
            id="fuelType"
            onChange={(e, value) => handleAutocompleteChange("fuelType", value)}
            getOptionLabel={(option) => capitalizeString(option)}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={t("screens.machines_form.text_fuel_type")}
                fullWidth
                margin="normal"
                variant="standard"
                className="textfieldFuelType"
                helperText={vehicleErrors.fuelType}
              />
            )}
            autoSelect
            value={vehicle.fuelType || ""}
          />
        </Grid>
        <Grid item>
          <TextField
            required
            label={t("screens.machines_form.text_fuel_capacity")}
            fullWidth
            margin="normal"
            variant="standard"
            className="textfieldFuelCapacity"
            name="fuelCapacity"
            id="new_machine_form-fuel_capacity"
            type="number"
            onChange={handleTextfieldNumberChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {t("common.units.liters")}
                </InputAdornment>
              ),
            }}
            value={vehicle.fuelCapacity || ""}
            helperText={vehicleErrors.fuelCapacity}
          />
        </Grid>
        <Grid item style={{ marginTop: 10 }}>
          <InputLabel style={{ fontSize: "0.6rem" }}>
            {t("screens.machines_form.text_prestart_checklist")}
          </InputLabel>
          <Select
            fullWidth
            className="textfieldPrestartcheckList"
            name="prestartcheckList"
            variant="standard"
            style={{ marginTop: 0 }}
            onChange={handleTextfieldChange}
            value={props.vehicle.prestartcheckList || ""}
          >
            {props.vehicleOptions.prestartChecks.map((x, i) => {
              return (
                <MenuItem value={x.name} key={i}>
                  {x.name}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item style={{ marginTop: 10 }}>
          <InputLabel style={{ fontSize: "0.6rem" }}>
            {t("screens.machines_form.text_running_checklist")}
          </InputLabel>
          <Select
            fullWidth
            className="textfieldRunningcheckList"
            name="runningcheckList"
            variant="standard"
            style={{ marginTop: 0 }}
            onChange={handleTextfieldChange}
            value={vehicle.runningcheckList || ""}
          >
            {props.vehicleOptions.runningChecks.map((x, i) => {
              return (
                <MenuItem value={x.name} key={i}>
                  {x.name}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item>
          <TextField
            label={t("screens.machines_form.text_device_name")}
            fullWidth
            margin="normal"
            variant="standard"
            className="textfieldDevicename"
            name="deviceName"
            id="new_machine_form-device_name"
            onChange={handleTextfieldChange}
            value={vehicle.deviceName || ""}
          />
        </Grid>
      </Grid>
    );
  }
}

export default FormMachine;
