import { createSlice } from "@reduxjs/toolkit";

//Import actions
import {
  addVehicle,
  addVehicleEvent,
  updateVehicleEvent,
  deleteVehicleEvent,
  fetchVehicle,
  fetchVehicleWithoutLoader,
  fetchVehicleArchives,
  fetchVehicleOptions,
  fetchVehicles,
  modifyVehicle,
  modifyVehicleState,
  removeVehicle,
  updateStateVehicle,
} from "redux/machinesRedux/machinesActions";

const initialState = {
  displayedVehicle: {},
  vehicles: [],
  archives: [],
  options: {
    vehicleTypes: [],
    makes: [],
    models: [],
    fuelTypes: [],
    prestartChecks: [],
    runningChecks: [],
  },
};

export const machinesSlice = createSlice({
  name: "machinesReducer",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // // FETCH VEHICLES REDUCER
      .addCase(fetchVehicles.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchVehicles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.vehicles = action.payload;
        state.displayedVehicle = initialState.displayedVehicle;
      })
      .addCase(fetchVehicles.rejected, (state, action) => {
        state.isLoading = false;
      })

      // // FETCH VEHICLE REDUCER
      .addCase(fetchVehicle.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchVehicle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.displayedVehicle = action.payload;
        state.archives = initialState.archives;
      })
      .addCase(fetchVehicle.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(fetchVehicleWithoutLoader.pending, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchVehicleWithoutLoader.fulfilled, (state, action) => {
        state.isLoading = false;
        state.displayedVehicle = action.payload;
        state.archives = initialState.archives;
      })
      .addCase(fetchVehicleWithoutLoader.rejected, (state, action) => {
        state.isLoading = false;
      })

      // // ADD VEHICLE REDUCER
      .addCase(addVehicle.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addVehicle.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addVehicle.rejected, (state, action) => {
        state.isLoading = false;
      })

      // // FETCH VEHICLE OPTIONS REDUCER
      .addCase(fetchVehicleOptions.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchVehicleOptions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.options = action.payload;
      })
      .addCase(fetchVehicleOptions.rejected, (state, action) => {
        state.isLoading = false;
      })

      // // MODIFY VEHICLES STATE REDUCER
      .addCase(modifyVehicleState.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(modifyVehicleState.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(modifyVehicleState.rejected, (state, action) => {
        state.isLoading = false;
      })

      // // MODIFY VEHICLES STATE REDUCER
      .addCase(modifyVehicle.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(modifyVehicle.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(modifyVehicle.rejected, (state, action) => {
        state.isLoading = false;
      })

      // // DELETE VEHICLE
      .addCase(removeVehicle.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(removeVehicle.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(removeVehicle.rejected, (state, action) => {
        state.isLoading = false;
      })

      // // ADD VEHICLES VEHICLE
      .addCase(addVehicleEvent.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addVehicleEvent.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addVehicleEvent.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(updateVehicleEvent.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateVehicleEvent.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateVehicleEvent.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(deleteVehicleEvent.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteVehicleEvent.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteVehicleEvent.rejected, (state, action) => {
        state.isLoading = false;
      })
      
      // // UPDATE STATE VEHICLE REDUCER
      .addCase(updateStateVehicle, (state, action) => {
        state.displayedVehicle = action.payload;
      })

      // // FETCH VEHICLE ARCHIVES REDUCER
      .addCase(fetchVehicleArchives.pending, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchVehicleArchives.fulfilled, (state, action) => {
        state.archives = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchVehicleArchives.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});
