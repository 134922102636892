import React from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { HelpOutline } from "@mui/icons-material";

//Import css
import "./modalConfirm.scss";

//Import Actions
import { modalConfirmNo } from "redux/commonRedux/commonActions";

function ModalConfirm(props) {
  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();

  return (
    <Dialog
      open={true}
      className="modalConfirm"
      onClose={(event, reason) =>
        reason !== "backdropClick" && dispatch(modalConfirmNo())
      }
    >
      <DialogTitle display="flex" alignContent="center" gap={2}>
        <HelpOutline /> <span>{props.modal.title}</span>
      </DialogTitle>
      <DialogContent>
        <Typography className="textModalInfo" fullWidth margin="normal">
          {props.modal.info}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            dispatch(modalConfirmNo());
          }}
        >
          {props.modal.buttonCancel}
        </Button>
        <Button variant="contained" color="primary" onClick={props.modal.func}>
          {props.modal.buttonConfirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalConfirm;
