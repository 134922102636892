import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";


import Card from "components/card";
import { Typography, IconButton, Container, Grid, TextField, InputAdornment, Tab, Select, MenuItem, FormControl, InputLabel, Pagination, TextareaAutosize } from "@mui/material";

import {
    TabContext,
    TabList,
    TabPanel
    }
from '@mui/lab';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import "./drillingactivity.scss";
import styles from "../../styles/_sys.scss";
import EditIcon from "static/icons/editicon";
import Checkbox from '@mui/material/Checkbox';
import TrashIcon from "static/icons/trashicon";
import { validateFloat } from "utils/tools";
import { TypographySpan } from "components/styledComponents";
import { fetchDrillTask, fetchShiftDrillTasks, removeExtraHole, updateDrillingHole, updateExtraHole } from "redux/shiftRedux/shiftActions";

function DrillingActivity({drillingTasks, shiftId, editable, collapsed}) {
    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);
    const shift = reducerState?.shift?.shiftData?.shiftReport;

    const endTime = "0001-01-01T00:00:00Z";

    const menuProps = {
        PaperProps: {
            style: {
                marginTop: '8px',
                minWidth: '500px',
                backgroundColor: styles.backgroundLight,
                borderRadius: '6px'
            },
        },
        MenuListProps: {
            style: {
                padding: '0px',
            },
        },
    };

    const holeMenuProps = {
        PaperProps: {
            style: {
                marginTop: '8px',
                minWidth: '300px',
                backgroundColor: styles.backgroundLight,
                borderRadius: '6px'
            },
        },
        MenuListProps: {
            style: {
                padding: '0px',
            },
        },
    };
    
    const DateToTime = (date) => {
        const isoDate = new Date(date);
      
        const timeString = isoDate?.toLocaleTimeString('en-au', {
          hour12: false,
          hour: '2-digit',
          minute: '2-digit',
        });

        return timeString;
    }

    const [tab, setTab] = useState('completed-holes');
    const [page, setPage] = useState(0);

    const [taskOptions, setTaskOptions] = useState([]);
    const [taskSelect, setTaskSelect] = useState('');
    const [task, setTask] = useState();
    const [tasks, setTasks] = useState(drillingTasks);
    const [availableTaskOptions, setAvailableTaskOptions] = useState([]);

    const [hole, setHole] = useState();
    const [updatedHole, setUpdatedHole] = useState();

    const [completedHoles, setCompletedHoles] = useState();

    const [showTable, setShowTable] = useState(false);

    const [drillingActivity, setDrillingActivity] = useState({
        patternHoles: 0,
        additionalHoles: 0,
        totalDepthPlanned: 0,
        totalDepthEdited: 0,
        totalDepthAdditional: 0,
        holesRedrilled: 0,
        holesFlushed : 0,
        bogged: 0,
        issues: 0,
        groundWater: 0,
        overburden: 0,
        cavity: 0,
        rc: 0
    });

    const defaultDelay = {issue: false, delay: 0, eventTime: "0001-01-01T00:00:00Z"};

    const [mode, setMode] = useState('default');

    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);

    // initialise non-prod
    const [updatedRedrill, setUpdatedRedrill] = useState();
    const [updatedFlush, setUpdatedFlush] = useState();
    const [updatedStuck, setUpdatedStuck] = useState();
    const [updatedOther, setUpdatedOther] = useState();

    //initialise ground conditions
    const [updatedWater, setUpdatedWater] = useState();
    const [updatedOverburden, setUpdatedOverburden] = useState();
    const [updatedCavity, setUpdatedCavity] = useState();
    const [updatedRcGc, setUpdatedRcGc] = useState();

    const [currentDelay, setCurrentDelay] = useState();
    const [noDelay, setNoDelay] = useState(false);

    const [showCard, setShowCard] = useState(false);

    const [drillingModal, setDrillingModal] = useState(false);

    const openDrillingModal = () => {
        setDrillingModal(true);
    }

    const closeDrillingModal = () => {
        dispatch(fetchShiftDrillTasks(reducerState?.shift?.shiftData?.shiftReport?._id)).then((data) => {
            setTasks(data?.payload);
        })
        // completed holes stuff
        setMode('default');
        setHole();
        setShowTable(false);
        setCompletedHoles();
        setTaskSelect('');
        setTask();
        cancelHoleDetails();
        setDrillingModal(false);

        //extra holes stuff
        setShowExtraHoles(false);
        setExtraHoles();
        setUpdatedNewExtraHole(newExtraHole);

        setTab('completed-holes');
    }

    const removeCompletedHole = (h) => {
        const hole = {...h,
            completedBy: '',
            endTime: endTime,
            startTime: endTime,
            updatedTime: endTime,
            state: 0,
            shiftID: '',
            flush: defaultDelay,
            redrill: defaultDelay,
            rodStuck: defaultDelay,
            otherIssue: defaultDelay,
            excessiveWater: defaultDelay,
            excessiveOverburden: defaultDelay,
            interceptCavity: defaultDelay,
            interceptRcGc: defaultDelay,
            depth: {
                edited: 0,
                editedTs: endTime,
                planned: h?.depth?.planned,
                redrilled: 0,
                redrilledTs: endTime,
            },
            notes: '',
            vehicleSerial: '',
        };

        setAvailableTaskOptions(availableTaskOptions.filter((x) => {return x.id !== task?.id}));
        dispatch(updateDrillingHole({taskId: task?.id, shiftId: reducerState?.shift?.shiftData?.shiftReport?._id, hole: hole})).then(() => {
            dispatch(fetchShiftDrillTasks(reducerState?.shift?.shiftData?.shiftReport?._id)).then((data) => {
                const tasks = data?.payload;
                setTasks(tasks);
                tasks.forEach((t) => {
                    if (t.id === task?.id) {
                        setTask(t);
                    }
                })
            })
        });
        setMode('default');
        setHole(); 
        setShowTable(false);
        let holes = [...completedHoles?.flat()?.filter((hole) => {return hole.shiftID === shift._id && hole.endTime !== endTime && hole.number !== updatedHole.number})];
        holes.sort((a, b) => {return a.number > b.number});
        setCompletedHoles([...chunkify(holes, 4)]);
        if ([...chunkify(holes, 4)].length > 0) {
            if (page < [...chunkify(holes, 4)].length) {
                setPage(page);
            }
            else {
                setPage([...chunkify(holes, 4)].length-1);
            }
        }
        else {
            setPage(0);
        }
    }

    const addPatternHole = () => {
        cancelHoleDetails();
        setMode('pattern-default');
    }

    const isDisabled = (type) => {
        const isEmpty = isNaN(parseFloat(updatedHole?.depth?.planned)) || isNaN(parseFloat(updatedHole?.depth?.edited)) || isNaN(parseFloat(updatedHole?.angle));
        const hasGroundCondition = updatedWater?.issue || updatedOverburden?.issue || updatedCavity?.issue || updatedRcGc?.issue;
        const delay = hole?.flush === updatedFlush || hole?.redrill === updatedRedrill || hole?.rodStuck === updatedStuck || hole?.otherIssue === updatedOther;
        const common = hole === updatedHole;

        if (!isEmpty) {
            if (noDelay) {
                if (common) {
                    if (type === 'add') {
                        return false;
                    }
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                if (updatedOther.issue) {
                    if (common) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                else {
                    if (!hasGroundCondition || common) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            }
        }
        else {
            return true;
        }
    }

    const isExtraDisabled = () => {
        const isZero = parseFloat(updatedNewExtraHole?.holes) === 0 || parseFloat(updatedNewExtraHole?.depth) === 0;
        const isEmpty = isNaN(parseFloat(updatedNewExtraHole?.holes)) || isNaN(parseFloat(updatedNewExtraHole?.depth));
        const common = newExtraHole === updatedNewExtraHole;
        if (isEmpty || common || isZero) {
            return true;
        }
        return false;
    }

    const isGroundConditionDisabled = () => {
        return (updatedRedrill?.issue && updatedRedrill.delay > 0 && updatedHole.depth.redrilled > 0)
            || (updatedFlush?.issue && updatedFlush.delay > 0)
            || (updatedStuck?.issue && updatedStuck.delay > 0);
    }

    useEffect(() => {
        // if (tasks) {
            getDrillingActivity();
            getPatternsAndPits();
        // }
    }, [tasks]);

    useEffect(() => {
        setShowCard(true);
    }, []);

    const getDrillingActivity = () => {
        let patternHoles = 0;
        let additionalHoles = 0;
        let totalDepthPlanned = 0;
        let totalDepthEdited = 0;
        let totalDepthAdditional = 0;

        let holesRedrilled = 0;
        let holesFlushed = 0;
        let bogged = 0;
        let issues = 0;
        let groundWater = 0;
        let overburden = 0;
        let cavity = 0;
        let rc = 0;

        tasks?.forEach(task => {
            task?.holes?.forEach(hole => {
                if (hole?.shiftID === shift._id) {
                    patternHoles++;

                    totalDepthPlanned += hole?.depth?.planned;
                    totalDepthEdited += hole?.depth?.edited !== 0 ? hole?.depth?.edited : hole?.depth?.planned;

                    if (hole?.redrill?.issue) {
                        holesRedrilled++;
                    }
                    if (hole?.flush?.issue) {
                        holesFlushed++;
                    }
                    if (hole?.rodStuck?.issue) {
                        bogged++;
                    }
                    if (hole?.otherIssue?.issue) {
                        issues++;
                    }
                    if (hole?.excessiveWater?.issue) {
                        groundWater++;
                    }
                    if (hole?.excessiveOverburden?.issue) {
                        overburden++;
                    }
                    if (hole?.interceptCavity?.issue) {
                        cavity++;
                    }
                    if (hole?.interceptRcGc?.issue) {
                        rc++;
                    }
                }
            })

            if (task?.additionalHoles !== null && task?.additionalHoles?.length > 0) {
                task?.additionalHoles?.forEach(additional => {
                    if (shift?.endTime === endTime) {
                        if ((additional.vehicleSerial === shift?.vehicleSerial && additional?.eventTime >= shift?.startTime) || additional?.shiftId === shift?._id) {
                            additionalHoles += additional?.holes;
                            totalDepthAdditional += additional?.depth;
                        }
                    }
                    else {
                        if ((additional.vehicleSerial === shift?.vehicleSerial && additional?.eventTime >= shift?.startTime && additional?.eventTime <= shift?.endTime) || additional?.shiftId === shift?._id) {
                            additionalHoles += additional?.holes;
                            totalDepthAdditional += additional?.depth;
                        }
                    }
                })
            }
        });

        setDrillingActivity(
            {
                patternHoles: patternHoles,
                additionalHoles: additionalHoles,
                totalDepthPlanned: totalDepthPlanned,
                totalDepthEdited: totalDepthEdited,
                totalDepthAdditional: totalDepthAdditional,
                holesRedrilled: holesRedrilled,
                holesFlushed: holesFlushed,
                bogged: bogged,
                issues: issues,
                groundWater: groundWater,
                overburden: overburden,
                cavity: cavity,
                rc: rc
            }
        );
    }

    const compileHoleDetails = () => {
        const compiled = {...updatedHole, 
            completedBy: reducerState?.common?.user?.username,
            shiftID: reducerState?.shift?.shiftData?.shiftReport?._id,
            endTime: updatedHole?.endTime !== endTime ? updatedHole?.endTime : new Date().toISOString(),
            startTime: updatedHole?.startTime !== endTime ? updatedHole?.startTime : new Date().toISOString(),
            updatedTime: new Date().toISOString(),
            status: 3,
            angle: parseFloat(updatedHole?.angle),
            depth: {
                edited: parseFloat(updatedHole?.depth?.edited),
                editedTs: updatedHole?.depth?.editedTs,
                planned: parseFloat(updatedHole?.depth?.planned),
                redrilled: parseFloat(updatedHole?.depth?.redrilled),
                redrilledTs: updatedHole?.depth?.redrilledTs,
            },

            redrill: {
                issue: updatedRedrill?.issue,
                delay: parseFloat(updatedRedrill?.delay),
                eventTime: updatedRedrill?.eventTime
            },
            flush: {
                issue: updatedFlush?.issue,
                delay: parseFloat(updatedFlush?.delay),
                eventTime: updatedFlush?.eventTime
            },
            rodStuck: {
                issue: updatedStuck?.issue,
                delay: parseFloat(updatedStuck?.delay),
                eventTime: updatedStuck?.eventTime
            },
            otherIssue: {
                issue: updatedOther?.issue,
                delay: parseFloat(updatedOther?.delay),
                eventTime: updatedOther?.eventTime
            },

            excessiveWater: {
                issue: updatedWater?.issue,
                delay: parseFloat(updatedWater?.delay),
                eventTime: updatedWater?.eventTime
            },
            excessiveOverburden: {
                issue: updatedOverburden?.issue,
                delay: parseFloat(updatedOverburden?.delay),
                eventTime: updatedOverburden?.eventTime
            },
            interceptCavity: {
                issue: updatedCavity?.issue,
                delay: parseFloat(updatedCavity?.delay),
                eventTime: updatedCavity?.eventTime
            },
            interceptRcGc: {
                issue: updatedRcGc?.issue,
                delay: parseFloat(updatedRcGc?.delay),
                eventTime: updatedRcGc?.eventTime
            },
            notes: updatedHole?.notes,
            vehicleSerial: reducerState?.shift?.shiftData?.shiftReport?.vehicleSerial,
        }

        saveHoleDetails(compiled);
    }

    const saveHoleDetails = (updatedHole) => {
        setAvailableTaskOptions(availableTaskOptions.filter((x) => {return x.id !== task?.id}));
        dispatch(updateDrillingHole({taskId: task?.id, shiftId: reducerState?.shift?.shiftData?.shiftReport?._id, hole: updatedHole})).then(() => {
            dispatch(fetchShiftDrillTasks(reducerState?.shift?.shiftData?.shiftReport?._id)).then((data) => {
                const tasks = data?.payload;
                setTasks(tasks);
                tasks.forEach((t) => {
                    if (t.id === task?.id) {
                        setTask(t);
                    }
                })
            })
        });
        setMode('default');
        setHole(); 
        setShowTable(false);
        let holes = [...completedHoles?.flat()?.filter((hole) => {return hole.shiftID === shift._id && hole.endTime !== endTime && hole.number !== updatedHole.number}), updatedHole];
        holes.sort((a, b) => {return a.number > b.number});
        setCompletedHoles([...chunkify(holes, 4)]);
    }

    const cancelHoleDetails = () => {
        setPage(0);
        setMode('default');
        setHole(); 
    }

    const getPatternsAndPits = () => {
        let options = [];
        tasks?.forEach((task) => {
            options.push({id: task?.id, label: task?.pattern?.toUpperCase() + " — " + task?.pit?.toUpperCase()});
        })
        setTaskOptions([...options]);
        const availableOptions = reducerState?.shift?.availableDrillTasks?.filter((x) => {return !options?.map((t) => {return t.id}).includes(x.id)});
        setAvailableTaskOptions(availableOptions);
    }

    const getTaskDetails = (taskId) => {
        tasks?.forEach((task) => {
            if (task.id === taskId) {
                setTask(task);
            }
        })
    }

    const handleTabChange = (event, tab) => {
        dispatch(fetchShiftDrillTasks(reducerState?.shift?.shiftData?.shiftReport?._id)).then((data) => {
            setTasks(data?.payload);
        })
        setMode('default');
        setHole();
        setShowTable(false);
        setCompletedHoles();
        setTaskSelect('');
        setTask();
        cancelHoleDetails();
        
        setShowExtraHoles(false);
        setExtraHoles();
        setUpdatedNewExtraHole(newExtraHole);

        setTab(tab);
    }

    const handleTaskChange = (event, task) => {
        const { value } = event?.target;
        setHole();
        setMode('default');
        setTaskSelect(value);
        if (!tasks?.map((x) => { return x?.id}).includes(value)) {
            setCompletedHoles([]);
            let tasks = [];
            dispatch(fetchShiftDrillTasks(reducerState?.shift?.shiftData?.shiftReport?._id)).then((shift) => {
                shift?.payload?.forEach((s) => {
                    tasks?.push(s);
                })
                dispatch(fetchDrillTask(value)).then((data) => {
                    tasks?.push(data?.payload);

                    tasks?.forEach((task) => {
                        if (task?.id === value) {
                            setTask(task);
                        }
                    })
                })
            })
        }
        else {
            dispatch(fetchShiftDrillTasks(reducerState?.shift?.shiftData?.shiftReport?._id)).then((data) => {
                const t = data?.payload;
                setTasks(t);
                t?.forEach((task) => {
                    if (task?.id === value) {
                        setTask(task);
                    }
                })
            })
        }
    }

    const setDetails = (holeNum) => {
        let hole = {};
        if (completedHoles?.flat().map((x) => {return x?.number}).includes(parseInt(holeNum))) {
            hole = completedHoles?.flat()?.filter((hole) => {return hole.number === parseInt(holeNum)}).pop();
        }
        else {
            hole = task?.holes?.filter((hole) => {return hole?.number === parseInt(holeNum)}).pop();
        }
        setHole(hole);
        setUpdatedHole(hole);
        setCurrentDelay([hole.flush, hole.redrill, hole.rodStuck, hole.otherIssue].filter((d) => {return d?.issue === true})[0]);

        //set delays and conditions
        setUpdatedFlush(hole.flush);
        setUpdatedRedrill(hole.redrill);
        setUpdatedStuck(hole.rodStuck);
        setUpdatedOther(hole.otherIssue);

        setUpdatedWater(hole.excessiveWater);
        setUpdatedOverburden(hole.excessiveOverburden);
        setUpdatedCavity(hole.interceptCavity);
        setUpdatedRcGc(hole.interceptRcGc);

        if (hole.redrill.issue || hole.flush.issue || hole.rodStuck.issue || hole.otherIssue.issue) {
            setNoDelay(false);
        }
        else {
            setNoDelay(true);
        }
    }

    const handleHoleChange = (event) => {
        const {value} = event?.target;
        setDetails(value);
        setMode('edit');
    }

    const handlePatternHoleChange = (event) => {
        const {value} = event?.target;
        setDetails(value);
        setMode('pattern');
    }

    const chunkify = function* (itr, size) {
        let chunk = [];
        for (const v of itr) {
          chunk.push(v);
          if (chunk.length === size) {
            yield chunk;
            chunk = [];
          }
        }
        if (chunk.length) yield chunk;
    };

    const handleHolePageChange = (e, page) => {
        setPage(page-1);
    }

    const handlePlannedChange = (event) => {
        const { value } = event?.target;
        if (validateFloat(value)) {
            setUpdatedHole((prev) => ({
                ...prev,
                depth: {
                    planned: value,
                    edited: updatedHole?.depth?.edited,
                    editedTs: updatedHole?.depth?.editedTs,
                    redrilled: updatedHole?.depth?.redrilled,
                    redrilledTs: updatedHole?.depth?.redrilledTs,
                }
            }));
        }
    }

    const handleStartChange = (event) => {
        const { $d } = event;
        
        if ($d) {
            if (new Date($d).getTime() >= new Date(updatedHole?.endTime).getTime()){
                setUpdatedHole((prev) => ({
                    ...prev,
                    startTime: new Date($d).toISOString(),
                    endTime: new Date($d).toISOString()
                }));
            } else { 
                setUpdatedHole((prev) => ({
                    ...prev,
                    startTime: new Date($d).toISOString()
                }));
            }
        }
    }

    const handleEndChange = (event) => {
        const { $d } = event;
        if ($d) {
            setUpdatedHole((prev) => ({
                ...prev,
                endTime: new Date($d).toISOString()
            }));
        }
    }


    const handleEditedChange = (event) => {
        const { value } = event?.target;
        if (validateFloat(value)) {
            setUpdatedHole((prev) => ({
                ...prev,
                depth: {
                    planned: updatedHole?.depth?.planned,
                    edited: value,
                    editedTs: new Date().toISOString(),
                    redrilled: updatedHole.depth.redrilled,
                    redrilledTs: updatedHole.depth.redrilledTs,
                }
            }));
        }
    }

    const handleAngleChange = (event) => {
        const { value } = event?.target;
        if (validateFloat(value)) {
            setUpdatedHole((prev) => ({
                ...prev,
                angle: value
            }));
        }
    }

    const handleDrillChange = (event) => {
        const { value } = event.target;
        if (validateFloat(value)) {
            setUpdatedHole((prev) => ({
                ...prev,
                depth: {
                    planned: updatedHole?.depth?.planned,
                    edited: updatedHole?.depth?.edited,
                    editedTs: updatedHole?.depth?.editedTs,
                    redrilled: value,
                    redrilledTs: new Date().toISOString(),
                }
            }))
        }
    }

    const handleHoleNotesChange = (event) => {
        const { value } = event.target;
        setUpdatedHole((prev) => ({
            ...prev,
            notes: value,
        }))
    }

    const handleHrs = (event) => {
        const { value } = event.target;
        if (value === "") {
            setHours(0);
            updateDelay(parseFloat(0), minutes);
        }
        else if (validateFloat(value)) {
            setHours(parseFloat(value));
            updateDelay(parseFloat(value), minutes);
        }
    }

    const handleMins = (event) => {
        const { value } = event.target;
        if (value === "") {
            setMinutes(0);
            updateDelay(hours, parseFloat(0));
        }
        if (validateFloat(value) && value) {
            setMinutes(parseFloat(value));
            updateDelay(hours, parseFloat(value));
        }
    }

    const updateDelay = (hours, minutes) => {
        const hoursInMinutes = parseFloat(hours) * 60;
        const totalMinutes = hoursInMinutes + parseFloat(minutes);
        setCurrentDelay((prev) => ({
            ...prev,
            delay: totalMinutes
        }))
    };

    const   resetGroundCondition = () => {
        setUpdatedWater(defaultDelay);
        setUpdatedOverburden(defaultDelay);
        setUpdatedCavity(defaultDelay);
        setUpdatedRcGc(defaultDelay);
    }

    const handleNoneChange = () => {
        setNoDelay(true);
        setUpdatedFlush(defaultDelay);
        setUpdatedRedrill(defaultDelay);
        setUpdatedStuck(defaultDelay);
        setUpdatedOther(defaultDelay);
        resetGroundCondition();
        resetGroundCondition();
        setCurrentDelay(defaultDelay);
        setUpdatedHole((prev) => ({
            ...prev,
            flush: defaultDelay,
            redrill: defaultDelay,
            rodStuck: defaultDelay,
            otherIssue: defaultDelay,
        }));  
    }

    const handleFlushChange = () => {
        setUpdatedFlush((prev) => ({
            ...prev,
            issue: true,
            delay: updatedFlush?.delay,
            eventTime: updatedFlush?.eventTime
        }));
        setUpdatedRedrill(defaultDelay);
        setUpdatedStuck(defaultDelay);
        setUpdatedOther(defaultDelay);
        setCurrentDelay({
            issue: true,
            delay: updatedFlush?.delay,
            eventTime: updatedFlush?.eventTime
        });
        setNoDelay(false);
        setUpdatedHole((prev) => ({
            ...prev,
            flush: updatedFlush,
        }));

        resetGroundCondition();

        resetGroundCondition();
    }

    const handleRedrillChange = () => {
        setUpdatedFlush(defaultDelay);
        setUpdatedRedrill((prev) => ({
            ...prev,
            issue: true,
            delay: updatedRedrill?.delay,
            eventTime: updatedRedrill?.eventTime
        }));
        setUpdatedStuck(defaultDelay);
        setUpdatedOther(defaultDelay);
        setCurrentDelay({
            issue: true,
            delay: updatedRedrill?.delay,
            eventTime: updatedRedrill?.eventTime 
        });
        setNoDelay(false);
        setUpdatedHole((prev) => ({
            ...prev,
            redrill: updatedRedrill,
        }));

        resetGroundCondition();

        resetGroundCondition();
    }

    const handleStuckChange = () => {
        setUpdatedFlush(defaultDelay);
        setUpdatedRedrill(defaultDelay);
        setUpdatedStuck((prev) => ({
            ...prev,
            issue: true,
            delay: updatedStuck?.delay,
            eventTime: updatedStuck?.eventTime
        }));
        setUpdatedOther(defaultDelay);
        setCurrentDelay({
            issue: true,
            delay: updatedStuck?.delay,
            eventTime: updatedStuck?.eventTime
        });
        setNoDelay(false);
        setUpdatedHole((prev) => ({
            ...prev,
            rodStuck: updatedStuck,
        }));

        resetGroundCondition();

        resetGroundCondition();
    }

    const handleOtherChange = () => {
        setUpdatedFlush(defaultDelay);
        setUpdatedRedrill(defaultDelay);
        setUpdatedStuck(defaultDelay);
        setUpdatedOther((prev) => ({
            ...prev,
            issue: true,
            delay: updatedOther?.delay,
            eventTime: updatedOther?.eventTime
        }));
        setCurrentDelay({
            issue: true,
            delay: updatedOther?.delay,
            eventTime: updatedOther?.eventTime
        });

        resetGroundCondition();

        resetGroundCondition();
        setNoDelay(false);
        setUpdatedHole((prev) => ({
            ...prev,
            otherIssue: updatedOther,
        }));
    }

    const handleWaterChange = (event) => {
        const {checked} = event?.target;

        if (checked) {
            setUpdatedWater((prev) => ({
                ...prev,
                issue: true,
                delay: currentDelay?.delay,
                eventTime: currentDelay?.eventTime
            }));
        }
        else {
            setUpdatedWater(defaultDelay);
        }
        setUpdatedHole((prev) => ({
            ...prev,
            excessiveWater: updatedWater,
        }));
    }

    const handleOverburdenChange = (event) => {
        const {checked} = event?.target;

        if (checked) {
            setUpdatedOverburden((prev) => ({
                ...prev,
                issue: true,
                delay: currentDelay?.delay,
                eventTime: currentDelay?.eventTime
            }));
        }
        else {
            setUpdatedOverburden(defaultDelay);
        }
        setUpdatedHole((prev) => ({
            ...prev,
            excessiveOverburden: updatedOverburden,
        }));
    }

    const handleCavityChange = (event) => {
        const {checked} = event?.target;

        if (checked) {
            setUpdatedCavity((prev) => ({
                ...prev,
                issue: true,
                delay: currentDelay?.delay,
                eventTime: currentDelay?.eventTime
            }));
        }
        else {
            setUpdatedCavity(defaultDelay);
        }
        setUpdatedHole((prev) => ({
            ...prev,
            interceptCavity: updatedCavity,
        }));
    }

    const handleRcGcChange = (event) => {
        const {checked} = event?.target;

        if (checked) {
            setUpdatedRcGc((prev) => ({
                ...prev,
                issue: true,
                delay: currentDelay?.delay,
                eventTime: currentDelay?.eventTime
            }));
        }
        else {
            setUpdatedRcGc(defaultDelay);
        }
        setUpdatedHole((prev) => ({
            ...prev,
            interceptRcGc: updatedRcGc,
        }));
    }

    useEffect(() => {
        if (currentDelay) {
            setHours(Math.floor(currentDelay?.delay/60));
            setMinutes(currentDelay?.delay%60);

            if (updatedRedrill?.issue) {
                setUpdatedRedrill((prev) => ({
                    ...prev,
                    delay: currentDelay?.delay,
                    eventTime: new Date().toISOString()
                }))
            }
            if (updatedFlush?.issue) {
                setUpdatedFlush((prev) => ({
                    ...prev,
                    delay: currentDelay?.delay,
                    eventTime: new Date().toISOString()
                }))
            }
            if (updatedStuck?.issue) {
                setUpdatedStuck((prev) => ({
                    ...prev,
                    delay: currentDelay?.delay,
                    eventTime: new Date().toISOString()
                }))
            }
            if (updatedOther?.issue) {
                setUpdatedOther((prev) => ({
                    ...prev,
                    delay: currentDelay?.delay,
                    eventTime: new Date().toISOString()
                }))
            }

            if (updatedWater?.issue) {
                setUpdatedWater((prev) => ({
                    ...prev,
                    delay: currentDelay?.delay,
                    eventTime: new Date().toISOString()
                }));
            }
            if (updatedOverburden?.issue) {
                setUpdatedOverburden((prev) => ({
                    ...prev,
                    delay: currentDelay?.delay,
                    eventTime: new Date().toISOString()
                }));
            }
            if (updatedCavity?.issue) {
                setUpdatedCavity((prev) => ({
                    ...prev,
                    delay: currentDelay?.delay,
                    eventTime: new Date().toISOString()
                }));
            }
            if (updatedRcGc?.issue) {
                setUpdatedRcGc((prev) => ({
                    ...prev,
                    delay: currentDelay?.delay,
                    eventTime: new Date().toISOString()
                }));
            }
        }
    }, [currentDelay])


    useEffect(() => {
        if (task) {
            setCompletedHoles([...chunkify(task?.holes?.filter((hole) => {return hole.shiftID === shift._id && hole.endTime !== endTime}).sort((a, b) => {return a.number > b.number}), 4)]);
            getExtraHoles();
        }
    }, [task])

    useEffect(() => {
        if (completedHoles) {
            setShowTable(true);
        }
    }, [completedHoles])

    useEffect(() => {
        if (completedHoles?.length > 0) {
            setShowTable(true);
        }
    }, [page])


    const [extraHoles, setExtraHoles] = useState([]);
    const [showExtraHoles, setShowExtraHoles] = useState(false);
    const [newExtraHole, setNewExtraHole] = useState({
        holes: 0,
        depth: 0,
        notes: '',
        completedBy: reducerState?.common?.user?.username,
        vehicleSerial: reducerState?.shift?.shiftData?.shiftReport?.vehicleSerial,
        eventTime: ''
    })
    const [updatedNewExtraHole, setUpdatedNewExtraHole] = useState({
        holes: 0,
        depth: 0,
        notes: '',
        completedBy: reducerState?.common?.user?.username,
        vehicleSerial: reducerState?.shift?.shiftData?.shiftReport?.vehicleSerial,
        eventTime: ''
    })

    const handleExtraHoleChange = (event) => {
        const { name, value } = event?.target;
        let arr = ['holes', 'depth'];
        if (arr.includes(name)) {
            if (validateFloat(value)) {
                setUpdatedNewExtraHole((prev) => ({
                    ...prev,
                    [name]: value,
                }));
            }
        }
        else {
            setUpdatedNewExtraHole((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    }

    const formatTime = (time) => {
        const date = new Date(time);
        const options = { weekday: 'short', hour: '2-digit', minute: '2-digit', second: '2-digit', year: 'numeric', month: 'short', day: 'numeric', hour12: false };
        return date.toLocaleString('en-US', options).replaceAll(',', '');
    }

    const getExtraHoles = () => {
        let extra = [];
        task?.additionalHoles?.forEach((hole) => {
            extra.push(hole);
        })
        setExtraHoles([...extra]);
    }

    const saveExtraHole = () => {
        const extraHole = {...updatedNewExtraHole,
            holes: parseFloat(updatedNewExtraHole?.holes),
            depth: parseFloat(updatedNewExtraHole?.depth),
            notes: updatedNewExtraHole?.notes,
            eventTime: new Date().toISOString(),
            completedBy: reducerState?.common?.user?.username,
            vehicleSerial: reducerState?.shift?.shiftData?.shiftReport?.vehicleSerial,
            shiftId: shift._id,
        };

        dispatch(updateExtraHole({taskId: task?.id, shiftId: reducerState?.shift?.shiftData?.shiftReport?._id, extra: extraHole}));
        setExtraHoles([...extraHoles, extraHole]);
        setUpdatedNewExtraHole(newExtraHole);
    }

    const deleteExtraHole = (extra) => {
        dispatch(removeExtraHole({taskId: task?.id, shiftId: reducerState?.shift?.shiftData?.shiftReport?._id, extra: extra}));
        setExtraHoles([...extraHoles.filter((hole) => {return hole?.eventTime !== extra?.eventTime})]);
    }

    useEffect(() => {
        if (extraHoles) {
            setShowExtraHoles(true);
        }
    }, [extraHoles])

    useEffect(() => {
        if (task) {
        }
    }, [task])

  return (
    <>
        {showCard && (
            <Card title='Drilling Activity' 
            content={
                <div className="drilling-activity">
                    <Grid>
                        <Grid container className="patterns">
                            <Grid container className="line">
                                <Grid xs={6} sm={6} md={6} lg={6} item className="label"><TypographySpan>Number of pattern holes drilled</TypographySpan></Grid>
                                <Grid xs={6} sm={6} md={6} lg={6} item className="data"><TypographySpan>{drillingActivity.patternHoles}</TypographySpan></Grid>
                            </Grid>
                        </Grid>
                        <Grid container className="patterns">
                            <Grid container className="line">
                                <Grid xs={6} sm={6} md={6} lg={6} item className="label"><TypographySpan>Number of extra holes drilled</TypographySpan></Grid>
                                <Grid xs={6} sm={6} md={6} lg={6} item className="data"><TypographySpan>{drillingActivity.additionalHoles}</TypographySpan></Grid>
                            </Grid>
                        </Grid>
                        <Grid container className="patterns">
                            <Grid container className="line">
                                <Grid xs={6} sm={6} md={6} lg={6} item className="label"><TypographySpan>Total depth drilled, planned</TypographySpan></Grid>
                                <Grid xs={6} sm={6} md={6} lg={6} item className="data"><TypographySpan>{Math.round(drillingActivity.totalDepthPlanned*100)/100} meters</TypographySpan></Grid>
                            </Grid>
                        </Grid>
                        <Grid container className="patterns">
                            <Grid container className="line">
                                <Grid xs={6} sm={6} md={6} lg={6} item className="label"><TypographySpan>Total depth drilled, edited</TypographySpan></Grid>
                                <Grid xs={6} sm={6} md={6} lg={6} item className="data"><TypographySpan>{Math.round(drillingActivity.totalDepthEdited*100)/100} meters</TypographySpan></Grid>
                            </Grid>
                        </Grid>
                        <Grid container className="patterns">
                            <Grid container className="line">
                                <Grid xs={6} sm={6} md={6} lg={6} item className="label"><TypographySpan>Total depth drilled, additional holes</TypographySpan></Grid>
                                <Grid xs={6} sm={6} md={6} lg={6} item className="data"><TypographySpan>{Math.round(drillingActivity.totalDepthAdditional*100)/100} meters</TypographySpan></Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid>
                        <TypographySpan variant="caption" sx={{fontWeight: '600', fontSize: '18px'}}>Reported Non-productive Occurrences</TypographySpan>
                    </Grid>

                    <Grid>
                        <Grid container className="holes">
                            <Grid container className="line">
                                <Grid xs={6} sm={6} md={6} lg={6} item className="label"><TypographySpan>Number of holes re-drilled</TypographySpan></Grid>
                                <Grid xs={6} sm={6} md={6} lg={6} item className="data"><TypographySpan>{drillingActivity.holesRedrilled}</TypographySpan></Grid>
                            </Grid>
                        </Grid>
                        <Grid container className="holes">
                            <Grid container className="line">
                                <Grid xs={6} sm={6} md={6} lg={6} item className="label"><TypographySpan>Number of holes flushed</TypographySpan></Grid>
                                <Grid xs={6} sm={6} md={6} lg={6} item className="data"><TypographySpan>{drillingActivity.holesFlushed}</TypographySpan></Grid>
                            </Grid>
                        </Grid>
                        <Grid container className="holes">
                            <Grid container className="line">
                                <Grid xs={6} sm={6} md={6} lg={6} item className="label"><TypographySpan>Number of times bogged or stuck rod</TypographySpan></Grid>
                                <Grid xs={6} sm={6} md={6} lg={6} item className="data"><TypographySpan>{drillingActivity.bogged}</TypographySpan></Grid>
                            </Grid>
                        </Grid>
                        <Grid container className="holes">
                            <Grid container className="line">
                                <Grid xs={6} sm={6} md={6} lg={6} item className="label"><TypographySpan>Number of times other issues occured</TypographySpan></Grid>
                                <Grid xs={6} sm={6} md={6} lg={6} item className="data"><TypographySpan>{drillingActivity.issues}</TypographySpan></Grid>
                            </Grid>
                        </Grid>
                        <Grid container className="holes">
                            <Grid container className="line">
                                <Grid xs={6} sm={6} md={6} lg={6} item className="label"><TypographySpan>Excessive ground water</TypographySpan></Grid>
                                <Grid xs={6} sm={6} md={6} lg={6} item className="data"><TypographySpan>{drillingActivity.groundWater}</TypographySpan></Grid>
                            </Grid>
                        </Grid>
                        <Grid container className="holes">
                            <Grid container className="line">
                                <Grid xs={6} sm={6} md={6} lg={6} item className="label"><TypographySpan>Excessive overburden (broken ground)</TypographySpan></Grid>
                                <Grid xs={6} sm={6} md={6} lg={6} item className="data"><TypographySpan>{drillingActivity.overburden}</TypographySpan></Grid>
                            </Grid>
                        </Grid>
                        <Grid container className="holes">
                            <Grid container className="line">
                                <Grid xs={6} sm={6} md={6} lg={6} item className="label"><TypographySpan>Intercept cavity/void</TypographySpan></Grid>
                                <Grid xs={6} sm={6} md={6} lg={6} item className="data"><TypographySpan>{drillingActivity.cavity}</TypographySpan></Grid>
                            </Grid>
                        </Grid>
                        <Grid container className="holes">
                            <Grid container className="line">
                                <Grid xs={6} sm={6} md={6} lg={6} item className="label"><TypographySpan>Intercept RC/GC hole</TypographySpan></Grid>
                                <Grid xs={6} sm={6} md={6} lg={6} item className="data"><TypographySpan>{drillingActivity.rc}</TypographySpan></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            }
            edit={
                <>
                    {editable ? (
                        <IconButton className="edit" aria-label="edit" size="small" onClick={openDrillingModal}>
                            <EditIcon/>
                        </IconButton>
                    ) : (
                        <></>
                    )}
                </>
            }
            canCollapse={true}
            collapsed={collapsed}>
            </Card>
        )}
        
        <Dialog
            className="drilling-modal"
            fullWidth={true}
            maxWidth='xl'
            open={drillingModal}
            onClose={closeDrillingModal}>
            <DialogTitle className="dialog-title">
            <FormatListBulletedIcon/> 
            <TypographySpan>Edit Drilling Activity</TypographySpan>
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="dialog-content">
                    <Container className="tabs">
                        <TabContext value={tab}>
                            <TabList onChange={handleTabChange}>
                                <Tab label={
                                    <TypographySpan sx={{fontSize: '18px'}} variant="body2">COMPLETED HOLES</TypographySpan>
                                } 
                                value="completed-holes" />
                                <Tab label={
                                    <TypographySpan sx={{fontSize: '18px'}} variant="body2">EXTRA HOLES</TypographySpan>
                                }  value="extra-holes" />
                            </TabList>



                            <TabPanel value="completed-holes" className="completed-holes">
                                <Grid container gap={4} lg={12}>

                                    <Grid container lg={12} className="pattern">
                                        <FormControl>
                                            <InputLabel id="completed-holes-pattern-select">Task (Pattern/Pit)</InputLabel>
                                            <Select
                                                value={taskSelect}
                                                label="Task (Pattern/Pit)"
                                                onChange={handleTaskChange}
                                                displayEmpty
                                                style={{minWidth: '500px', maxWidth: '500px'}}
                                                autoWidth
                                                MenuProps={menuProps}
                                                variant="standard">

                                                    <MenuItem value={'workedon'} disabled sx={{display: taskOptions?.length > 0 ? 'flex' : 'none'}}>
                                                        <TypographySpan variant="caption" sx={{fontSize: '24px'}}>Worked On</TypographySpan>
                                                    </MenuItem>
                                                    {
                                                        taskOptions?.map((x) => {
                                                            return (
                                                                <MenuItem value={x.id}>
                                                                    <TypographySpan variant="caption" sx={{fontSize: '24px'}}>{x.label}</TypographySpan>
                                                                </MenuItem>
                                                            );
                                                        })
                                                    }
                                                    <MenuItem value={'available'} disabled sx={{display: availableTaskOptions?.length > 0 ? 'flex' : 'none'}}>
                                                        <TypographySpan variant="caption" sx={{fontSize: '24px'}}>Available</TypographySpan>
                                                    </MenuItem>
                                                    {
                                                        availableTaskOptions?.map((x) => {
                                                            return (
                                                                <MenuItem value={x.id}>
                                                                    <TypographySpan variant="caption" sx={{fontSize: '24px'}}>{x.name}</TypographySpan>
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid container xs={12} sm={12} md={12} lg={12} display={'flex'} flexDirection={'column'} wrap="nowrap">
                                        <Grid container xs={12} sm={12} md={12} lg={12} sx={{opacity: taskSelect ? '1' : '0.5', pointerEvents: taskSelect ? 'auto' : 'none'}} gap={2} className="holes" alignContent={'start'}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} className="header">
                                                <TypographySpan variant="caption">Select a hole to edit</TypographySpan>
                                                <Button variant="contained" color="primary" onClick={() => {addPatternHole()}}>Add Pattern Hole</Button>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} display={'flex'} flexDirection={'column'} className="table" sx={{opacity: mode==="pattern" || mode==="pattern-default" ? '0.5' : '1', pointerEvents: mode==="pattern" || mode==="pattern-default" ? 'none' : 'auto'}}>
                                                <Grid item className="header" xs={12} sm={12} md={12} lg={12} display={'flex'} flexDirection={'row'}>
                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                        <TypographySpan variant="caption">Select</TypographySpan>
                                                    </Grid>
                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                        <TypographySpan variant="caption">Hole ID</TypographySpan>
                                                    </Grid>
                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                        <TypographySpan variant="caption">Started</TypographySpan>
                                                    </Grid>
                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                        <TypographySpan variant="caption">Completed</TypographySpan>
                                                    </Grid>
                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                        <TypographySpan variant="caption">Depth (Planned)</TypographySpan>
                                                    </Grid>
                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                        <TypographySpan variant="caption">Depth (Edited)</TypographySpan>
                                                    </Grid>
                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                        <TypographySpan variant="caption">Drilling Angle</TypographySpan>
                                                    </Grid>
                                                    <Grid item xs sm md lg={1}></Grid>
                                                </Grid>
                                                {showTable ? (
                                                    <>
                                                        {completedHoles[page]?.map((h) => {
                                                            return (
                                                                <Grid item className="data" xs={12} sm={12} md={12} lg={12} display={'flex'} flexDirection={'row'} backgroundColor={hole?.number === h?.number ? 'rgba(80, 140, 230, 0.08) !important' : ''}>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                                        <FormControl sx={{paddingLeft: '22px'}}>
                                                                            <RadioGroup
                                                                            onChange={handleHoleChange}
                                                                                defaultValue=""
                                                                                name="radio-buttons-group"
                                                                            >
                                                                                <FormControlLabel value={h?.number} control={<Radio size="small" checked={hole ? (hole?.number === h?.number ? true : false) : false} />} label="" />
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                                        <TypographySpan variant="caption">
                                                                            {h?.number}
                                                                           </TypographySpan>
                                                                    </Grid>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                                        <TypographySpan variant="caption">
                                                                            {DateToTime(h?.startTime)}
                                                                        </TypographySpan>
                                                                    </Grid>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                                        <TypographySpan variant="caption">
                                                                            {DateToTime(h?.endTime)}
                                                                        </TypographySpan>
                                                                    </Grid>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                                        <TypographySpan variant="caption">
                                                                            {h?.depth?.planned} m
                                                                        </TypographySpan>
                                                                    </Grid>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                                        <TypographySpan variant="caption">
                                                                            {h?.depth?.edited} m
                                                                        </TypographySpan>
                                                                    </Grid>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                                        <TypographySpan variant="caption">
                                                                            {h?.angle}º
                                                                        </TypographySpan>
                                                                    </Grid>
                                                                    <Grid item xs sm md lg={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                                        <IconButton sx={{opacity: hole?.number === h?.number ? '1' : '0.4', pointerEvents: hole?.number === h?.number ? 'auto' : 'none'}}
                                                                            onClick={() => {removeCompletedHole(h)}}>
                                                                            <TrashIcon/>
                                                                        </IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            )
                                                        })}
                                                    </>
                                                      
                                                ) : (
                                                    <>
                                                        {[1, 2, 3, 4].map((i) => {
                                                            return (
                                                                <Grid item className="data" xs={12} sm={12} md={12} lg={12} display={'flex'} flexDirection={'row'}>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                                        <TypographySpan sx={{opacity: '0'}}>a</TypographySpan>
                                                                    </Grid>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}></Grid>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}></Grid>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}></Grid>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}></Grid>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}></Grid>
                                                                    <Grid item xs sm md lg={1} display={'flex'} justifyContent={'center'} alignItems={'center'}></Grid>
                                                                </Grid>
                                                            )
                                                        })}
                                                    </>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} display={'flex'} justifyContent={'right'} sx={{opacity: mode==="pattern" || mode==="pattern-default" ? '0.5' : (taskSelect ? '1' : '0.5'), pointerEvents: mode==="pattern" || mode==="pattern-default" ? 'none' : (taskSelect ? 'auto' : 'none')}}>
                                            <Pagination count={completedHoles ? completedHoles.length : 1} page={page+1} onChange={handleHolePageChange}/>
                                        </Grid>
                                    </Grid>

                                    {/* this is the hole details view when you select a pattern hole from the list */}
                                    {mode === "pattern" && (
                                        <Grid container lg={12} className="details" gap={1}>
                                            <Grid item lg={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} className="header">
                                                <Grid item>
                                                    <TypographySpan variant="caption">Add a Pattern Hole</TypographySpan>
                                                </Grid>
                                                <Grid item display={'flex'} gap={1}>
                                                    <Button variant="contained" color="cancel" onClick={cancelHoleDetails}>Cancel</Button> 
                                                    <Button variant="contained" color="primary" onClick={compileHoleDetails} disabled={isDisabled('add')}>Save Hole</Button>
                                                </Grid>
                                            </Grid>
                                            <Grid container className="report">
                                                <Card title="Drilling Report"
                                                content={
                                                    <Grid container lg={12}>

                                                            <Grid container lg={12} gap={4}>
                                                                <Grid container lg={12} display={'flex'} flexDirection={'column'} wrap="nowrap" className="hole">
                                                                    <Grid container xs={12} sm={12} md={12} lg={12} gap={8}>
                                                                        <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                            <TypographySpan variant="caption">Hole ID</TypographySpan>
                                                                            <FormControl>
                                                                                <Select
                                                                                    value={updatedHole?.number}
                                                                                    onChange={handlePatternHoleChange}
                                                                                    displayEmpty
                                                                                    style={{minWidth: '300px', maxWidth: '300px'}}
                                                                                    autoWidth
                                                                                    MenuProps={holeMenuProps}
                                                                                    variant="standard">

                                                                                    <MenuItem value='' style={{display: 'none'}}>
                                                                                        <TypographySpan variant="caption" sx={{fontSize: '24px'}}>Select hole...</TypographySpan>
                                                                                    </MenuItem>

                                                                                    {
                                                                                        task?.holes?.map((x) => {
                                                                                            if (x.shiftID !== shift._id && x.endTime === endTime) {
                                                                                                return (
                                                                                                    <MenuItem value={x.number}>
                                                                                                        <TypographySpan variant="caption" sx={{fontSize: '24px'}}>{x.number}</TypographySpan>
                                                                                                    </MenuItem>
                                                                                                );
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid>
                                                                        <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                            <TypographySpan variant="caption">Planned drilling depth</TypographySpan>
                                                                            <TextField 
                                                                                sx={{minWidth: '300px', maxWidth: '300px'}} 
                                                                                variant="standard" 
                                                                                onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}}
                                                                                onChange={handlePlannedChange}
                                                                                InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>m</InputAdornment>}}
                                                                                value={updatedHole?.depth?.planned}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid container xs={12} sm={12} md={12} lg={12} gap={8}>
                                                                        <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                            <TypographySpan variant="caption">Drilling started at</TypographySpan>
                                                                            <TimePicker 
                                                                                inputProps={{readOnly: true}}
                                                                                ampm={false}
                                                                                PaperProps={{sx: {minWidth: '400px'}}}
                                                                                onChange={handleStartChange}
                                                                                renderInput={(params) => (
                                                                                    <TextField variant="standard" {...params} />
                                                                                )}
                                                                                value={updatedHole?.startTime !== "0001-01-01T00:00:00Z" ? updatedHole?.startTime : new Date().toISOString()}
                                                                                sx={{maxWidth: '200px'}}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                            <TypographySpan variant="caption">Edited drilling depth</TypographySpan>
                                                                            <TextField 
                                                                                sx={{minWidth: '300px', maxWidth: '300px'}} 
                                                                                variant="standard" 
                                                                                onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}}
                                                                                onChange={handleEditedChange}
                                                                                InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>m</InputAdornment>}}
                                                                                value={updatedHole?.depth?.edited}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid container xs={12} sm={12} md={12} lg={12} gap={8}>
                                                                        <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                            <TypographySpan variant="caption">Drilling completed at</TypographySpan>
                                                                            <TimePicker 
                                                                                ampm={false}
                                                                                PaperProps={{sx: {minWidth: '400px'}}}
                                                                                onChange={handleEndChange}
                                                                                renderInput={(params) => (
                                                                                    <TextField variant="standard" {...params} />
                                                                                )}
                                                                                inputProps={{readOnly: true}}
                                                                                value={updatedHole?.endTime !== "0001-01-01T00:00:00Z" ? updatedHole?.endTime : new Date().toISOString()}
                                                                                minTime={updatedHole?.startTime !== "0001-01-01T00:00:00Z" ? dayjs(updatedHole?.startTime) : dayjs()}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                            <TypographySpan variant="caption">Drilling angle</TypographySpan>
                                                                            <TextField 
                                                                                sx={{minWidth: '300px', maxWidth: '300px'}} 
                                                                                variant="standard" 
                                                                                onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}}
                                                                                onChange={handleAngleChange}
                                                                                InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>degrees</InputAdornment>}}
                                                                                value={updatedHole?.angle}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container lg={12} display={'flex'} flexDirection={'row'}>
                                                                    <Grid container lg className="non-prod">

                                                                        <Grid item lg={12} marginBottom={'12px'}>
                                                                            <TypographySpan>Non-productive - Operational Issues</TypographySpan>
                                                                        </Grid>

                                                                        <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                            <Grid container lg>
                                                                                <Radio size="small" checked={noDelay} onChange={handleNoneChange} />
                                                                                <TypographySpan>No issues</TypographySpan>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                            <Grid container lg>
                                                                                <Radio size="small" checked={updatedRedrill?.issue} name="redrill" onChange={handleRedrillChange} />
                                                                                <TypographySpan>Hole was re-drilled</TypographySpan>
                                                                            </Grid>
                                                                            <Grid container xs sm md lg display={'flex'} justifyContent={'flex-end'} gap={2} sx={{opacity: updatedRedrill?.issue ? '1' : '0', pointerEvents: updatedRedrill?.issue ? 'auto' : 'none'}}>
                                                                                <Grid item lg>
                                                                                    <TextField 
                                                                                        variant="standard" 
                                                                                        onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}}
                                                                                        onChange={handleHrs}
                                                                                        InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>hrs</InputAdornment>}}
                                                                                        value={hours}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item lg>
                                                                                    <TextField 
                                                                                        variant="standard" 
                                                                                        onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}}
                                                                                        onChange={handleMins}
                                                                                        InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>mins</InputAdornment>}}
                                                                                        value={minutes}
                                                                                    />
                                                                                </Grid> 
                                                                                <Grid item lg>
                                                                                    <TextField 
                                                                                        variant="standard" 
                                                                                        name="redrilled"
                                                                                        onChange={handleDrillChange}
                                                                                        InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>m</InputAdornment>}}
                                                                                        value={updatedHole?.depth?.redrilled}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                            <Grid container lg>
                                                                                <Radio size="small" checked={updatedFlush?.issue} name="flush" onChange={handleFlushChange}/>
                                                                                <TypographySpan>Hole needs to be flushed</TypographySpan>
                                                                            </Grid>
                                                                            <Grid container lg display={'flex'} justifyContent={'flex-end'} gap={2} sx={{opacity: updatedFlush?.issue ? '1' : '0', pointerEvents: updatedFlush?.issue ? 'auto' : 'none'}}>
                                                                                <Grid item lg>
                                                                                    <TextField 
                                                                                        variant="standard" 
                                                                                        onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}}
                                                                                        onChange={handleHrs}
                                                                                        InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>hrs</InputAdornment>}}
                                                                                        value={hours}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item lg>
                                                                                    <TextField 
                                                                                        variant="standard" 
                                                                                        onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}}
                                                                                        onChange={handleMins}
                                                                                        InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>mins</InputAdornment>}}
                                                                                        value={minutes}
                                                                                    />
                                                                                </Grid>                                                                            
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                            <Grid container lg>
                                                                                <Radio size="small" checked={updatedStuck?.issue} name="rodStuck" onChange={handleStuckChange} />
                                                                                <TypographySpan>Rod was bogged/stuck</TypographySpan>
                                                                            </Grid>
                                                                            <Grid container lg display={'flex'} justifyContent={'flex-end'} gap={2} sx={{opacity: updatedStuck?.issue ? '1' : '0', pointerEvents: updatedStuck?.issue ? 'auto' : 'none'}}>
                                                                                <Grid item lg>
                                                                                    <TextField 
                                                                                        variant="standard" 
                                                                                        onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}}
                                                                                        onChange={handleHrs}
                                                                                        InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>hrs</InputAdornment>}}
                                                                                        value={hours}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item lg>
                                                                                    <TextField 
                                                                                        variant="standard" 
                                                                                        onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}}
                                                                                        onChange={handleMins}
                                                                                        InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>mins</InputAdornment>}}
                                                                                        value={minutes}
                                                                                    />
                                                                                </Grid>                                                                            
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                            <Grid container lg>
                                                                                <Radio size="small" checked={updatedOther?.issue} name="otherIssue" onChange={handleOtherChange} />
                                                                                <TypographySpan>Other issue</TypographySpan>
                                                                            </Grid>
                                                                            <Grid container lg display={'flex'} justifyContent={'flex-end'} gap={1} sx={{opacity: updatedOther?.issue ? '1' : '0', pointerEvents: updatedOther?.issue ? 'auto' : 'none'}}>
                                                                                <Grid container lg={12} gap={2}>
                                                                                    <Grid item lg>
                                                                                        <TextField 
                                                                                            variant="standard" 
                                                                                            onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}}
                                                                                            onChange={handleHrs}
                                                                                            InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>hrs</InputAdornment>}}
                                                                                            value={hours}
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item lg>
                                                                                        <TextField 
                                                                                            variant="standard" 
                                                                                            onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}}
                                                                                            onChange={handleMins}
                                                                                            InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>mins</InputAdornment>}}
                                                                                            value={minutes}
                                                                                        />
                                                                                    </Grid> 
                                                                                </Grid>
                                                                                <Grid container lg={12}>
                                                                                    <Grid item lg>
                                                                                        <TextField 
                                                                                            variant="standard" 
                                                                                            name="redrilled"
                                                                                            onChange={handleHoleNotesChange}
                                                                                            placeholder="Enter a description"
                                                                                            value={updatedHole?.notes}
                                                                                            title={updatedHole?.notes}
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>                                                                      
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid container lg className="issues"
                                                                        sx={{
                                                                            opacity: (isGroundConditionDisabled()) ? '1' : '0.5', 
                                                                            pointerEvents: (isGroundConditionDisabled()) ? 'auto' : 'none'
                                                                        }}>
                                                                        <Grid item lg={12} marginBottom={'12px'}>
                                                                            <TypographySpan>Ground Condition (Cause)</TypographySpan>
                                                                        </Grid>

                                                                        <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                            <Grid container lg>
                                                                                <Checkbox size="small" checked={updatedWater?.issue} {...hole?.number + '-excessiveWater'} onChange={handleWaterChange} /> 
                                                                                <TypographySpan>Excessive ground water</TypographySpan>
                                                                            </Grid>
                                                                            <Grid container lg display={'flex'} justifyContent={'flex-end'} gap={2} sx={{opacity: updatedWater?.issue ? '0.5' : '0'}}>
                                                                                <TypographySpan>{Math.floor(updatedWater?.delay/60)}hr {updatedWater?.delay%60}min</TypographySpan>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                            <Grid container lg>
                                                                                <Checkbox size="small" checked={updatedOverburden?.issue} {...hole?.number + '-excessiveWater'} onChange={handleOverburdenChange} /> 
                                                                                <TypographySpan>Excessive overburden</TypographySpan>
                                                                            </Grid>
                                                                            <Grid container lg display={'flex'} justifyContent={'flex-end'} gap={2} sx={{opacity: updatedOverburden?.issue ? '0.5' : '0'}}>
                                                                                <TypographySpan>{Math.floor(updatedOverburden?.delay/60)}hr {updatedOverburden?.delay%60}min</TypographySpan>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                            <Grid container lg>
                                                                                <Checkbox size="small" checked={updatedCavity?.issue} {...hole?.number + '-excessiveWater'} onChange={handleCavityChange} /> 
                                                                                <TypographySpan>Intercept cavity/void</TypographySpan>
                                                                            </Grid>
                                                                            <Grid container lg display={'flex'} justifyContent={'flex-end'} gap={2} sx={{opacity: updatedCavity?.issue ? '0.5' : '0'}}>
                                                                                <TypographySpan>{Math.floor(updatedCavity?.delay/60)}hr {updatedCavity?.delay%60}min</TypographySpan>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                            <Grid container lg>
                                                                                <Checkbox size="small" checked={updatedRcGc?.issue} {...hole?.number + '-excessiveWater'} onChange={handleRcGcChange} /> 
                                                                                <TypographySpan>Intercept RC/GC hole</TypographySpan>
                                                                            </Grid>
                                                                            <Grid container lg display={'flex'} justifyContent={'flex-end'} gap={2} sx={{opacity: updatedRcGc?.issue ? '0.5' : '0'}}>
                                                                                <TypographySpan>{Math.floor(updatedRcGc?.delay/60)}hr {updatedRcGc?.delay%60}min</TypographySpan>
                                                                            </Grid>
                                                                        </Grid>
                                                                        {/* used to fill in the ground conditions so they don't have as big of a gap */}
                                                                        <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{opacity: '0'}}>
                                                                            <Grid container lg>
                                                                                <TypographySpan>empty</TypographySpan>
                                                                            </Grid>
                                                                            <Grid container lg display={'flex'} justifyContent={'flex-end'} gap={2}>
                                                                                <TypographySpan>empty</TypographySpan>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{opacity: '0'}}>
                                                                            <Grid container lg>
                                                                                <TypographySpan>empty</TypographySpan>
                                                                            </Grid>
                                                                            <Grid container lg display={'flex'} justifyContent={'flex-end'} gap={2}>
                                                                                <TypographySpan>empty</TypographySpan>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                    </Grid>
                                                }>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    )}

                                    {/* this is the hole details view when you select to add a pattern hole */}
                                    {mode === "pattern-default" && (
                                        <Grid container lg={12} className="details" gap={1}>
                                            <Grid item lg={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} className="header">
                                                <Grid item>
                                                    <TypographySpan variant="caption">Add a Pattern Hole</TypographySpan>
                                                </Grid>
                                                <Grid item display={'flex'} gap={1}>
                                                    <Button variant="contained" color="cancel" onClick={cancelHoleDetails}>Cancel</Button> 
                                                    <Button variant="contained" color="primary" onClick={compileHoleDetails} sx={{opacity: '0.5', pointerEvents: 'none'}}>Save Hole</Button>
                                                </Grid>
                                            </Grid>
                                            <Grid container className="report">
                                                <Card title="Drilling Report"
                                                content={
                                                    <Grid container lg={12}>
                                                        <Grid container lg={12} gap={4}>
                                                        <Grid container lg={12} display={'flex'} flexDirection={'column'} wrap="no-wrap" className="hole">
                                                            <Grid container xs={12} sm={12} md={12} lg={12} gap={8}>
                                                                <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                        <TypographySpan variant="caption">Hole ID</TypographySpan>
                                                                        <FormControl>
                                                                            <Select
                                                                                value=''
                                                                                onChange={handlePatternHoleChange}
                                                                                displayEmpty
                                                                                style={{minWidth: '300px', maxWidth: '300px'}}
                                                                                autoWidth
                                                                                MenuProps={holeMenuProps}
                                                                                variant="standard">

                                                                                <MenuItem value='' style={{display: 'none'}}>
                                                                                    <TypographySpan variant="caption" sx={{fontSize: '24px'}}>Select hole...</TypographySpan>
                                                                                </MenuItem>

                                                                                {
                                                                                    task?.holes?.map((x) => {
                                                                                        if (x?.shiftID !== shift?._id && x?.endTime === endTime) {
                                                                                            return (
                                                                                                <MenuItem value={x.number}>
                                                                                                    <TypographySpan variant="caption" sx={{fontSize: '24px'}}>{x?.number}</TypographySpan>
                                                                                                </MenuItem>
                                                                                            );
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </Select>
                                                                        </FormControl>
                                                                </Grid>
                                                                <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx={{opacity: '0.5', pointerEvents: 'none'}}>
                                                                    <TypographySpan variant="caption">Planned drilling depth</TypographySpan>
                                                                    <TextField 
                                                                        sx={{minWidth: '300px', maxWidth: '300px'}} 
                                                                        variant="standard" 
                                                                        InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>m</InputAdornment>}}
                                                                        value={'##'}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container xs={12} sm={12} md={12} lg={12} gap={8} sx={{opacity: '0.5', pointerEvents: 'none'}}>
                                                                <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                    <TypographySpan variant="caption">Drilling started at</TypographySpan>
                                                                    <TextField 
                                                                        sx={{minWidth: '300px', maxWidth: '300px'}} 
                                                                        variant="standard" 
                                                                        InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}><AccessTimeOutlinedIcon/></InputAdornment>}}
                                                                        value={'hh:mm'}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                    <TypographySpan variant="caption">Actual drilling depth</TypographySpan>
                                                                    <TextField 
                                                                        sx={{minWidth: '300px', maxWidth: '300px'}} 
                                                                        variant="standard" 
                                                                        InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>m</InputAdornment>}}
                                                                        value={'##'}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container xs={12} sm={12} md={12} lg={12} gap={8} sx={{opacity: '0.5', pointerEvents: 'none'}}>
                                                                <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                    <TypographySpan variant="caption">Drilling completed at</TypographySpan>
                                                                    <TextField 
                                                                        sx={{minWidth: '300px', maxWidth: '300px'}} 
                                                                        variant="standard" 
                                                                        InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}><AccessTimeOutlinedIcon/></InputAdornment>}}
                                                                        value={'hh:mm'}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                    <TypographySpan variant="caption">Drilling angle</TypographySpan>
                                                                    <TextField 
                                                                        sx={{minWidth: '300px', maxWidth: '300px'}} 
                                                                        variant="standard" 
                                                                        InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>degrees</InputAdornment>}}
                                                                        value={'##'}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container lg={12} display={'flex'} flexDirection={'row'} sx={{opacity: '0.5', pointerEvents: 'none'}}>
                                                            <Grid container lg className="non-prod">
                                                                <Grid item lg={12} marginBottom={'12px'}>
                                                                    <TypographySpan>Non-productive - Operational Issues</TypographySpan>
                                                                </Grid>
                                                                <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                    <Grid container lg>
                                                                        <Radio size="small" checked={false} />
                                                                        <TypographySpan>No issues</TypographySpan>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                    <Grid container lg>
                                                                        <Radio size="small" checked={false} />
                                                                        <TypographySpan>Hole was re-drilled</TypographySpan>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                    <Grid container lg>
                                                                        <Radio size="small" checked={false} />
                                                                        <TypographySpan>Hole needs to be flushed</TypographySpan>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                    <Grid container lg>
                                                                        <Radio size="small" checked={false} />
                                                                        <TypographySpan>Rod was bogged/stuck</TypographySpan>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                    <Grid container lg>
                                                                        <Radio size="small" checked={false} />
                                                                        <TypographySpan>Other issue</TypographySpan>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container lg className="issues">
                                                                <Grid item lg={12} marginBottom={'12px'}>
                                                                    <TypographySpan>Ground Condition (Cause)</TypographySpan>
                                                                </Grid>
                                                                <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                    <Grid container lg>
                                                                        <Checkbox size="small" checked={false} /> 
                                                                        <TypographySpan>Excessive ground water</TypographySpan>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                    <Grid container lg>
                                                                        <Checkbox size="small" checked={false} /> 
                                                                        <TypographySpan>Excessive overburden</TypographySpan>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                    <Grid container lg>
                                                                        <Checkbox size="small" checked={false} /> 
                                                                        <TypographySpan>Intercept cavity/void</TypographySpan>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                    <Grid container lg>
                                                                        <Checkbox size="small" checked={false} /> 
                                                                        <TypographySpan>Intercept RC/GC hole</TypographySpan>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    </Grid>
                                                }>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    )}

                                    {/* this is the hole details view when you select a completed hole from the table */}
                                    {mode === "edit" && (
                                        <Grid container lg={12} className="details" gap={1}>
                                            <Grid item lg={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} className="header">
                                                <Grid item>
                                                    <TypographySpan variant="caption">Edit hole {hole ? hole?.number : '##'}</TypographySpan>
                                                </Grid>
                                                <Grid item display={'flex'} gap={1}>
                                                    <Button variant="contained" color="cancel" onClick={cancelHoleDetails}>Cancel</Button> 
                                                    <Button variant="contained" color="primary" onClick={compileHoleDetails} disabled={isDisabled()}>Save Hole</Button>
                                                </Grid>
                                            </Grid>
                                            <Grid container className="report">
                                                <Card title="Drilling Report"
                                                content={
                                                    <Grid container lg={12}>
                                                        <Grid container lg={12} gap={4}>
                                                            <Grid container lg={12} display={'flex'} flexDirection={'column'} wrap="nowrap" className="hole">
                                                                <Grid container xs={12} sm={12} md={12} lg={12} gap={8}>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                        <TypographySpan variant="caption">Hole ID</TypographySpan>
                                                                        <TextField 
                                                                            sx={{minWidth: '300px', maxWidth: '300px'}} 
                                                                            variant="standard" 
                                                                            disabled
                                                                            value={hole?.number}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                        <TypographySpan variant="caption">Planned drilling depth</TypographySpan>
                                                                        <TextField 
                                                                            sx={{minWidth: '300px', maxWidth: '300px'}} 
                                                                            variant="standard" 
                                                                            onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}}
                                                                            onChange={handlePlannedChange}
                                                                            InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>m</InputAdornment>}}
                                                                            value={updatedHole?.depth?.planned}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container xs={12} sm={12} md={12} lg={12} gap={8}>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                        <TypographySpan variant="caption">Drilling started at</TypographySpan>
                                                                        <TimePicker 
                                                                            inputProps={{readOnly: true}}
                                                                            ampm={false}
                                                                            PaperProps={{sx: {minWidth: '400px'}}}
                                                                            onChange={handleStartChange}
                                                                            renderInput={(params) => (
                                                                                <TextField variant="standard" {...params} />
                                                                            )}
                                                                            value={updatedHole?.startTime}
                                                                            sx={{maxWidth: '200px'}}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                        <TypographySpan variant="caption">Edited drilling depth</TypographySpan>
                                                                        <TextField 
                                                                            sx={{minWidth: '300px', maxWidth: '300px'}} 
                                                                            variant="standard" 
                                                                            onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}}
                                                                            onChange={handleEditedChange}
                                                                            InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>m</InputAdornment>}}
                                                                            value={updatedHole?.depth?.edited}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container xs={12} sm={12} md={12} lg={12} gap={8}>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                        <TypographySpan variant="caption">Drilling completed at</TypographySpan>
                                                                        <TimePicker 
                                                                            ampm={false}
                                                                            PaperProps={{sx: {minWidth: '400px'}}}
                                                                            onChange={handleEndChange}
                                                                            renderInput={(params) => (
                                                                                <TextField variant="standard" {...params} />
                                                                            )}
                                                                            inputProps={{readOnly: true}}
                                                                            value={updatedHole?.endTime}
                                                                            minTime={dayjs(updatedHole?.startTime)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                        <TypographySpan variant="caption">Drilling angle</TypographySpan>
                                                                        <TextField 
                                                                            sx={{minWidth: '300px', maxWidth: '300px'}} 
                                                                            variant="standard" 
                                                                            onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}}
                                                                            onChange={handleAngleChange}
                                                                            InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>degrees</InputAdornment>}}
                                                                            value={updatedHole?.angle}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container lg={12} display={'flex'} flexDirection={'row'}>
                                                                <Grid container lg className="non-prod">

                                                                    <Grid item lg={12} marginBottom={'12px'}>
                                                                        <TypographySpan>Non-productive - Operational Issues</TypographySpan>
                                                                    </Grid>

                                                                    <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                        <Grid container lg>
                                                                            <Radio size="small" checked={noDelay} onChange={handleNoneChange} />
                                                                            <TypographySpan>No issues</TypographySpan>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                        <Grid container lg>
                                                                            <Radio size="small" checked={updatedRedrill?.issue} name="redrill" onChange={handleRedrillChange} />
                                                                            <TypographySpan>Hole was re-drilled</TypographySpan>
                                                                        </Grid>
                                                                        <Grid container xs sm md lg display={'flex'} justifyContent={'flex-end'} gap={2} sx={{opacity: updatedRedrill.issue ? '1' : '0', pointerEvents: updatedRedrill?.issue ? 'auto' : 'none'}}>
                                                                            <Grid item lg>
                                                                                <TextField 
                                                                                    variant="standard" 
                                                                                    onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}}
                                                                                    onChange={handleHrs}
                                                                                    InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>hrs</InputAdornment>}}
                                                                                    value={hours}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item lg>
                                                                                <TextField 
                                                                                    variant="standard" 
                                                                                    onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}}
                                                                                    onChange={handleMins}
                                                                                    InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>mins</InputAdornment>}}
                                                                                    value={minutes}
                                                                                />
                                                                            </Grid> 
                                                                            <Grid item lg>
                                                                                <TextField 
                                                                                    variant="standard" 
                                                                                    name="redrilled"
                                                                                    onChange={handleDrillChange}
                                                                                    InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>m</InputAdornment>}}
                                                                                    value={updatedHole?.depth?.redrilled}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                        <Grid container lg>
                                                                            <Radio size="small" checked={updatedFlush?.issue} name="flush" onChange={handleFlushChange}/>
                                                                            <TypographySpan>Hole needs to be flushed</TypographySpan>
                                                                        </Grid>
                                                                        <Grid container lg display={'flex'} justifyContent={'flex-end'} gap={2} sx={{opacity: updatedFlush?.issue ? '1' : '0', pointerEvents: updatedFlush?.issue ? 'auto' : 'none'}}>
                                                                            <Grid item lg>
                                                                                <TextField 
                                                                                    variant="standard" 
                                                                                    onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}}
                                                                                    onChange={handleHrs}
                                                                                    InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>hrs</InputAdornment>}}
                                                                                    value={hours}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item lg>
                                                                                <TextField 
                                                                                    variant="standard" 
                                                                                    onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}}
                                                                                    onChange={handleMins}
                                                                                    InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>mins</InputAdornment>}}
                                                                                    value={minutes}
                                                                                />
                                                                            </Grid>                                                                            
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                        <Grid container lg>
                                                                            <Radio size="small" checked={updatedStuck?.issue} name="rodStuck" onChange={handleStuckChange} />
                                                                            <TypographySpan>Rod was bogged/stuck</TypographySpan>
                                                                        </Grid>
                                                                        <Grid container lg display={'flex'} justifyContent={'flex-end'} gap={2} sx={{opacity: updatedStuck?.issue ? '1' : '0', pointerEvents: updatedStuck?.issue ? 'auto' : 'none'}}>
                                                                            <Grid item lg>
                                                                                <TextField 
                                                                                    variant="standard" 
                                                                                    onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}}
                                                                                    onChange={handleHrs}
                                                                                    InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>hrs</InputAdornment>}}
                                                                                    value={hours}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item lg>
                                                                                <TextField 
                                                                                    variant="standard" 
                                                                                    onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}}
                                                                                    onChange={handleMins}
                                                                                    InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>mins</InputAdornment>}}
                                                                                    value={minutes}
                                                                                />
                                                                            </Grid>                                                                            
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                        <Grid container lg>
                                                                            <Radio size="small" checked={updatedOther?.issue} name="otherIssue" onChange={handleOtherChange} />
                                                                            <TypographySpan>Other issue</TypographySpan>
                                                                        </Grid>
                                                                        <Grid container lg display={'flex'} justifyContent={'flex-end'} gap={1} sx={{opacity: updatedOther?.issue ? '1' : '0', pointerEvents: updatedOther?.issue ? 'auto' : 'none'}}>
                                                                            <Grid container lg={12} gap={2}>
                                                                                <Grid item lg>
                                                                                    <TextField 
                                                                                        variant="standard" 
                                                                                        onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}}
                                                                                        onChange={handleHrs}
                                                                                        InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>hrs</InputAdornment>}}
                                                                                        value={hours}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item lg>
                                                                                    <TextField 
                                                                                        variant="standard" 
                                                                                        onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}}
                                                                                        onChange={handleMins}
                                                                                        InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>mins</InputAdornment>}}
                                                                                        value={minutes}
                                                                                    />
                                                                                </Grid> 
                                                                            </Grid>
                                                                            <Grid container lg={12}>
                                                                                <Grid item lg>
                                                                                    <TextField 
                                                                                        variant="standard" 
                                                                                        name="redrilled"
                                                                                        onChange={handleHoleNotesChange}
                                                                                        placeholder="Enter a description"
                                                                                        value={updatedHole?.notes}
                                                                                        title={updatedHole?.notes}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>                                                                      
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid container lg className="issues" 
                                                                sx={{
                                                                    opacity: (isGroundConditionDisabled()) ? '1' : '0.5', 
                                                                    pointerEvents: (isGroundConditionDisabled()) ? 'auto' : 'none'
                                                                }}>
                                                                    <Grid item lg={12} marginBottom={'12px'}>
                                                                        <TypographySpan>Ground Condition (Cause)</TypographySpan>
                                                                    </Grid>

                                                                    <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                        <Grid container lg>
                                                                            <Checkbox size="small" checked={updatedWater?.issue} {...hole?.number + '-excessiveWater'} onChange={handleWaterChange} /> 
                                                                            <TypographySpan>Excessive ground water</TypographySpan>
                                                                        </Grid>
                                                                        <Grid container lg display={'flex'} justifyContent={'flex-end'} gap={2} sx={{opacity: updatedWater?.issue ? '0.5' : '0'}}>
                                                                            <TypographySpan>{Math.floor(updatedWater?.delay/60)}hr {updatedWater?.delay%60}min</TypographySpan>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                        <Grid container lg>
                                                                            <Checkbox size="small" checked={updatedOverburden?.issue} {...hole?.number + '-excessiveWater'} onChange={handleOverburdenChange} /> 
                                                                            <TypographySpan>Excessive overburden</TypographySpan>
                                                                        </Grid>
                                                                        <Grid container lg display={'flex'} justifyContent={'flex-end'} gap={2} sx={{opacity: updatedOverburden?.issue ? '0.5' : '0'}}>
                                                                            <TypographySpan>{Math.floor(updatedOverburden?.delay/60)}hr {updatedOverburden?.delay%60}min</TypographySpan>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                        <Grid container lg>
                                                                            <Checkbox size="small" checked={updatedCavity?.issue} {...hole?.number + '-excessiveWater'} onChange={handleCavityChange} /> 
                                                                            <TypographySpan>Intercept cavity/void</TypographySpan>
                                                                        </Grid>
                                                                        <Grid container lg display={'flex'} justifyContent={'flex-end'} gap={2} sx={{opacity: updatedCavity?.issue ? '0.5' : '0'}}>
                                                                            <TypographySpan>{Math.floor(updatedCavity?.delay/60)}hr {updatedCavity?.delay%60}min</TypographySpan>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                        <Grid container lg>
                                                                            <Checkbox size="small" checked={updatedRcGc?.issue} {...hole?.number + '-excessiveWater'} onChange={handleRcGcChange} /> 
                                                                            <TypographySpan>Intercept RC/GC hole</TypographySpan>
                                                                        </Grid>
                                                                        <Grid container lg display={'flex'} justifyContent={'flex-end'} gap={2} sx={{opacity: updatedRcGc?.issue ? '0.5' : '0'}}>
                                                                            <TypographySpan>{Math.floor(updatedRcGc?.delay/60)}hr {updatedRcGc?.delay%60}min</TypographySpan>
                                                                        </Grid>
                                                                    </Grid>
                                                                    {/* used to fill in the ground conditions so they don't have as big of a gap */}
                                                                    <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{opacity: '0'}}>
                                                                            <Grid container lg>
                                                                                <TypographySpan>empty</TypographySpan>
                                                                            </Grid>
                                                                            <Grid container lg display={'flex'} justifyContent={'flex-end'} gap={2}>
                                                                                <TypographySpan>empty</TypographySpan>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{opacity: '0'}}>
                                                                            <Grid container lg>
                                                                                <TypographySpan>empty</TypographySpan>
                                                                            </Grid>
                                                                            <Grid container lg display={'flex'} justifyContent={'flex-end'} gap={2}>
                                                                                <TypographySpan>empty</TypographySpan>
                                                                            </Grid>
                                                                        </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    )}

                                    {/* this is the default hole details view with no data */}
                                    {mode === "default" && (
                                        <Grid container lg={12} className="details" gap={1} sx={{opacity: '0.5', pointerEvents: 'none'}}>
                                         <Grid item lg={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} className="header">
                                             <Grid item>
                                                 <TypographySpan variant="caption">Edit hole ##</TypographySpan>
                                             </Grid>
                                             <Grid item display={'flex'} gap={1}>
                                                 <Button variant="contained" color="cancel" onClick={cancelHoleDetails}>Cancel</Button> 
                                                 <Button variant="contained" color="primary" onClick={compileHoleDetails}>Save Hole</Button>
                                             </Grid>
                                         </Grid>
                                         <Grid container className="report">
                                             <Card title="Drilling Report"
                                             content={
                                                 <Grid container lg={12}>
                                                     <Grid container lg={12} gap={4}>
                                                        <Grid container lg={12} display={'flex'} flexDirection={'column'} wrap="no-wrap" className="hole">
                                                            <Grid container xs={12} sm={12} md={12} lg={12} gap={8}>
                                                                <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                    <TypographySpan variant="caption">Hole ID</TypographySpan>
                                                                    <TextField 
                                                                        sx={{minWidth: '300px', maxWidth: '300px'}} 
                                                                        variant="standard" 
                                                                        value={'##'}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                    <TypographySpan variant="caption">Planned drilling depth</TypographySpan>
                                                                    <TextField 
                                                                        sx={{minWidth: '300px', maxWidth: '300px'}} 
                                                                        variant="standard" 
                                                                        InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>m</InputAdornment>}}
                                                                        value={'##'}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container xs={12} sm={12} md={12} lg={12} gap={8}>
                                                                <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                    <TypographySpan variant="caption">Drilling started at</TypographySpan>
                                                                    <TextField 
                                                                        sx={{minWidth: '300px', maxWidth: '300px'}} 
                                                                        variant="standard" 
                                                                        InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}><AccessTimeOutlinedIcon/></InputAdornment>}}
                                                                        value={'hh:mm'}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                    <TypographySpan variant="caption">Actual drilling depth</TypographySpan>
                                                                    <TextField 
                                                                        sx={{minWidth: '300px', maxWidth: '300px'}} 
                                                                        variant="standard" 
                                                                        InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>m</InputAdornment>}}
                                                                        value={'##'}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container xs={12} sm={12} md={12} lg={12} gap={8}>
                                                                <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                    <TypographySpan variant="caption">Drilling completed at</TypographySpan>
                                                                    <TextField 
                                                                        sx={{minWidth: '300px', maxWidth: '300px'}} 
                                                                        variant="standard" 
                                                                        InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}><AccessTimeOutlinedIcon/></InputAdornment>}}
                                                                        value={'hh:mm'}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                    <TypographySpan variant="caption">Drilling angle</TypographySpan>
                                                                    <TextField 
                                                                        sx={{minWidth: '300px', maxWidth: '300px'}} 
                                                                        variant="standard" 
                                                                        InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>degrees</InputAdornment>}}
                                                                        value={'##'}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container lg={12} display={'flex'} flexDirection={'row'}>
                                                            <Grid container lg className="non-prod">
                                                                <Grid item lg={12} marginBottom={'12px'}>
                                                                    <TypographySpan>Non-productive - Operational Issues</TypographySpan>
                                                                </Grid>
                                                                <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                    <Grid container lg>
                                                                        <Radio size="small" checked={false} />
                                                                        <TypographySpan>No issues</TypographySpan>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                    <Grid container lg>
                                                                        <Radio size="small" checked={false} />
                                                                        <TypographySpan>Hole was re-drilled</TypographySpan>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                    <Grid container lg>
                                                                        <Radio size="small" checked={false} />
                                                                        <TypographySpan>Hole needs to be flushed</TypographySpan>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                    <Grid container lg>
                                                                        <Radio size="small" checked={false} />
                                                                        <TypographySpan>Rod was bogged/stuck</TypographySpan>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                    <Grid container lg>
                                                                        <Radio size="small" checked={false} />
                                                                        <TypographySpan>Other issue</TypographySpan>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container lg className="issues">
                                                                <Grid item lg={12} marginBottom={'12px'}>
                                                                    <TypographySpan>Ground Condition (Cause)</TypographySpan>
                                                                </Grid>
                                                                <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                    <Grid container lg>
                                                                        <Checkbox size="small" checked={false} /> 
                                                                        <TypographySpan>Excessive ground water</TypographySpan>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                    <Grid container lg>
                                                                        <Checkbox size="small" checked={false} /> 
                                                                        <TypographySpan>Excessive overburden</TypographySpan>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                    <Grid container lg>
                                                                        <Checkbox size="small" checked={false} /> 
                                                                        <TypographySpan>Intercept cavity/void</TypographySpan>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item lg={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                                                    <Grid container lg>
                                                                        <Checkbox size="small" checked={false} /> 
                                                                        <TypographySpan>Intercept RC/GC hole</TypographySpan>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                 </Grid>
                                             }>
                                             </Card>
                                         </Grid>
                                     </Grid>
                                    )}
                                </Grid>
                            </TabPanel>


                            <TabPanel value="extra-holes" className="extra-holes">
                                <Grid container gap={4} lg={12}>
                                    <Grid container lg={12} className="pattern">
                                        <FormControl>
                                            <InputLabel id="extra-holes-pattern-select">Task (Pattern/Pit)</InputLabel>
                                            <Select
                                                value={taskSelect}
                                                label="Task (Pattern/Pit)"
                                                onChange={handleTaskChange}
                                                displayEmpty
                                                style={{minWidth: '500px', maxWidth: '500px'}}
                                                autoWidth
                                                MenuProps={menuProps}
                                                variant="standard">

                                                {
                                                    taskOptions?.map((x) => {
                                                        return (
                                                            <MenuItem value={x.id}>
                                                                <TypographySpan variant="caption" sx={{fontSize: '24px'}}>{x.label}</TypographySpan>
                                                            </MenuItem>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid container xs={12} sm={12} md={12} lg={12} display={'flex'} flexDirection={'column'} wrap="nowrap">
                                        <Grid container lg={12} className="details" gap={1} sx={{opacity: !taskSelect ? '0.5' : '1', pointerEvents: !taskSelect ? 'none' : 'auto'}}>
                                            <Grid item lg={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} className="header">
                                                <Grid item>
                                                    <TypographySpan variant="caption">Add Extra Holes</TypographySpan>
                                                </Grid>
                                                <Grid item display={'flex'} gap={1}>
                                                    <Button variant="contained" color="cancel" onClick={() => {setUpdatedNewExtraHole(newExtraHole)}}>Cancel</Button> 
                                                    <Button variant="contained" color="primary" onClick={saveExtraHole} disabled={isExtraDisabled()}>Save</Button>
                                                </Grid>
                                            </Grid>
                                            <Grid container className="report">
                                                <Card title=""
                                                content={
                                                    <Grid container lg={12}>
                                                        <Grid container lg={12} gap={4}>
                                                            <Grid container lg={12} display={'flex'} flexDirection={'column'} wrap="no-wrap" className="hole" gap={2}>
                                                                <Grid container xs={12} sm={12} md={12} lg={12} gap={8}>
                                                                    <Grid item xs sm md lg={3} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                        <TypographySpan variant="caption">Additional holes drilled</TypographySpan>
                                                                    </Grid>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                        <TextField 
                                                                            sx={{minWidth: '300px', maxWidth: '300px'}} 
                                                                            variant="standard"
                                                                            name="holes"
                                                                            onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}}
                                                                            InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>holes</InputAdornment>}}
                                                                            value={taskSelect ? updatedNewExtraHole?.holes : '##'}
                                                                            onChange={handleExtraHoleChange}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container xs={12} sm={12} md={12} lg={12} gap={8}>
                                                                    <Grid item xs sm md lg={3} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                        <TypographySpan variant="caption">Additional metres drilled</TypographySpan>
                                                                    </Grid>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                        <TextField 
                                                                            sx={{minWidth: '300px', maxWidth: '300px'}} 
                                                                            variant="standard" 
                                                                            name="depth"
                                                                            onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}}
                                                                            InputProps={{endAdornment: <InputAdornment position="end" style={{opacity: '0.5'}}>m</InputAdornment>}}
                                                                            value={taskSelect ? updatedNewExtraHole?.depth : '##'}
                                                                            onChange={handleExtraHoleChange}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container xs={12} sm={12} md={12} lg={12} gap={8}>
                                                                    <Grid item xs sm md lg={3} display={'flex'} alignItems={'flex-start'}>
                                                                        <TypographySpan variant="caption">Notes</TypographySpan>
                                                                    </Grid>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                                        <TextareaAutosize minRows={5} name="notes" className="text-area" placeholder="Enter a note or comment..." value={updatedNewExtraHole?.notes} onChange={handleExtraHoleChange}/>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={12} sm={12} md={12} lg={12} display={'flex'} flexDirection={'column'} wrap="nowrap">
                                    <Grid container xs={12} sm={12} md={12} lg={12} sx={{opacity: taskSelect ? '1' : '0.5', pointerEvents: taskSelect ? 'auto' : 'none'}} gap={2} className="holes" alignContent={'start'}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} className="header">
                                                <TypographySpan variant="caption">Extra Holes for the Task</TypographySpan>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} display={'flex'} flexDirection={'column'} className="table" sx={{opacity: mode==="pattern" || mode==="pattern-default" ? '0.5' : '1', pointerEvents: mode==="pattern" || mode==="pattern-default" ? 'none' : 'auto'}}>
                                                <Grid item className="header" xs={12} sm={12} md={12} lg={12} display={'flex'} flexDirection={'row'}>
                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                        <TypographySpan variant="caption">Holes</TypographySpan>
                                                    </Grid>
                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                        <TypographySpan variant="caption">Depth</TypographySpan>
                                                    </Grid>
                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                        <TypographySpan variant="caption">User</TypographySpan>
                                                    </Grid>
                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                        <TypographySpan variant="caption">Event Time</TypographySpan>
                                                    </Grid>
                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                        <TypographySpan variant="caption">Actions</TypographySpan>
                                                    </Grid>
                                                </Grid>
                                                {showExtraHoles ? (
                                                    <>
                                                        {extraHoles?.map((h) => {
                                                            return (
                                                                <Grid item className="data" xs={12} sm={12} md={12} lg={12} display={'flex'} flexDirection={'row'}>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                                        <TypographySpan variant="caption">
                                                                            {h?.holes}
                                                                        </TypographySpan>
                                                                    </Grid>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                                        <TypographySpan variant="caption">
                                                                            {h?.depth}
                                                                        </TypographySpan>
                                                                    </Grid>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                                        <TypographySpan variant="caption">
                                                                            {h?.completedBy}
                                                                        </TypographySpan>
                                                                    </Grid>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                                        <TypographySpan variant="caption">
                                                                            {formatTime(h?.eventTime)}
                                                                        </TypographySpan>
                                                                    </Grid>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                                        <IconButton onClick={() => {deleteExtraHole(h)}}>
                                                                            <TrashIcon/>
                                                                        </IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            )
                                                        })}
                                                    </>
                                                      
                                                ) : (
                                                    <>
                                                        {[1].map((i) => {
                                                            return (
                                                                <Grid item className="data" xs={12} sm={12} md={12} lg={12} display={'flex'} flexDirection={'row'}>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                                        <TypographySpan sx={{opacity: '0'}}>a</TypographySpan>
                                                                    </Grid>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}></Grid>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}></Grid>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}></Grid>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}></Grid>
                                                                    <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}></Grid>
                                                                    <Grid item xs sm md lg={1} display={'flex'} justifyContent={'center'} alignItems={'center'}></Grid>
                                                                </Grid>
                                                            )
                                                        })}
                                                    </>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </TabContext>
                    </Container>
                </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-actions">
            <Button variant="contained" color="cancel" onClick={closeDrillingModal}>Close</Button>
            </DialogActions>
        </Dialog>
    </>
  );
}

export default DrillingActivity;
