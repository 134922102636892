import { createSlice } from "@reduxjs/toolkit";
import { fetchAuditLogs } from "./auditAction";

const initialState = {
    logs: [],
};

export const auditSlice = createSlice({
    name: "auditReducer",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder

        .addCase(fetchAuditLogs.pending, (state, action) => {
          state.isLoading = true;
        })
        .addCase(fetchAuditLogs.fulfilled, (state, action) => {
          state.logs = action.payload;
          state.isLoading = false;
        })
        .addCase(fetchAuditLogs.rejected, (state, action) => {
          state.isLoading = false;
        });
    },
  });