import { combineReducers } from "redux";

// //import other reducers
import { commonSlice } from "redux/commonRedux/commonSlice";
import { userSlice } from "./userRedux/userSlice";
import { profileSlice } from "./profileRedux/profileSlice";
import { fieldSlice } from "./fieldRedux/fieldSlice";
import { boardSlice } from "./boardRedux/boardSlice";
import { machinesSlice } from "./machinesRedux/machinesSlice";
import { shiftSlice } from "./shiftRedux/shiftSlice";
import { auditSlice } from "./auditRedux/auditSlice";

const rootReducer = (history) =>
  combineReducers({
    common: commonSlice.reducer,
    user: userSlice.reducer,
    profile: profileSlice.reducer,
    field: fieldSlice.reducer,
    board: boardSlice.reducer,
    machines: machinesSlice.reducer,
    shift: shiftSlice.reducer,
    audit: auditSlice.reducer,
  });

export default rootReducer;
