import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "helper/config/axiosConfig";
import { addToast, modalClose, modalError } from "redux/commonRedux/commonActions";
//api functions

// FETCH VEHICLES ACTION
export const fetchVehicles = createAsyncThunk(
  "machines/fetchVehicles",
  async (model, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().get("/vehicles");
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

// FETCH VEHICLE ACTION
export const fetchVehicle = createAsyncThunk(
  "machines/fetchVehicle/vehicle/byid/",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().get("/vehicle/byid/" + id);
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const fetchVehicleWithoutLoader = createAsyncThunk(
  "machines/fetchVehicleWithoutLoader/vehicle/byid/",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().get("/vehicle/byid/" + id);
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

// ADD VEHICLE ACTION
export const addVehicle = createAsyncThunk(
  "machines/addVehicle",
  async (vehicle, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().post("/vehicle", vehicle);
      dispatch(
        addToast({
          type: "success",
          message: "screens.machines_new.toast_message_vehicle_added_success",
        })
      );
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

// FETCH VEHICLE OPTIONS
export const fetchVehicleOptions = createAsyncThunk(
  "machines/fetchVehicleOptions",
  async (model, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().get("/vehicle/options");
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

// MODIFY VEHICLE STATE
export const modifyVehicleState = createAsyncThunk(
  "machines/modifyVehicleState",
  async (model, { rejectWithValue, dispatch }) => {
    try {
      debugger;
      const { id, state } = model;
      const response = await axiosConfig().put("/vehicle/state", { id, state });
      // dispatch(modifyVehicleSuccess(response.data));
      dispatch(
        addToast({
          type: "success",
          message: "screens.machines_info.toast_message_state_added_success",
        })
      );
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

// MODIFY VEHICLE
export const modifyVehicle = createAsyncThunk(
  "machines/modifyVehicle",
  async (vehicle, { rejectWithValue, dispatch }) => {
    try {
      //modify the object and make a new one to keep only some props
      const updatedVehicle = {
        id: vehicle.id,
        secret_key: vehicle.secret_key,
        deviceName: vehicle.deviceName,
        type: vehicle.type,
        make: vehicle.make,
        model: vehicle.model,
        name: vehicle.name,
        serial: vehicle.serial,
        serviceFirst: vehicle.serviceFirst,
        serviceInterval: vehicle.serviceInterval,
        fuelType: vehicle.fuelType,
        fuelCapacity: vehicle.fuelCapacity,
        manufacturerURL: vehicle.manufacturerURL,
        manualURL: vehicle.manualURL,
        state: vehicle.state,
        prestartcheckList: vehicle.prestartcheckList,
        runningcheckList: vehicle.runningcheckList,
      };
      const response = await axiosConfig().put("/vehicle", updatedVehicle);
      // dispatch(modifyVehicleSuccess(vehicle));
      dispatch(
        addToast({
          type: "success",
          message: "screens.machines_info.toast_message_state_added_success",
        })
      );
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

//generate secret without having to edit whole vehicle
export const generateSecret = createAsyncThunk(
  "machines/modifyVehicle",
  async (vehicle, { rejectWithValue, dispatch }) => {
    try {
      //modify the object and make a new one to keep only some props
      const updatedVehicle = {
        id: vehicle.id,
        secret_key: vehicle.secret_key,
        deviceName: vehicle.deviceName,
        type: vehicle.type,
        make: vehicle.make,
        model: vehicle.model,
        name: vehicle.name,
        serial: vehicle.serial,
        serviceFirst: vehicle.serviceFirst,
        serviceInterval: vehicle.serviceInterval,
        fuelType: vehicle.fuelType,
        fuelCapacity: vehicle.fuelCapacity,
        manufacturerURL: vehicle.manufacturerURL,
        manualURL: vehicle.manualURL,
        state: vehicle.state,
        prestartcheckList: vehicle.prestartcheckList,
        runningcheckList: vehicle.runningcheckList,
      };
      const response = await axiosConfig().put("/vehicle", updatedVehicle);
      dispatch(
        addToast({
          type: "success",
          message: "screens.machines_info.toast_message_generate_secret_success",
        })
      );
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

// // UPDATE STATE VEHICLE ACTION
export const updateStateVehicle = createAction(
  "machines/updateStateVehicle",
  (model) => {
    return { payload: model };
  }
);

// DELETE VEHICLE
export const removeVehicle = createAsyncThunk(
  "machines/removeVehicle/id",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().delete("/vehicle/" + id);
      dispatch(deleteVehicleSuccess());
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const deleteVehicleSuccess = createAction(
  "machines/deleteVehicleSuccess",
  (model) => {
    return { payload: model };
  }
);

export const openModalMaintenance = createAction(
  "MODAL_MAINTENANCE", // don't change type
  (model) => {
    return { payload: model };
  }
);
export const addVehicleEvent = createAsyncThunk(
  "machines/addVehicleEvent",
  async (event, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().post("/vehicleevent", event);
      dispatch(addVehicleEventSuccess());
      dispatch(
        addToast({
          type: "success",
          message:
            "screens.machines_info.toast_message_maintenance_added_success",
        })
      );
      dispatch(fetchVehicle(event.vehicleID));
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);
export const updateVehicleEvent = createAsyncThunk(
  "machines/updateVehicleEvent",
  async (event, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().put("/vehicleevent/update", event);
      dispatch(updateVehicleEventSuccess());
      dispatch(
        addToast({
          type: "success",
          message:
            "Vehicle event updated successfully !",
        })
      );
      dispatch(fetchVehicle(event.vehicleID));
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);
export const deleteVehicleEvent = createAsyncThunk(
  "machines/deleteVehicleEvent",
  async ({eventId, event}, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().delete("/vehicleevent/delete/" + eventId);
      dispatch(deleteVehicleEventSuccess());
      dispatch(
        addToast({
          type: "success",
          message:
            "Vehicle event deleted successfully !",
        })
      );
      dispatch(fetchVehicle(event.vehicleID));
      dispatch(modalClose());
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const addVehicleEventSuccess = createAction(
  "machines/addVehicleEventSuccess",
  (model) => {
    return { payload: model };
  }
);
export const updateVehicleEventSuccess = createAction(
  "machines/updateVehicleEventSuccess",
  (model) => {
    return { payload: model };
  }
);
export const deleteVehicleEventSuccess = createAction(
  "machines/deleteVehicleEventSuccess",
  (model) => {
    return { payload: model };
  }
);

export const fetchVehicleArchives = createAsyncThunk(
  "machines/fetchVehicleArchives",
  async (serial, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().get("/vehiclearchives/" + serial);
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const openModalArchives = createAction("MODAL_ARCHIVES", (model) => {
  return { payload: model };
});
