import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

import { Container, 
        Grid, 
        TextField, 
        // Typography, 
        Chip, 
        Select, 
        MenuItem, 
        Tab } 
from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
        TabContext,
        TabList,
        TabPanel
        }
from '@mui/lab';
import Skeleton from '@mui/material/Skeleton';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import "./reportspage.scss";
import styles from "../../styles/_sys.scss";
import { fetchAllShiftsForVehicleBySerial, fetchPrestartData, fetchAvailableDrillTasks, fetchShiftReportDataWithoutLoader, fetchFullShiftDataWithoutLoader, updatePrestartApproval, updateApproval, fetchShiftReportData } from "redux/shiftRedux/shiftActions";
import { fetchVehicles, fetchVehicleWithoutLoader } from "redux/machinesRedux/machinesActions";
import GeneralInformation from "./generalinformation";
import MachineHours from "./machinehours";
import RockTools from "./rocktoolsused";
import DrillingActivity from "./drillingactivity";
import Notes from "./notes";
import ReportedDelays from "./reporteddelays";
import Approval from "./approval";
import Fitness from "./fitness";
import Hazard from "./hazard";
import Fluid from "./fluid";
import CollapseExpand from "components/collapse";
import { addToast } from "redux/commonRedux/commonActions";
import { TypographySpan } from "components/styledComponents";

function ReportsPage(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const endTime = "0001-01-01T00:00:00Z";

    const reducerState = useSelector((state) => state);
    // console.log(reducerState);
    const vehicles = reducerState.machines.vehicles;
    const [collapsed, setCollapsed] = useState(false);

    const [shift, setShift] = useState('');
    const [shiftData, setShiftData] = useState();
    const [vehicle, setVehicle] = useState('');
    const [prestartdata, setPrestartdata] = useState([]);

    const [vehicleShifts, setVehicleShifts] = useState([]);
    const [vehicleShiftDates, setVehicleShiftDates] = useState();
    const [vehicleShiftSlots, setVehicleShiftSlots] = useState();

    const [showTabs, setShowTabs] = useState(false);
    const [showTabContent, setShowTabContent] = useState(false);
    const [showSkeletons, setShowSkeletons] = useState(!showTabs);
    const [showApproval, setShowApproval] = useState(false);
    const [tab, setTab] = useState('shift');

    const menuProps = {
        PaperProps: {
            style: {
                marginTop: '8px',
                minWidth: '300px',
                backgroundColor: styles.backgroundLight,
                borderRadius: '6px'
            },
        },
        MenuListProps: {
            style: {
                padding: '0px',
            },
        },
    };

    function getMaxDate() {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        return `${month}/${date}/${year}`;
    }
    
    const [shiftDate, setShiftDate] = useState(null);
    const [shiftSlot, setShiftSlot] = useState('');

    const handleMachineChange = (event) => {
        dispatch(fetchVehicleWithoutLoader(event?.target?.value)).then((vehicle) => {
            setVehicle(vehicle?.payload);
        });
        clearDetailsForMachine();
    }

    useEffect(() => {
        if (vehicle) {
            loadAllVehicleShifts(vehicle?.serial);
        }
    }, [vehicle])

    const handleShiftDateChange = (event) => {
        let date = `${event?.$M+1}/${event?.$D}/${event?.$y}`;
        setShiftDate(date);
        clearDetailsForDate();
    }

    useEffect(() => {
        if (shiftDate && vehicleShifts?.length > 0) {
            loadShiftSlots(shiftDate);
        }
    }, [shiftDate, vehicleShifts])

    const handleShiftSlotChange = (event) => {
        const {value} = event?.target;
        setShiftSlot(value);

        navigate('/reports/' + value, {state: {shiftId: value, machineId: vehicle.id, machineSerial: vehicle.serial}});
    }

    useEffect(() => {
        if (shiftSlot) {
            loadVehicleShift(shiftSlot);
        }
    }, [shiftSlot])

    const handleTabChange = (event, tab) => {
        setCollapsed(false);
        setShowTabs(false);

        if (tab === 'pre-start') {
            dispatch(fetchPrestartData(reducerState?.shift?.shiftData?.preStartChecklist?.ID)).then((data) => {
                setPrestartdata(data?.payload);
            })
        }
        if (tab === 'shift') {
            dispatch(fetchFullShiftDataWithoutLoader(reducerState?.shift?.shiftData?.shiftReport?._id)).then((data) => {
                setShiftData(data?.payload);
            })
        }

        setTab(tab);
    }

    const clearDetailsForMachine = () => {
        setShiftDate(null);
        setShiftSlot('');
        setVehicleShiftDates([]);
        setVehicleShiftSlots([]);
        setShowTabs(false);
        setTab('pre-start');
    }

    const clearDetailsForDate = () => {
        setShiftSlot('');
        setVehicleShiftSlots([]);
        setShowTabs(false);
        setTab('pre-start');
    }

    const convertDateV2 = (str) => {
        // returns d/m/yyyy
        const date = new Date(str);

        const day = String(date.getDate());
        const month = String(date.getMonth() + 1);
        const year = date.getFullYear();
        
        return `${day}/${month}/${year}`;
    }

    const convertDateV3 = (str) => {
        // returns m/dd/yyyy
        // e.g. 9/14/2024
        const date = new Date(str);

        const day = String(date.getDate()).padStart(2, '0');
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return `${month}/${day}/${year}`;
    }

    const convertDateToTime = (date) => {
        let timestamp = Date.parse(date);
        return new Date(timestamp).toTimeString().substring(0, 5);
    }

    const loadAllVehicleShifts = (serial) => {
        if (serial != null) {
            dispatch(fetchAllShiftsForVehicleBySerial(serial)).then((shifts) => {
                setVehicleShifts(shifts?.payload);
            })
        }
        else {
            dispatch(fetchAllShiftsForVehicleBySerial(vehicle?.serial)).then((shifts) => {
                setVehicleShifts(shifts?.payload);
            })
        }
    }

    useEffect(() => {
        if (vehicleShifts?.length > 0) {
            loadVehicleShiftDates(vehicleShifts);
        }
    }, [vehicleShifts])

    const loadVehicleShiftDates = (shifts) => {
        let datesArr = [];
        let originShifts = shifts?.filter((s) => {return s?.state !== 5});
        originShifts?.forEach(shift => {
            datesArr?.push(convertDateV2(shift?.createdAt?.toString()));
        })
        setVehicleShiftDates(datesArr);
    }

    useEffect(() => {
        if (vehicleShiftDates?.length > 0) {
            setShiftSlot(props?.id);
        }
    }, [vehicleShiftDates])

    const loadShiftSlots = (date) => {
        if (date) {
            let slots = [];
            vehicleShifts?.filter((shift) => { return convertDateV2(shift?.createdAt?.toString()) === convertDateV2(date?.toString());}).forEach(slot => {
                if (slot?.endTime === endTime) {
                    let name = `${convertDateToTime(slot?.createdAt)} — LIVE`;
                    slots.push({id: slot?._id, name: name});
                }
                else {
                    let name = `${convertDateToTime(slot?.createdAt)} — ${convertDateToTime(slot?.endTime)}`;
                    slots.push({id: slot?._id, name: name});
                }
            });
            setVehicleShiftSlots(slots);
        }
    }

    const loadVehicleShift = (shiftId) => {
        setShift(vehicleShifts?.filter((shift) => {return shift?._id === shiftId})[0]);
    }

    useEffect(() => {
        if (shift) {
            dispatch(fetchFullShiftDataWithoutLoader(shift._id)).then((data) => {
                if (data?.payload?.preStartChecklist?.ID === "000000000000000000000000") {
                    dispatch(
                        addToast({
                            type: "error",
                            message: "Pre start check has not been submitted yet!",
                        })
                    );
                }
                else {
                    setShiftData(data?.payload);
                    setPrestartdata(data?.payload?.preStartChecklist);
                    setShowTabs(false);
                }
            })
        }
    }, [shift])

    const loadData = (val, mode) => {
        setShowApproval(false);
        if (mode === 'pre-start') {
            dispatch(fetchPrestartData(reducerState?.shift?.shiftData?.preStartChecklist?.ID)).then((data) => {
                setPrestartdata((prev) => ({
                    ...prev,
                    approved: val,
                }));
            })
        }
        if (mode === 'shift') {
            dispatch(fetchFullShiftDataWithoutLoader(reducerState?.shift?.shiftData?.shiftReport?._id)).then((data) => {
                setShiftData((prev) => ({
                    ...prev,
                    shiftReport: {
                        ...prev.shiftReport,
                        approved: val,
                    },
                }));
            })
        }
    }

    useEffect(() => {
        dispatch(fetchVehicles()).then((done) => {
            dispatch(fetchAvailableDrillTasks()).then(() => {
                if (props?.mode === "live") {
                    if (props?.machineId) {
                        dispatch(fetchVehicleWithoutLoader(props?.machineId)).then((vehicle) => {
                            setVehicle(vehicle?.payload);
                            if (props?.id) {
                                dispatch(fetchShiftReportData(props?.id)).then(shift => {
                                    setShiftDate(convertDateV3(shift?.payload?.createdAt.toString()));
                                });
                            }
                        });
                    }
                } 
            });
        });
    }, [])

    useEffect(() => {
        if (vehicle && shiftDate && shiftSlot && shift && shiftData && prestartdata) {
            setShowTabs(true);
            setShowTabContent(true);
            setShowApproval(true);
            setShowSkeletons(false);
        }
    }, [vehicle, shiftDate, shiftSlot, shift, shiftData, prestartdata])

    function toTitleCase(str) {
        const words = str.split('_');
        
        const capitalizedWords = words?.map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        });
        
        const titleCaseStr = capitalizedWords.join(' ');
        
        return titleCaseStr;
    }

    const isShiftEditable = () => {
        return shiftData?.shiftReport?.endTime !== endTime && !shiftData?.shiftReport.approved;
    }
    const isPrestartEditable = () => {
        return !prestartdata?.approved;
    }

    //General Information
    const getDrillStartTime = () => {
        const shiftID = shiftData?.shiftReport?._id;
        let holesTimes = [];
        if (shiftData?.shiftReport?.startTime === shiftData?.shiftReport?.createdAt) { //means the shiftreport hasn't been modified
            shiftData?.drillTasks?.forEach(task => {
                task?.holes?.forEach(hole => {
                    if (hole?.shiftID === shiftID && hole?.endTime !== endTime) {
                        holesTimes.push(hole?.endTime);
                    }
                })
            })
            if (holesTimes?.length > 0) {
                holesTimes?.sort((a, b) => new Date(a) - new Date(b));
                return holesTimes[0]; //so use the first hole completed time
            }
        }
        return shiftData?.shiftReport?.startTime; //else use the time the user has modified to
    }
    const getDrillPatterns = () => {
        let patterns = [];
        shiftData?.drillTasks?.forEach(task => {
            if (!patterns.includes(task.pattern)) {
                patterns.push(task.pattern);
            }
        })
        return patterns;
    }

    //Rock Tools
    const getRockTools = () => {
        return shiftData?.shiftReport?.rockTools;
    }

    //Machine Hours
    const getEngineHours = (type) => {
        if (type === 'shift') {
            let engine = '';
            shiftData?.shiftReport?.info?.forEach(v => {
                if (!engine) {
                    if (v.key === 'engineHoursEnd') {
                        engine = v.value;
                    }
                }
            })
            return engine;
        }
        if (type === 'pre-start') {
            return prestartdata?.engineHours;
        }
    }
    const getPercussiveHours = (type) => {
        if (type === 'shift') {
            let engine = '';
            shiftData?.shiftReport?.info?.forEach(v => {
                if (!engine) {
                    if (v.key === 'percussiveHoursEnd') {
                        engine = v.value;
                    }
                }
            })
            return engine;
        }
        if (type === 'pre-start') {
            return prestartdata?.percussiveHours;
        }
    }

    //Reported Delays
    const getOperationalDelays = () => {
        let operationalArr = [];
        shiftData?.shiftReport?.operationDelays?.forEach(delay => {
            if (delay.type === 'operational') {
                operationalArr.push({name: delay.name, startTime: delay.startTime, duration: delay.duration, createdAt: delay.createdAt, type: delay.type});
            }
        })
        return operationalArr;

    }
    const getMechanicalDelays = () => {
        let mechanicalArr = [];

        let reported = shiftData?.vehicleEvents?.filter((e) => { return e?.eventType === "BREAKDOWN_REPORTED"});
        let repaired = shiftData?.vehicleEvents?.filter((e) => { return e?.eventType === "BREAKDOWN_REPAIRED"});

        reported?.forEach((reportedEvent) => {
            if (reportedEvent?.repair === "000000000000000000000000") {
                reportedEvent?.errors?.forEach(error => {
                    mechanicalArr.push({id: reportedEvent?.id, name: toTitleCase(error.name), startTime: reportedEvent?.startedAt, endTime: reportedEvent?.endedAt, createdAt: reportedEvent.createdAt})
                })
            }
            else {
                repaired.forEach((repairedEvent) => {
                    if (reportedEvent?.repair === repairedEvent?.id) {
                        repairedEvent?.errors?.forEach(error => {
                            mechanicalArr.push({id: repairedEvent?.id, name: toTitleCase(error.name), startTime: repairedEvent?.startedAt, endTime: repairedEvent?.endedAt, createdAt: repairedEvent.createdAt})
                        })
                    }
                })
            }
        })

        return mechanicalArr;
    }
    const getNonProductiveDelays = () => {
        let nonProdArr = [];
        shiftData?.shiftReport?.operationDelays?.forEach(delay => {
            if (delay.type === 'nonprod') {
                nonProdArr.push({name: delay.name, startTime: delay.startTime, duration: delay.duration, createdAt: delay.createdAt, type: delay.type});
            }
        })
        return nonProdArr;
    }

    //Drilling Activity
    const getDrillTasks = () => {
        return shiftData?.drillTasks;
    }
    const getShiftId = () => {
        return shiftData?.shiftReport?._id;
    }

    //Notes/Comments
    const getOperatorNotes = () => {
        return shiftData?.shiftReport?.notes;
    }
    const getSupervisorNotes = () => {
        if (shiftData?.shiftReport?.supervisorNotes) {
            return shiftData?.shiftReport?.supervisorNotes;
        }
        else {
            return '';
        }
    }

    //Approval
    const getApproved = (mode) => {
        if (mode === 'shift') {
            return shiftData?.shiftReport?.approved;
        }
        if (mode === 'pre-start') {
            return prestartdata?.approved;
        }
    }
    const getApprovalHistory = (mode) => {
        if (mode === 'shift') {
            return shiftData?.shiftReport?.approvalHistory;
        }
        if (mode === 'pre-start') {
            if (prestartdata?.approvalHistory === null) {
                dispatch(updateApproval({shiftId: prestartdata?.shiftReportID, prestartId: prestartdata?.ID, user: shiftData?.shiftReport?.users[0], reportType: mode}));
                setPrestartdata((prev) => ({
                    ...prev,
                    approvalHistory: [{
                        description: "Initial Report Submission",
                        user: shiftData?.shiftReport?.users[0],
                        createdAt: shiftData?.shiftReport?.createdAt,
                    }],
                }));
            }
            return prestartdata?.approvalHistory;
        }
    }

    //Pre-start functions
    //Fitness Statement
    const getFitnessStatement = () => {
        return prestartdata?.statementOfFitness;
    }

    //Hazards
    const getHazardAIssues = () => {
        return prestartdata?.checklist?.filter((issue) => {return issue.hazardType === "hazardA"});
    }
    const getHazardBIssues = () => {
        return prestartdata?.checklist?.filter((issue) => {return issue.hazardType === "hazardB"});
    }
    const getRunningIssues = () => {
        return prestartdata?.checklist?.filter((issue) => {return issue.hazardType === "running"});
    }

    //Fluid levels
    const getFuelLevel = () => {
        return prestartdata?.fuelLevel;
    }
    const getOilLevel = () => {
        return prestartdata?.rockOilLevel;
    }
    const getWaterLevel = () => {
        return prestartdata?.waterLevel;
    }

    //Notes
    const getPrestartNotes = () => {
        return prestartdata?.notes;
    }

    const handleCollapsed = (event) => {
        setShowTabContent(false);
        setCollapsed(event);
    }

    useEffect(() => {
        setShowTabContent(true);
    }, [collapsed])

    return (
        <div>
            <Container fixed className="topbar">
                <Grid container>
                    <Grid item xs={12} sm={3} md={3} lg={3} className="chip">
                        <Chip style={{display: vehicle ? '' : 'none'}} label={vehicle ? vehicle.name : '...'} />
                    </Grid>
                    <Grid className="details" container xs sm md lg>

                        <Grid container gap={2} xs={12} sm={12} md={12} lg className="machine">
                            <Grid item xs sm md lg className="label">
                                <TypographySpan variant="body2">{t("screens.reports.text_machine")}</TypographySpan>
                            </Grid>
                            <Grid item xs sm md lg className="action">
                                <Select
                                    title={vehicle.name}
                                    value={vehicle ? vehicle.id : ''}
                                    onChange={handleMachineChange}
                                    displayEmpty
                                    style={{height: '33px', minWidth: '200px', maxWidth: '200px'}}
                                    MenuProps={menuProps}
                                    variant="standard">

                                    <MenuItem value="" disabled sx={{display: 'none'}}>
                                        <TypographySpan variant="caption">{t("screens.reports.select_empty")}</TypographySpan>
                                    </MenuItem>
                                    {
                                        vehicles?.map((x) => {
                                            return (
                                                <MenuItem value={x.id}>
                                                    <TypographySpan variant="caption" sx={{fontSize: '24px'}}>{x.name}</TypographySpan>
                                                </MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </Grid>
                        </Grid>

                        <Grid container gap={2} xs={12} sm={12} md={12} lg className="shift-date">
                            <Grid item xs sm md lg className="label">
                                <TypographySpan variant="body2">{t("screens.reports.text_shift_date")}</TypographySpan>
                            </Grid>
                            <Grid item xs sm md lg className="action">
                                <DatePicker
                                    disabled={vehicleShiftDates ? vehicleShiftDates?.length === 0 : true}
                                    inputFormat="DD/MM/YYYY"
                                    value={shiftDate}
                                    onChange={handleShiftDateChange}
                                    // maxDate={getMaxDate()}
                                    shouldDisableDate={(date) => {
                                        let fdate = `${date?.$D}/${date?.$M+1}/${date?.$y}`;
                                        if (vehicleShiftDates?.includes(fdate)) {
                                            return false;
                                        }
                                        else {
                                            return true
                                        }
                                    }}
                                    views={['day']}
                                    renderInput={(params) => (
                                        <TextField variant="standard" {...params} />
                                    )}
                                />
                            </Grid>
                        </Grid>

                        <Grid container gap={2} xs={12} sm={12} md={12} lg className="shift">
                            <Grid item xs sm md lg className="label">
                                <TypographySpan variant="body2">{t("screens.reports.text_shift")}</TypographySpan>
                            </Grid>
                            <Grid item xs sm md lg className="action">
                                <Select
                                    title={shiftSlot}
                                    value={shiftSlot ?? ''}
                                    disabled={vehicleShiftSlots ? vehicleShiftSlots?.length === 0 : true}
                                    onChange={handleShiftSlotChange}
                                    displayEmpty
                                    MenuProps={menuProps}
                                    style={{height: '33px', minWidth: '200px', maxWidth: '200px'}}
                                    variant="standard">
                                    
                                    <MenuItem value="" disabled sx={{display: 'none'}}>
                                        <TypographySpan variant="caption">{t("screens.reports.select_empty")}</TypographySpan>
                                    </MenuItem>
                                    {
                                        vehicleShiftSlots?.map((x) => {
                                            return (
                                                <MenuItem value={x.id}>
                                                    <TypographySpan variant="caption" sx={{fontSize: '24px'}}>{x.name}</TypographySpan>
                                                </MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>

            {showSkeletons && (
                <Grid container xs={12} sm={12} md={12} lg={12} rowGap={4} columnGap={8} height={'100%'} padding={'24px'}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Skeleton width={'100%'} height={'100%'}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md lg>
                        <Skeleton width={'100%'} height={'100%'}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Skeleton width={'100%'} height={'100%'}/>
                    </Grid>
                    <Grid container xs={12} sm={12} md lg gap={4} height={'100%'}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Skeleton width={'100%'} height={'100%'}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Skeleton width={'100%'} height={'100%'}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Skeleton width={'100%'} height={'100%'}/>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} sm={12} md={12} lg={12} gap={8} height={'100%'}>
                        <Grid item xs={12} sm={12} md lg>
                            <Skeleton width={'100%'} height={'100%'}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md lg>
                            <Skeleton width={'100%'} height={'100%'}/>
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {showTabs && (
                <Container className="tabs">
                    <TabContext value={tab}>
                            <TabList onChange={handleTabChange}>
                                <Tab label={
                                    <TypographySpan sx={{fontSize: '18px'}} variant="body2">{t("screens.reports.tab_pre_start_report")}</TypographySpan>
                                } 
                                value="pre-start" />
                                <Tab label={
                                    <TypographySpan sx={{fontSize: '18px'}} variant="body2">{t("screens.reports.tab_shift_report")}</TypographySpan>
                                }  value="shift" />
                                {showApproval && (
                                    <Grid container lg className="report-status">
                                        <Grid item sx={{backgroundColor: tab === 'pre-start' ? (prestartdata?.approvalHistory?.length > 0 ? (prestartdata?.approved ? styles.approvalGreen : styles.approvalOrange) : styles.approvalGrey) : (shiftData?.shiftReport?.approvalHistory?.length > 0 ? (shiftData?.shiftReport?.approved ? styles.approvalGreen : styles.approvalOrange) : styles.approvalGrey)}}>
                                            <TypographySpan>Report Status: </TypographySpan>
                                            {tab === 'pre-start' ? (
                                                <Fragment>
                                                    {prestartdata?.approvalHistory?.length > 0 ? (
                                                        <Fragment>
                                                            {prestartdata?.approved ? (
                                                                <Grid item display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1} padding={'0px'} className="status">
                                                                    <TypographySpan fontWeight={600}>Approved</TypographySpan>
                                                                    <CheckBoxIcon sx={{width: '25px', height: '25px', color: 'green'}}/>
                                                                </Grid>
                                                            ) : (
                                                                <Grid item display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1} padding={'0px'} className="status">
                                                                    <TypographySpan fontWeight={600}>Awaiting Approval</TypographySpan>
                                                                    <InsertDriveFileOutlinedIcon sx={{width: '25px', height: '25px'}}/>
                                                                </Grid>
                                                            )}
                                                        </Fragment>
                                                    ) : (
                                                        <Grid item display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1} padding={'0px'} className="status">
                                                            <TypographySpan fontWeight={600}>Not Yet Submitted</TypographySpan>
                                                        </Grid>
                                                    )}
                                                </Fragment>
                                            ) : (
                                                <Fragment>
                                                    {shiftData?.shiftReport?.approvalHistory?.length > 0 ? (
                                                        <Fragment>
                                                            {shiftData?.shiftReport?.approved ? (
                                                                <Grid item display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1} padding={'0px'} className="status">
                                                                    <TypographySpan fontWeight={600}>Approved</TypographySpan>
                                                                    <CheckBoxIcon sx={{width: '25px', height: '25px', color: 'green'}}/>
                                                                </Grid>
                                                            ) : (
                                                                <Grid item display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1} padding={'0px'} className="status">
                                                                    <TypographySpan fontWeight={600}>Awaiting Approval</TypographySpan>
                                                                    <InsertDriveFileOutlinedIcon sx={{width: '25px', height: '25px'}}/>
                                                                </Grid>
                                                            )}
                                                        </Fragment>
                                                    ) : (
                                                        <Grid item display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1} padding={'0px'} className="status">
                                                            <TypographySpan fontWeight={600}>Not Yet Submitted</TypographySpan>
                                                        </Grid>
                                                    )}
                                                </Fragment>
                                            )}
                                        </Grid>
                                    </Grid>
                                )}
                            </TabList>

                        <TabPanel value="pre-start" className="pre-start">
                            <Grid container xs={12} sm={12} md={12} lg={12} rowGap={3} columnGap={8}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Fitness checked={getFitnessStatement()} editable={isPrestartEditable()}></Fitness>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} display={'flex'} justifyContent={'right'}>
                                    <CollapseExpand onClick={handleCollapsed}/>
                                </Grid>
                                {showTabContent && (
                                    <>
                                        <Grid item xs={12} sm={12} md lg>
                                            <Hazard mode={'normal'} title={'Hazards Type A Issues'} issues={getHazardAIssues()} type="hazardA" editable={isPrestartEditable()} collapsed={collapsed}></Hazard>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <Hazard mode={'split'} title={'Hazards Type B Issues'} issues={getHazardBIssues()} type="hazardB" editable={isPrestartEditable()} collapsed={collapsed}></Hazard>
                                        </Grid>
                                        <Grid container xs={12} sm={12} md lg gap={4} height={'fit-content'}>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Hazard mode={'normal'} title={'Running Checks'} issues={getRunningIssues()} type="running" editable={isPrestartEditable()} collapsed={collapsed}></Hazard>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Fluid fuelLevel={getFuelLevel()} oilLevel={getOilLevel()} waterLevel={getWaterLevel()} editable={isPrestartEditable()} collapsed={collapsed}></Fluid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <MachineHours mode='prestart' engine={getEngineHours('pre-start')} percussive={getPercussiveHours('pre-start')} editable={isPrestartEditable()} collapsed={collapsed}></MachineHours>
                                            </Grid>
                                        </Grid>
                                        <Grid container xs={12} sm={12} md={12} lg={12} gap={8} height={'fit-content'}>
                                            <Grid item xs={12} sm={12} md lg>
                                                <Notes mode='pre-start' supervisor={getPrestartNotes()} editable={isPrestartEditable()} collapsed={collapsed}></Notes>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md lg>
                                                <Approval mode='pre-start' approved={getApproved('pre-start')} approvalHistory={getApprovalHistory('pre-start')} editable={isPrestartEditable()} onApprovalClick={(e) => {loadData(e.approval, e.mode)}}></Approval>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </TabPanel>

                        <TabPanel value="shift" className="shift">
                            <Grid item lg display={'flex'} justifyContent={'right'} marginBottom={'16px'}>
                                <CollapseExpand onClick={handleCollapsed}/>
                            </Grid>
                            {showTabContent && (
                                <Grid xs={12} sm={12} md={12} lg={12} container gap={8} display={'flex'} flexDirection={'row'}>
                                    <Grid container xs={12} sm={12} md={12} lg gap={4} display={'flex'} flexDirection={'column'}>
                                        <GeneralInformation drillStartTime={getDrillStartTime()} patterns={getDrillPatterns()} editable={isShiftEditable()} collapsed={collapsed}></GeneralInformation>
                                        <DrillingActivity drillingTasks={getDrillTasks()} shiftId={getShiftId()} editable={isShiftEditable()} collapsed={collapsed}></DrillingActivity>
                                        <ReportedDelays operational={getOperationalDelays()} mechanical={getMechanicalDelays()} nonproductive={getNonProductiveDelays()} editable={isShiftEditable()} collapsed={collapsed}></ReportedDelays>
                                    </Grid>
                                    <Grid container xs={12} sm={12} md={12} lg gap={4} display={'flex'} flexDirection={'column'}>
                                        <RockTools rockTools={getRockTools()} editable={isShiftEditable()} collapsed={collapsed}></RockTools>
                                        <MachineHours mode='shift' engine={getEngineHours('shift')} percussive={getPercussiveHours('shift')} prestart={[getEngineHours('pre-start'), getPercussiveHours('pre-start')]} editable={isShiftEditable()} collapsed={collapsed}></MachineHours>
                                        <Notes mode='shift' operator={getOperatorNotes()} supervisor={getSupervisorNotes()} editable={isShiftEditable()} collapsed={collapsed}></Notes>
                                        <Approval mode='shift' approved={getApproved('shift')} approvalHistory={getApprovalHistory('shift')} editable={isShiftEditable()} onApprovalClick={(e) => {loadData(e.approval, e.mode)}}></Approval>
                                    </Grid>
                                </Grid>
                            )}
                            
                        </TabPanel>
                    </TabContext>
                </Container>
            )}
        </div>
    );
}

export default ReportsPage;
