import React, { Suspense } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import "utils/i18n";

import appStore from "redux/store";
import history from "utils/history";

// import LayoutWrapper
import LayoutWrapper from "components/layout/LayoutWrapper";
//import containers
import Home from "containers/home";
import Profile from "containers/profile";
import Login from "containers/login";
//import Login from 'containers/login';
import Field from "containers/field";
import Machines from "containers/machines";
import Users from "containers/users/Users";
import Board from "containers/board";
import Shift from "containers/shift";
import Analytics from "containers/analytics";
import Reports from "containers/reports";

//import utils
import ProtectedRoute from "utils/protectedRoute";

//import styles
import theme from "styles/theme";
import Audit from "components/audit/audit";

export const isLocal = window.location.origin.includes('localhost') ? true : false;
// export const isDev = window.location.origin.replace('https://', '').replace('.horizonsdca.co', '') === 'sys-demo' ? true : false;
//the routes having a tab should be first and index should be incremented by 1
export const routes = [
  {
    path: "/field",
    label: "tabs.tab_field",
    hasTab: true,
    component: Field,
    requiredRoles: ["engineer", "surveyor", "director"],
    tabRegex: /^\/field/, //regex that should be used to match the tab that should be active (=any route matching that regex will use that tab)
    index: 0,
  },
  {
    path: "/board",
    label: "tabs.tab_board",
    hasTab: true,
    component: Board,
    requiredRoles: ["engineer", "supervisor", "surveyor", "director"],
    tabRegex: /^\/board/, //regex that should be used to match the tab that should be active (=any route matching that regex will use that tab)
    index: 1,
  },
  {
    path: "/shift",
    label: "tabs.tab_shift",
    hasTab: true,
    component: Shift,
    requiredRoles: ["supervisor", "engineer", "director"],
    tabRegex: /^\/shift/, //regex that should be used to match the tab that should be active (=any route matching that regex will use that tab)
    index: 2,
  },
  {
    path: "/reports",
    label: "tabs.tab_report",
    hasTab: true,
    component: Reports,
    requiredRoles: ["supervisor", "director"],
    tabRegex: /^\/reports/, //regex that should be used to match the tab that should be active (=any route matching that regex will use that tab)
    index: 3,
  },
  {
    path: "/reports/:id",
    label: "tabs.tab_report",
    hasTab: false,
    component: Reports,
    requiredRoles: ["supervisor", "director"],
    tabRegex: /^\/reports/, //regex that should be used to match the tab that should be active (=any route matching that regex will use that tab)
    index: 16,
  },
  {
    path: "/machines",
    label: "tabs.tab_machines",
    hasTab: true,
    component: Machines,
    requiredRoles: ["maintenance", "director"],
    tabRegex: /^\/machines/, //regex that should be used to match the tab that should be active (=any route matching that regex will use that tab)
    index: 4,
  },
  {
    path: "/user",
    label: "tabs.tab_users",
    hasTab: false,
    component: Users,
    requiredRoles: ["director"],
    tabRegex: /^\/user/, //regex that should be used to match the tab that should be active (=any route matching that regex will use that tab)
    index: 5,
  },
  {
    path: "/user/list",
    label: "tabs.tab_users",
    hasTab: false,
    component: Users,
    requiredRoles: ["director"],
    tabRegex: /^\/user/, //regex that should be used to match the tab that should be active (=any route matching that regex will use that tab)
    index: 6,
  },
  {
    path: "/user/new",
    label: "tabs.tab_users",
    hasTab: false,
    component: Users,
    requiredRoles: ["director"],
    tabRegex: /^\/user/, //regex that should be used to match the tab that should be active (=any route matching that regex will use that tab)
    index: 7,
  },
  {
    path: "/user/update/:id",
    label: "tabs.tab_users",
    hasTab: false,
    component: Users,
    requiredRoles: ["director"],
    tabRegex: /^\/user/, //regex that should be used to match the tab that should be active (=any route matching that regex will use that tab)
    index: 8,
  },
  {
    path: "/analytics",
    label: "tabs.tab_analytics",
    hasTab: true,
    component: Analytics,
    requiredRoles: ["supervisor", "engineer", "director", "maintenance"],
    tabRegex: /^\/analytics/, //regex that should be used to match the tab that should be active (=any route matching that regex will use that tab)
    index: 9,
  },
  {
    path: "/login",
    label: "",
    hasTab: false,
    component: Login,
    index: 10,
  },
  {
    path: "/profile",
    label: "",
    hasTab: false,
    component: Profile,
    requiredRoles: ["*"],
    index: 11,
  },
  {
    path: "/audit",
    label: "",
    hasTab: false,
    component: Audit,
    requiredRoles: ["*"],
    index: 16,
  },
  {
    path: "/machines/list",
    label: "tabs.tab_machines",
    hasTab: false,
    component: Machines,
    requiredRoles: ["maintenance", "director"],
    index: 12,
  },
  {
    path: "/machines/new",
    label: "tabs.tab_machines",
    hasTab: false,
    component: Machines,
    requiredRoles: ["maintenance", "director"],
    index: 13,
  },
  {
    path: "/machines/info/:id",
    label: "tabs.tab_machines",
    hasTab: false,
    component: Machines,
    requiredRoles: ["maintenance", "director"],
    index: 14,
  },
  {
    path: "/machines/edit/:id",
    label: "tabs.tab_machines",
    hasTab: false,
    component: Machines,
    requiredRoles: ["maintenance", "director"],
    tabRegex: /^\/machines/, //regex that should be used to match the tab that should be active
    index: 15,
  },
];

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <Provider store={appStore}>
          <BrowserRouter history={history}>
            <Suspense fallback={null}>
              <LayoutWrapper>
                <Routes>
                  {
                    //generate the routes
                    routes.map((x) => {
                      if (x.requiredRoles) {
                        return (
                          <Route
                            key={x.path}
                            path={x.path}
                            element={
                              <ProtectedRoute
                                path={x.path}
                                element={<x.component />}
                                requiredRoles={x.requiredRoles}
                                key={x.path}
                              />
                            }
                          />
                        );
                      } else {
                        return (
                          <Route
                            key={x.path}
                            path={x.path}
                            element={<x.component />}
                          />
                        );
                      }
                    })
                  }
                  <Route path="/" element={<Home />} />
                </Routes>
              </LayoutWrapper>
            </Suspense>
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
