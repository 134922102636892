import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Add, Info } from "@mui/icons-material";
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';

//import utils
import { GetLoggedInUser } from "api/auth";

//Import css
import "./modalMaintenance.scss";

//Import Actions
import { modalClose } from "redux/commonRedux/commonActions";
import { addVehicleEvent, deleteVehicleEvent, updateVehicleEvent } from "redux/machinesRedux/machinesActions";
import styles from "../../styles/_sys.scss";
import { createShiftReport, deleteShiftReport, fetchAllShiftsForVehicleBySerial } from "redux/shiftRedux/shiftActions";

function ModalMaintenance(props) {
  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();
  // const state = useSelector((state) => (state));

  const user = GetLoggedInUser();
  const [maintenanceEvent, setMaintenanceEvent] = useState({
    startedAt: props.modal.maintenanceData.startedAt || dayjs(new Date()),
    endedAt: props.modal.maintenanceData.endedAt || dayjs(new Date()),
    type: props.modal.maintenanceData.eventType || "SCHEDULED_SERVICE",
    report: props.modal.maintenanceData.notes || "",
  });

  const [updatedMaintenanceEvent, setUpdatedMaintenanceEvent] = useState({
    id: props.modal.maintenanceData.id || "000000000000000000000000",
    startedAt: props.modal.maintenanceData.startedAt || dayjs(new Date()),
    endedAt: props.modal.maintenanceData.endedAt || dayjs(new Date()),
    type: props.modal.maintenanceData.eventType || "SCHEDULED_SERVICE",
    report: props.modal.maintenanceData.notes || "",
  });

  const [maintenanceErrors, setMaintenanceErrors] = useState({
    report: "",
    endedAt: "",
  });

  React.useEffect(() => {
    formValidation();
    // eslint-disable-next-line
  }, [updatedMaintenanceEvent]);

  const handleDateTimePickerChange = (property, dateObject) => {
    setUpdatedMaintenanceEvent({ ...updatedMaintenanceEvent, [property]: dateObject });
  };

  const handleValueChange = (e) => {
    setUpdatedMaintenanceEvent({
      ...updatedMaintenanceEvent,
      [e.target.name]: e.target.value,
    });
  };

  const isFormValid = () => {
    let isNotEmpty = false;
    Object.keys(maintenanceErrors).forEach(function (key, index) {
      if (maintenanceErrors[key] !== "") isNotEmpty = true;
    });
    if (isNotEmpty) return false;
    if (updatedMaintenanceEvent.report === "") return false;
    return true;
  };
  const formValidation = () => {
    if (props.modal.mode === "info") return; //no need to do form validation if info mode
    if (props.modal.mode === "delete") return; //no need to do form validation if info mode
    let newErrors = {
      endedAt: "",
      report: "",
    };
    let formattedStartDate = dayjs(new Date(updatedMaintenanceEvent?.startedAt))?.format();
    let formattedEndDate = dayjs(new Date(updatedMaintenanceEvent?.endedAt))?.format();
    if (formattedStartDate > formattedEndDate) {
      newErrors.endedAt = t(
        "components.modals.modalMaintenance.error_end_date_before_start_date"
      );
    }
    if (updatedMaintenanceEvent.report === "") {
      newErrors.report = t(
        "components.modals.modalMaintenance.error_report_required"
      );
    }
    setMaintenanceErrors(newErrors);
    return;
  };

  const handleSave = async () => {
    if (maintenanceErrors.endedAt !== "") return;

    let maintenanceData = {
      id: updatedMaintenanceEvent.id,
      vehicleID: props.modal.vehicle.id,
      vehicleSerial: props.modal.vehicle.serial,
      startedAt: updatedMaintenanceEvent.startedAt,
      endedAt: updatedMaintenanceEvent.endedAt,
      eventType: updatedMaintenanceEvent.type,
      notes: updatedMaintenanceEvent.report,
      reportedBy: user.username,
    };

    if (props?.modal?.mode === "add") {
      dispatch(addVehicleEvent(maintenanceData));

      if (maintenanceData?.eventType === "UNSCHEDULED_SERVICE") {
        const shifts = makeShiftData(maintenanceData);
        shifts?.forEach(async (shift) => {
          await dispatch(createShiftReport(shift));
        });
      }
    }

    if (props?.modal?.mode === "edit") {
      if (maintenanceEvent?.type === "UNSCHEDULED_SERVICE") {
        await dispatch(fetchAllShiftsForVehicleBySerial(maintenanceData.vehicleSerial)).then((data) => {
          const oosShifts = data?.payload?.filter((s) => {return s.state === 5});
          oosShifts?.forEach(async (shift) => {
            if (shift.createdAt >= maintenanceEvent.startedAt && shift.endTime <= maintenanceEvent.endedAt) {
              await dispatch(deleteShiftReport(shift?._id));
            }
          })
        });
        // create shifts based on new event data
        const shifts = makeShiftData(maintenanceData);
        shifts?.forEach(async (shift) => {
          await dispatch(createShiftReport(shift));
        });
      }

      dispatch(updateVehicleEvent(maintenanceData));
    }
  };

  const makeShiftData = (maintenanceData) => {
    const emptyId = "000000000000000000000000";

    const startDate = new Date(maintenanceData?.startedAt);
    const endDate = new Date(maintenanceData?.endedAt);
    const shifts = [];
    const shiftDuration = 12 * 60 * 60 * 1000;

    let currentStart = startDate;

    while (currentStart < maintenanceData?.endedAt) {
      let currentEnd = new Date(currentStart.getTime() + shiftDuration);

      if (currentEnd > endDate) {
        currentEnd = endDate;
      }

      shifts.push({
        _id: emptyId,
        users: [user.username],
        vehicleSerial: maintenanceData?.vehicleSerial,
        startTime: new Date(currentStart),
        endTime: new Date(currentEnd),
        operationDelays: [],
        rockTools: {shank: [0, 0, 0], rod: [0, 0, 0], bit: [0, 0, 0]},
        notes: 'Auto-generated Out-of-Service Shift from Unplanned Breakdown event.\n\n' + maintenanceData?.notes,
        supervisorNotes: '',
        tasks: [],
        info: [],
        state: 5,
        approved: true,
        approvalHistory: [
          {description: 'Report Approved', user: user.username, createdAt: new Date()},
          {description: 'Initial Report Submission', user: user.username, createdAt: new Date()},
        ],
        createdAt: new Date(currentStart),
        updatedAt: new Date(),
      });
  
      currentStart = currentEnd;
    }
    return shifts;
  }

  const handleDelete = async () => {
    let maintenanceData = {
      id: updatedMaintenanceEvent.id,
      vehicleID: props.modal.vehicle.id,
      vehicleSerial: props.modal.vehicle.serial,
      startedAt: updatedMaintenanceEvent.startedAt,
      endedAt: updatedMaintenanceEvent.endedAt,
      eventType: updatedMaintenanceEvent.type,
      notes: updatedMaintenanceEvent.report,
      reportedBy: user.username,
    };

    if (maintenanceEvent?.type === "UNSCHEDULED_SERVICE") {
      await dispatch(fetchAllShiftsForVehicleBySerial(maintenanceData.vehicleSerial)).then((data) => {
        const oosShifts = data?.payload?.filter((s) => {return s.state === 5});
        oosShifts?.forEach(async (shift) => {
          if (shift.createdAt >= maintenanceEvent.startedAt && shift.endTime <= maintenanceEvent.endedAt) {
            await dispatch(deleteShiftReport(shift?._id));
          }
        })
      });
    }

    dispatch(deleteVehicleEvent({eventId: maintenanceData.id, event: maintenanceData}));
  }

  let dialogTitle;

  if (props.modal.mode === "add") {
    dialogTitle = (
      <DialogTitle display={'flex'} alignContent={'center'} gap={2}>
        <HandymanOutlinedIcon />
        <span style={{fontSize: '40px'}}>
          Add Maintenance Event
        </span>
      </DialogTitle>
    );
  }

  if (props.modal.mode === "info") {
    dialogTitle = (
      <DialogTitle display={'flex'} alignContent={'center'} gap={2}>
        <HandymanOutlinedIcon />
        <span style={{fontSize: '40px'}}>
          {t("components.modals.modalMaintenance.text_primary_title_info")}
        </span>
      </DialogTitle>
    );
  }

  if (props.modal.mode === "edit") {
    dialogTitle = (
      <DialogTitle display={'flex'} alignContent={'center'} gap={2}>
        <HandymanOutlinedIcon />
        <span style={{fontSize: '40px'}}>
          Edit Maintenance Event
        </span>
      </DialogTitle>
    );
  }

  if (props.modal.mode === "delete") {
    dialogTitle = (
      <DialogTitle display={'flex'} alignContent={'center'} color={styles.errorRedDark} gap={2}>
        <HandymanOutlinedIcon />
        <span style={{fontSize: '40px'}}>
          Delete Maintenance Event
        </span>
      </DialogTitle>
    );
  }

  const formatDate = (iso) => {
    const date = new Date(iso);

    const timeString = date?.toLocaleTimeString('en-au', { 
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    });

    return `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${timeString}`;
  }

  return (
    <Dialog
      open={true}
      className="modalMaintenance"
      onClose={() => dispatch(modalClose())}
      maxWidth={"lg"}
      fullWidth={true}
    >
      {dialogTitle}
      <DialogContent sx={{padding: '48px 84px'}}>
        <Grid container lg={12} gap={6} className="content">
          <Grid container lg={12} gap={6}>
            {props?.modal?.mode === 'delete' && (
              <Grid item lg={12}>
                <Typography color={styles.errorRedDark} fontSize={'24px'} paddingLeft={'32px'}><strong>Caution:</strong> Please review this maintenance event before deletion</Typography>
              </Grid>
            )}
            
            <Grid item lg>
              {(props?.modal?.mode === 'info' || props?.modal?.mode === 'edit' || props?.modal?.mode === 'delete') && (
                <TextField disabled={props?.modal?.mode === 'edit'} variant="standard" label="User" inputProps={{readOnly: true}} style={{width: '100%'}} value={props?.modal?.maintenanceData?.reportedBy}/>
              )}
              {props?.modal?.mode === 'add' && (
                <TextField disabled={true} variant="standard" label="User" inputProps={{readOnly: true}} style={{width: '100%'}} value={user?.username}/>
              )}
            </Grid>
            <Grid item lg>
              {(props?.modal?.mode === 'info' || props?.modal?.mode === 'edit' || props?.modal?.mode === 'delete') && (
                <TextField disabled={props?.modal?.mode === 'edit'} variant="standard" label="Machine Serial" inputProps={{readOnly: true}} style={{width: '100%'}} value={props?.modal?.vehicle?.serial}/>
              )}
              {props?.modal?.mode === 'add' && (
                <TextField disabled={true} variant="standard" label="Machine Serial" inputProps={{readOnly: true}} style={{width: '100%'}} value={props?.modal?.vehicle?.serial}/>
              )}
            </Grid>
          </Grid>

          <Grid container lg={12} gap={6}>
            <Grid item lg>
              {(props?.modal?.mode === 'info' || props?.modal?.mode === 'delete') && (
                <TextField variant="standard" label="Start Date/Time" inputProps={{readOnly: true}} style={{width: '100%'}} value={formatDate(updatedMaintenanceEvent?.startedAt)}/>
              )}
              {(props?.modal?.mode === 'edit' || props?.modal?.mode === 'add') && (
                <DateTimePicker
                    className="startedAt"
                    label="Start Date/Time"
                    inputFormat="DD/MM/YYYY HH:mm"
                    value={updatedMaintenanceEvent?.startedAt}
                    onChange={(d) => handleDateTimePickerChange("startedAt", d)}
                    renderInput={(params) => (
                      <TextField variant="standard" {...params} />
                    )}
                />
              )}
            </Grid>
            <Grid item lg>
              {(props?.modal?.mode === 'info' || props?.modal?.mode === 'delete') && (
                <TextField variant="standard" label="End Date/Time" inputProps={{readOnly: true}} style={{width: '100%'}} value={formatDate(updatedMaintenanceEvent?.endedAt)}/>
              )}
              {(props?.modal?.mode === 'edit' || props?.modal?.mode === 'add') && (
                <DateTimePicker
                    className="endedAt"
                    label="End Date/Time"
                    inputFormat="DD/MM/YYYY HH:mm"
                    value={updatedMaintenanceEvent?.endedAt}
                    onChange={(d) => handleDateTimePickerChange("endedAt", d)}
                    renderInput={(params) => (
                      <TextField
                        helperText={maintenanceErrors?.endedAt} variant="standard" {...params} />
                    )}
                />
              )}
            </Grid>
          </Grid>

          <Grid container lg={12}>
              {(props.modal.mode === 'info' || props?.modal?.mode === 'delete') && (
                <TextField variant="standard" label="Service Type" inputProps={{readOnly: true}} sx={{width: '100%'}} value={t("common.vehicleevents." + updatedMaintenanceEvent?.type)}/>
              )}

              {(props.modal.mode === 'edit' || props.modal.mode === 'add') && (
                <>
                  <InputLabel className="service-type-input-label"
                    style={{fontSize: '24px'}}
                    shrink>
                    {t(
                      "components.modals.modalMaintenance.text_maintenance_type"
                    )}
                  </InputLabel>
                  <Select
                    style={{fontSize: '26px'}}
                    value={updatedMaintenanceEvent?.type ?? "SCHEDULED_SERVICE"}
                    onChange={handleValueChange}
                    name="type"
                    variant="standard"
                    className="service-type-field-dropdown"
                    fullWidth
                  >
                    <MenuItem value={"SCHEDULED_SERVICE"}>
                      {t("common.vehicleevents.SCHEDULED_SERVICE")}
                    </MenuItem>
                    <MenuItem value={"UNSCHEDULED_SERVICE"}>
                      {t("common.vehicleevents.UNSCHEDULED_SERVICE")}
                    </MenuItem>
                  </Select>
                </>
              )}
          </Grid>

          <Grid container lg display={'flex'} flexDirection={'column'}>
              <Typography variant="caption" sx={{opacity: '0.6', fontSize: '22px'}}>Report</Typography>
              {(props.modal.mode === 'info' || props?.modal?.mode === 'delete') && (
                <TextareaAutosize readOnly minRows={8} value={updatedMaintenanceEvent?.report} style={{fontSize: '14px', padding: '12px'}}/>
              )}

              {(props.modal.mode === 'edit' || props.modal.mode === 'add') && (
                <TextareaAutosize minRows={8} required name="report" value={updatedMaintenanceEvent?.report ?? ''} style={{fontSize: '14px', padding: '12px'}} onChange={handleValueChange}/>
              )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>

        {props.modal.mode === "info" && (
          <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch(modalClose());
          }}
          >
            Close
          </Button>
        )} 

        {(props.modal.mode === "edit") && (
          <>
            <Button
            size="small"
            variant="contained"
            color="inherit"
            onClick={() => {
              dispatch(modalClose());
            }}
            >
              {t("components.modals.modalMaintenance.button_cancel")}
            </Button>

            <Button
              size="small"
              variant="contained"
              id="new_maintenance-save_maintenance"
              color="primary"
              disabled={!isFormValid()}
              onClick={() => {
                handleSave();
              }}
              >
              {t("components.modals.modalMaintenance.button_save")}
            </Button>
          </>
        )} 

        {(props.modal.mode === "add") && (
          <>
            <Button
            size="small"
            variant="contained"
            color="inherit"
            onClick={() => {
              dispatch(modalClose());
            }}
            >
              {t("components.modals.modalMaintenance.button_cancel")}
            </Button>

            <Button
              size="small"
              variant="contained"
              id="new_maintenance-save_maintenance"
              color="primary"
              disabled={!isFormValid()}
              onClick={() => {
                handleSave();
              }}
              >
              {t("components.modals.modalMaintenance.button_save")}
            </Button>
          </>
        )} 

        {(props.modal.mode === "delete") && (
          <>
            <Button
            size="small"
            variant="contained"
            color="inherit"
            onClick={() => {
              dispatch(modalClose());
            }}
            >
              {t("components.modals.modalMaintenance.button_cancel")}
            </Button>

            <Button
              size="small"
              variant="contained"
              id="new_maintenance-save_maintenance"
              color="primary"
              onClick={() => {
                handleDelete();
              }}
              >
                Delete Event
            </Button>
          </>
        )} 
      </DialogActions>
    </Dialog>
  );
}

export default ModalMaintenance;
