import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateShiftNotes, updatePrestartNotes } from "redux/shiftRedux/shiftActions";

import Card from "components/card";
import { Typography, 
    IconButton, 
    Container, 
    Grid, 
    TextareaAutosize,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import { TypographySpan } from "components/styledComponents";

import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';

import "./notes.scss";
import EditIcon from "static/icons/editicon";

function Notes({operator, supervisor, mode, editable, collapsed}) {
    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);

    const [operatorNotes, setOperatorNotes] = useState('');
    const [supervisorNotes, setSupervisorNotes] = useState('');
    const [updatedSupervisorNotes, setUpdatedSupervisorNotes] = useState('');

    const [showCard, setShowCard] = useState(false);

    const [notesModal, setNotesModal] = useState(false);

    const openNotesModal = () => {
        setNotesModal(true);
    }

    const closeNotesModal = () => {
        setUpdatedSupervisorNotes(supervisorNotes);
        setNotesModal(false);
    }

    const saveNotes = () => {
        if (mode === 'shift') {
            dispatch(updateShiftNotes({shiftId: reducerState?.shift?.shiftData?.shiftReport?._id, supervisorNotes: updatedSupervisorNotes}));
        }
        if (mode === 'pre-start') {
            dispatch(updatePrestartNotes({prestartId: reducerState?.shift?.shiftData?.preStartChecklist?.ID, notes: updatedSupervisorNotes}));
        }

        setSupervisorNotes(updatedSupervisorNotes);
        setShowCard(false);
        setNotesModal(false);
    }

    const supervisorNotesChange = (event) => {
        setUpdatedSupervisorNotes(event?.target?.value);
    }

    const isDisabled = () => {
        if (supervisorNotes !== updatedSupervisorNotes) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (operator) {
            setOperatorNotes(operator);
        }
        if (supervisor) {
            setSupervisorNotes(supervisor);
        }
    }, []);

    useEffect(() => {
            setShowCard(true);
    }, [supervisorNotes]);

  return (
    <>
        {showCard && (
            <Card title='Notes/Comments' 
            content={
                <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className="notes">
                    {mode === 'shift' ? (
                        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} gap={1}>
                            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TypographySpan variant="caption">Operator's Notes</TypographySpan>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextareaAutosize readOnly className="text-area" defaultValue={operatorNotes}/>
                                </Grid>
                            </Grid>
                            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TypographySpan variant="caption">Supervisor's Notes</TypographySpan>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextareaAutosize readOnly className="text-area" defaultValue={supervisorNotes}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextareaAutosize readOnly className="text-area pre-start" defaultValue={supervisorNotes}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            }
            edit={
                <IconButton className="edit" aria-label="edit" size="small" style={{display: !editable ? 'none' : ''}} onClick={openNotesModal}>
                    <EditIcon/>
                </IconButton>
            }
            canCollapse={true}
            collapsed={collapsed}>
            </Card>
        )}
        
        <Dialog
            className="notes-modal"
            fullWidth={true}
            maxWidth='md'
            open={notesModal}
            onClose={closeNotesModal}>
            <DialogTitle className="dialog-title">
            <ChatBubbleOutlineOutlinedIcon/> 
            <TypographySpan>Edit Notes/Comments</TypographySpan>
            </DialogTitle>
            <DialogContent>
            <DialogContentText className="dialog-content">
                <Container>
                    {mode === 'shift' ? (
                        <Grid container gap={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="grid-item">
                                <TypographySpan variant="caption">Operator's Notes</TypographySpan>
                                <TextareaAutosize readOnly minRows={4} className="text-area" disabled={true} defaultValue={operatorNotes}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="grid-item">
                                <TypographySpan variant="caption">Supervisor's Notes</TypographySpan>
                                <TextareaAutosize minRows={4} className="text-area" defaultValue={supervisorNotes} placeholder="Enter a note or comment..." onChange={supervisorNotesChange}/>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container gap={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="grid-item">
                                <TextareaAutosize minRows={4} className="text-area" defaultValue={supervisorNotes} placeholder="Enter a note or comment..." onChange={supervisorNotesChange}/>
                            </Grid>
                        </Grid>
                    )}
                </Container>
            </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-actions">
            <Button variant="contained" color="cancel" onClick={closeNotesModal}>Cancel</Button>
            <Button variant="contained" onClick={saveNotes} autoFocus disabled={isDisabled()}>
                Save
            </Button>
            </DialogActions>
        </Dialog>
    </>    
  );
}

export default Notes;
