export const operationalDelays = [
    {
      label: 'screens.operationaldelay.text_preshift_meeting',
      name: 'preshift_meeting',
      type: 'operational',
      icon: 'list',
    },
    {
      label: 'screens.operationaldelay.text_transport',
      name: 'transport_to_pit',
      type: 'operational',
      icon: 'tram',
    },
    {
      label: 'screens.operationaldelay.text_pre_check',
      name: 'prestart_check',
      type: 'operational',
      icon: 'view-list',
    },
    {
      label: 'screens.operationaldelay.text_chop_time',
      name: 'chop_time',
      type: 'operational',
      icon: 'watch-later',
    },
    {
      label: 'screens.operationaldelay.text_blasting_wait',
      name: 'blasting_wait',
      type: 'operational',
      icon: 'whatshot',
    },
    {
      label: 'screens.operationaldelay.text_blasting_tramming',
      name: 'blasting_tramming',
      type: 'operational',
      icon: 'whatshot',
    },
    {
      label: 'screens.operationaldelay.text_wait_pattern',
      name: 'pattern_wait',
      type: 'operational',
      icon: 'gps-fixed',
    },
    {
      label: 'screens.operationaldelay.text_wait_drilling',
      name: 'drilling_ground',
      type: 'operational',
      icon: 'subway',
    },
    {
      label: 'screens.operationaldelay.text_wait_cleanup',
      name: 'floor_cleanup',
      type: 'operational',
      icon: 'watch-later',
    },
    {
      label: 'screens.operationaldelay.text_rig_relocation_tramming',
      name: 'relocation_tramming',
      type: 'operational',
      icon: 'shuffle',
    },
    {
      label: 'screens.operationaldelay.text_rig_relocation_float',
      name: 'relocation_lowbed',
      type: 'operational',
      icon: 'shuffle',
    },
    {
      label: 'screens.operationaldelay.text_tram_onoff',
      name: 'tram_pattern',
      type: 'operational',
      icon: 'tram',
    },
    {
      label: 'screens.operationaldelay.text_wait_drilling_water',
      name: 'drilling_water',
      type: 'operational',
      icon: 'watch-later',
    },
    {
      label: 'screens.operationaldelay.text_weather',
      name: 'weather',
      type: 'operational',
      icon: 'watch-later',
    },
    {
      label: 'screens.operationaldelay.text_wait_rock_tools',
      name: 'rock_tools',
      type: 'operational',
      icon: 'watch-later',
    },
    {
      label: 'screens.operationaldelay.text_bit_change',
      name: 'bit_change',
      type: 'operational',
      icon: 'watch-later',
    },
    {
      label: 'screens.operationaldelay.text_wait_grease',
      name: 'grease_wait',
      type: 'operational',
      icon: 'watch-later',
    },
  ];
  
  export const mechanicalBreakdown = [
    {
      label: 'screens.operationaldelay.text_low_hydraulic_oil',
      name: 'low_hydraulic_oil',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_low_engine_oil',
      name: 'low_engine_oil',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_low_compressor_oil',
      name: 'low_compressor_oil',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_low_rock_oil',
      name: 'low_rock_oil',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_low_gear_oil',
      name: 'low_gear_oil',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_engine_can_not_start',
      name: 'engine_cannot_start',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_engine_overheating',
      name: 'engine_overheating',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_compressor_overheating',
      name: 'compressor_overheating',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_hydraulic_overheating',
      name: 'hydraulic_overheating',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_oil_leakage',
      name: 'oil_leakage',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_hose_burst',
      name: 'hose_burst',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_faulty_carousel',
      name: 'faulty_carousel',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_pito_jaw_removed',
      name: 'pito_jaw_removed',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_faulty_rod_changer_joystick',
      name: 'faulty_rod_changer_joystick',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_faulty_drilling_joystick',
      name: 'faulty_drilling_joystick',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_faulty_track',
      name: 'faulty_track',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_faulty_drilling_or_tramming_lights',
      name: 'faulty_drilling_or_tramming_lights',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_engine_cut_off',
      name: 'engine_cut_off',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_faulty_a_c',
      name: 'faulty_a_c',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label:
        'screens.operationaldelay.text_faulty_water_injection_operating_system',
      name: 'faulty_water_injection_operating_system',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label:
        'screens.operationaldelay.text_faulty_thread_grease_operating_system',
      name: 'faulty_thread_grease_operating_system',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_faulty_dust_collector_system',
      name: 'faulty_dust_collector_system',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_flushing_air_seized',
      name: 'flushing_air_seized',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_broken_suction_head_cylinder',
      name: 'broken_suction_head_cylinder',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_suction_hose_removed',
      name: 'suction_hose_removed',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_faulty_feed',
      name: 'faulty_feed',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_faulty_rotation',
      name: 'faulty_rotation',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_torn_feed_rope_or_chain',
      name: 'torn_feed_rope_or_chain',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_faulty_rod_changer_or_handler',
      name: 'faulty_rod_handler',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_loosed_or_removed_front_cover_bolts',
      name: 'loosed_or_removed_front_cover_bolts',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_faulty_tramming_system',
      name: 'faulty_tramming_system',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_faulty_cabin_door_lock',
      name: 'faulty_cabin_door_lock',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_faulty_rpm_engine_high_or_low',
      name: 'faulty_rpm_engine_high_or_low',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_faulty_wiping_system',
      name: 'faulty_wiping_system',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_faulty_oscillation',
      name: 'faulty_oscillation',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_faulty_rear_jack',
      name: 'faulty_rear_jack',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_faulty_boom_joystick',
      name: 'faulty_boom_joystick',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_engine_starts_but_no_control_works',
      name: 'engine_starts_but_no_control_works',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_centraliser_jaw',
      name: 'centraliser_jaw',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_flushing_head_bolts',
      name: 'flushing_head_bolts',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_faulty_mounting_pressure_bolt',
      name: 'faulty_mounting_pressure_bolt',
      type: 'breakdown',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_faulty_boom_levers',
      name: 'faulty_boom_levers',
      type: 'breakdown',
      icon: 'av-timer',
    },
  ];
  
  export const mechanicalNonProd = [
    {
      label: 'screens.operationaldelay.text_worn_out_pito_jaws',
      name: 'worn_out_pito_jaws',
      type: 'nonprod',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_worn_rod_handler_jaws',
      name: 'worn_rod_handler_jaws',
      type: 'nonprod',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_shank_rod_misalignement',
      name: 'shank_rod_misalignement',
      type: 'nonprod',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_rod_rod_misalignment',
      name: 'rod_rod_misalignment',
      type: 'nonprod',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_defective_fast_tramming',
      name: 'defective_fast_tramming',
      type: 'nonprod',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_hard_breaking_drifter',
      name: 'hard_breaking_drifter',
      type: 'nonprod',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_defective_threading_in_or_out',
      name: 'defective_threading_in_or_out',
      type: 'nonprod',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_loosed_ground_pin_bolts',
      name: 'loosed_ground_pin_bolts',
      type: 'nonprod',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_faulty_drifter_fast_feed',
      name: 'faulty_drifter_fast_feed',
      type: 'nonprod',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_faulty_suction_head',
      name: 'faulty_suction_head',
      type: 'nonprod',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_faulty_dust_collector',
      name: 'faulty_dust_collector',
      type: 'nonprod',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_faulty_drilling_water_injection',
      name: 'faulty_drilling_water_injection',
      type: 'nonprod',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_manual_thread_greasing',
      name: 'manual_thread_greasing',
      type: 'nonprod',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_slow_boom_lifting_cylinder',
      name: 'slow_boom_lifting_cylinder',
      type: 'nonprod',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_faulty_angle_indicators',
      name: 'faulty_angle_indicators',
      type: 'nonprod',
      icon: 'av-timer',
    },
  ];
  
  export const opNonProd = [
    {
      label: 'screens.operationaldelay.text_intercept_rcgc',
      name: 'intercept_rcgc',
      type: 'opnonprod',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_excessive_water',
      name: 'excessive_water',
      type: 'opnonprod',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_excessive_overburden',
      name: 'excessive_overburden',
      type: 'opnonprod',
      icon: 'av-timer',
    },
    {
      label: 'screens.operationaldelay.text_intercept_cavity',
      name: 'intercept_cavity',
      type: 'opnonprod',
      icon: 'av-timer',
    },
  ];
  