import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";


import Card from "components/card";
import { Typography, IconButton, Container, Grid, TextField, InputAdornment } from "@mui/material";
import { TypographySpan } from "components/styledComponents";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

import "./machinehours.scss";
import { updateShiftMachineHours, updatePrestartMachineHours } from "redux/shiftRedux/shiftActions";
import EditIcon from "static/icons/editicon";
import { validateFloat } from "utils/tools";

function MachineHours({engine, percussive, prestart, editable, mode, collapse, typeId, updated, collapsed}) {
    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);

    const [engineHours, setEngineHours] = useState('');
    const [updatedEngineHours, setUpdatedEngineHours] = useState('');
    const [percussiveHours, setPercussiveHours] = useState('');
    const [updatedPercussiveHours, setUpdatedPercussiveHours] = useState('');

    const [showCard, setShowCard] = useState(false);
    const [showShift, setShowShift] = useState(false);

    const [hoursModal, setHoursModal] = useState(false);

    const openHoursModal = () => {
        setHoursModal(true);
    }

    const closeHoursModal = () => {
        setUpdatedEngineHours(engineHours);
        setUpdatedPercussiveHours(percussiveHours);
        setHoursModal(false);
    }

    const engineHoursChange = (event) => {
        const { value } = event?.target;
        setUpdatedEngineHours(parseFloat(value));
        setShowShift(false);
    }

    const percussiveHoursChange = (event) => {
        const { value } = event?.target;
        setUpdatedPercussiveHours(parseFloat(value));
        setShowShift(false);
    }

    const isDisabled = () => {
        if (isNaN(updatedEngineHours) || isNaN(updatedPercussiveHours)) {
            return true;
        }
        if (engineHours !== updatedEngineHours || percussiveHours !== updatedPercussiveHours) {
            return false;
        }
        return true;
    }

    const saveHours = () => {
        if (mode === 'shift') {
            dispatch(updateShiftMachineHours({shiftId: reducerState.shift.shiftData.shiftReport._id, hours: [{key: 'engineHoursEnd', value: parseFloat(updatedEngineHours)}, {key: 'percussiveHoursEnd', value: parseFloat(updatedPercussiveHours)}]}));
        }
        if (mode === 'prestart') {
            dispatch(updatePrestartMachineHours({prestartId: reducerState.shift.shiftData.preStartChecklist.ID, engine: parseFloat(updatedEngineHours), percussive: parseFloat(updatedPercussiveHours)}));
        }
        if (mode === 'vehicle-shift') {
            dispatch(updateShiftMachineHours({shiftId: typeId, hours: [{key: 'engineHoursEnd', value: parseFloat(updatedEngineHours)}, {key: 'percussiveHoursEnd', value: parseFloat(updatedPercussiveHours)}]}));
        }
        if (mode === 'vehicle-prestart') {
            dispatch(updatePrestartMachineHours({prestartId: typeId, engine: parseFloat(updatedEngineHours), percussive: parseFloat(updatedPercussiveHours)}));
        }

        setEngineHours(updatedEngineHours);
        setPercussiveHours(updatedPercussiveHours);
        setHoursModal(false);
    }

    const formatDate = (iso) => {
        const date = new Date(iso);
    
        const timeString = date?.toLocaleTimeString('en-au', { 
          hour12: false,
          hour: '2-digit',
          minute: '2-digit',
        });
    
        return `${timeString} ${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`;
      }

    useEffect(() => {
        if (engine) {
            setEngineHours(engine);
            setUpdatedEngineHours(engine);
        }
        if (percussive) {
            setPercussiveHours(percussive);
            setUpdatedPercussiveHours(percussive);
        }
    }, []);

    useEffect(() => {
            setShowCard(true);
            setShowShift(true);
    }, [engineHours, percussiveHours, updatedEngineHours, updatedPercussiveHours]);

  return (
    <>
        {showCard && (
            <Card title='Engine and Percussive Hours' 
            content={
                <div className="machine-hours">
                    <Grid>
                        <Grid container className="hours">
                            <Grid container className="line">
                                <Grid xs={6} sm={6} md={6} lg={6} item className="label"><TypographySpan>Engine Hours</TypographySpan></Grid>
                                <Grid xs={6} sm={6} md={6} lg={6} item className="data"><TypographySpan>{engineHours ? (Math.round(engineHours*10)/10) : 'Not recorded yet'}</TypographySpan></Grid>
                            </Grid>
                        </Grid>
                        <Grid container className="hours">
                            <Grid container className="line">
                                <Grid xs={6} sm={6} md={6} lg={6} item className="label"><TypographySpan>Percussive Hours</TypographySpan></Grid>
                                <Grid xs={6} sm={6} md={6} lg={6} item className="data"><TypographySpan>{percussiveHours ? (Math.round(percussiveHours*10)/10) : 'Not recorded yet'}</TypographySpan></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            }
            edit={
                <>
                    {editable ? (
                        <IconButton className="edit" aria-label="edit" size="small" onClick={openHoursModal} style={{display: engineHours && percussiveHours ? '' : 'none'}}>
                            <EditIcon/>
                        </IconButton>
                    ) : (
                        <></>
                    )}
                </>
            }
            canCollapse={collapse ?? true}
            collapsed={collapsed}>
            </Card>
        )}
        
        <Dialog
            className="hours-modal"
            fullWidth={true}
            open={hoursModal}
            onClose={closeHoursModal}>
            <DialogTitle className="dialog-title">
            <AccessTimeOutlinedIcon/> 
            <TypographySpan>Edit Engine and Percussive Hours</TypographySpan>
            </DialogTitle>
            <DialogContent>
            <DialogContentText className="dialog-content">
                <>
                    {mode === 'shift' && (
                        <Container>
                            <TypographySpan variant="caption">Recorded at <strong>End of Shift</strong></TypographySpan>
                            <Grid container lg={12} gap={4}>
                                <Grid container lg={12} gap={4} className="engine">
                                    <Grid item xs={12} sm={12} md={12} lg={7}>
                                        <TextField required label="End Engine Hours" onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}} onChange={engineHoursChange} variant="standard" defaultValue={(Math.round(updatedEngineHours*10)/10)} InputProps={{endAdornment: <InputAdornment position="end">hours</InputAdornment>}}/>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={4}>
                                        {showShift && (
                                            <TextField label="Total for Shift" variant="standard" disabled defaultValue={isNaN(Math.round((updatedEngineHours-prestart[0]) * 10)/10) ? 'N/A' : Math.round((updatedEngineHours-prestart[0]) * 10)/10} InputProps={{readOnly: true, endAdornment: <InputAdornment position="end" style={{opacity: '0.4'}}>hours</InputAdornment>}}/> 
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container lg={12} gap={4} className="percussive">
                                    <Grid item xs={12} sm={12} md={12} lg={7}>
                                        <TextField required label="End Percussive Hours" onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}} onChange={percussiveHoursChange} variant="standard" defaultValue={(Math.round(updatedPercussiveHours*10)/10)} InputProps={{endAdornment: <InputAdornment position="end">hours</InputAdornment>}}/>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={4}>
                                        {showShift && (
                                            <TextField label="Total for Shift" variant="standard" disabled defaultValue={isNaN(Math.round((updatedPercussiveHours-prestart[1]) * 10)/10) ? 'N/A' : Math.round((updatedPercussiveHours-prestart[1]) * 10)/10} InputProps={{readOnly: true, endAdornment: <InputAdornment position="end" style={{opacity: '0.4'}}>hours</InputAdornment>}}/> 
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    )}
                    
                    {mode === 'prestart' && (
                        <Container>
                            <TypographySpan variant="caption">Recorded at <strong>Start of Shift</strong></TypographySpan>
                            <Grid container lg={12} gap={4}>
                                <Grid container lg={12} gap={4} className="engine">
                                    <Grid item xs={12} sm={12} md={12} lg={7}>
                                        <TextField required label="Start Engine Hours" onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}} onChange={engineHoursChange} variant="standard" defaultValue={(Math.round(updatedEngineHours*10)/10)} InputProps={{endAdornment: <InputAdornment position="end">hours</InputAdornment>}}/>
                                    </Grid>
                                </Grid>
                                <Grid container lg={12} gap={4} className="percussive">
                                    <Grid item xs={12} sm={12} md={12} lg={7}>
                                        <TextField required label="Start Percussive Hours" onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}} onChange={percussiveHoursChange} variant="standard" defaultValue={(Math.round(updatedPercussiveHours*10)/10)} InputProps={{endAdornment: <InputAdornment position="end">hours</InputAdornment>}}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    )}

                    {(mode === 'vehicle-shift' || mode === 'vehicle-prestart') && (
                        <Container>
                            <TypographySpan variant="caption">Last Updated: <strong>{formatDate(updated)}</strong></TypographySpan>
                            <Grid container lg={12} gap={4}>
                                <Grid container lg={12} gap={4} className="engine">
                                    <Grid item xs={12} sm={12} md={12} lg={7}>
                                        <TextField required label="Engine Hours" onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}} onChange={engineHoursChange} variant="standard" defaultValue={(Math.round(updatedEngineHours*10)/10)} InputProps={{endAdornment: <InputAdornment position="end">hours</InputAdornment>}}/>
                                    </Grid>
                                </Grid>
                                <Grid container lg={12} gap={4} className="percussive">
                                    <Grid item xs={12} sm={12} md={12} lg={7}>
                                        <TextField required label="Percussive Hours" onKeyDown={(e) => {if(!validateFloat(e.key)) {e.preventDefault();}}} onChange={percussiveHoursChange} variant="standard" defaultValue={(Math.round(updatedPercussiveHours*10)/10)} InputProps={{endAdornment: <InputAdornment position="end">hours</InputAdornment>}}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    )}
                </>
            </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-actions">
            <Button variant="contained" color="cancel" onClick={closeHoursModal}>Cancel</Button>
            <Button variant="contained" onClick={saveHours} autoFocus disabled={isDisabled()}>
                Save
            </Button>
            </DialogActions>
        </Dialog>
    </>
  );
}

export default MachineHours;
