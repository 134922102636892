import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "helper/config/axiosConfig";
import { modalError, modalClose, addToast } from "redux/commonRedux/commonActions";

export const fetchAuditLogs = createAsyncThunk(
    "audit/fetchAuditLogs",
    async (model, { rejectWithValue, dispatch }) => {
      try {
        const response = await axiosConfig().get("/audit");
        return response.data;
      } catch (err) {
        dispatch(modalError(err));
        return rejectWithValue([], { data: err.response.data });
      }
    }
  );