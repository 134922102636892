import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Card from "components/card";
import { Typography, IconButton, TextField, Grid } from "@mui/material";
import { TypographySpan } from "components/styledComponents";

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// Icons
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';


import "./generalinformation.scss";
import { updateShiftDrillingTime } from "redux/shiftRedux/shiftActions";
import EditIcon from "static/icons/editicon";

function GeneralInformation({drillStartTime, patterns, editable, collapsed}) {
    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);
    
    const [drillTime, setDrillTime] = useState('');
    const [updatedDrillTime, setUpdatedDrillTime] = useState('');

    const [patternsWorkedOn, setPatternsWorkedOn] = useState([]);
    const [updatedPatternsWorkedOn, setUpdatedPatternsWorkedOn] = useState([]);
    
    const availablePatterns = ['Test Pattern'];

    const [checkboxState, setCheckboxState] = useState(
        availablePatterns?.reduce((acc, option) => {
            acc[option] = patterns?.includes(option);
            return acc;
        }, {})
    );

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckboxState((prevState) => ({
          ...prevState,
          [name]: checked,
        }));

        setUpdatedPatternsWorkedOn((prevPatterns) => {
            if (checked) {
              return [...prevPatterns, name];
            } else {
              return prevPatterns?.filter((option) => option !== name);
            }
          });
    };


    const [showCard, setShowCard] = React.useState(false);

    // Drill Time functions
    const [drillTimeModal, setDrillTimeModal] = React.useState(false);
    const openDrillTimeModal = () => {
        setDrillTimeModal(true);
    }
    const closeDrillTimeModal = () => {
        setUpdatedDrillTime(drillTime);
        setDrillTimeModal(false);
    }
    const drillTimeChange = (event) => {
        if (event === null) {
            setUpdatedDrillTime(new Date().toISOString());
            return;
        }
        if (!isNaN(event.$y)) {
            setUpdatedDrillTime(DateToISO(event?.$d));
        }
    }
    const saveDrillTime = () => {
        dispatch(updateShiftDrillingTime({shiftId: reducerState?.shift?.shiftData?.shiftReport?._id, time: updatedDrillTime}));
        
        setDrillTime(updatedDrillTime);
        setShowCard(false);
        setDrillTimeModal(false);
    }
    

    // Patterns functions
    const [patternModal, setPatternModal] = React.useState(false);
    const openPatternModal = () => {
        setPatternModal(true);
    }
    const closePatternModal = () => {
        setUpdatedPatternsWorkedOn(patternsWorkedOn);
        setCheckboxState(availablePatterns?.reduce((acc, option) => {
            acc[option] = patterns?.includes(option);
            return acc;
        }, {}));
        setPatternModal(false);
    }
    const savePattern = () => {
        //code to save drill time
        // setDrillTimeView(ISOToTime(drillTime));
        setPatternModal(false);
    }
    const patternChange = (event) => {
        // setDrillTime(DateToISO(event.$d));
    }

    const ISOToTime = (date) => {
        const isoDate = new Date(date);
      
        const timeString = isoDate?.toLocaleTimeString('en-au', {
          hour12: false,
          hour: '2-digit',
          minute: '2-digit',
        });

        return timeString;
    };

    const DateToISO = (date) => {
        const isoDate = new Date(date);
      
        const isoDateString = isoDate?.toISOString();
      
        return isoDateString
    };

    const isDisabled = () => {
        if (drillTime !== updatedDrillTime) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (drillStartTime) {
            setDrillTime(drillStartTime);
            setUpdatedDrillTime(drillStartTime);
        }
        if (patterns) {
            setPatternsWorkedOn(patterns);
            setUpdatedPatternsWorkedOn(patterns);
        }
    }, [])

    useEffect(() => {
        if (drillTime) {
            setShowCard(true);
        }
    }, [drillTime])


  return (
    <>
        {showCard && (
            <Card title='General Information'
                content={
                    <div className="general-information">
                        <Grid>
                            <Grid className="drill-start">
                                {editable ? (
                                    <Grid container lg={12} className="line">
                                        <Grid item xs={6} sm={6} md={6} lg={6} className="label">
                                            <TypographySpan>Drilling start time</TypographySpan>
                                        </Grid>
                                        <Grid item xs={6} sm={5} md={5} lg={5} className="data">
                                            {drillTime ? (<TypographySpan>{ISOToTime(drillTime)}</TypographySpan>) : <TypographySpan><i>Not found</i></TypographySpan>}
                                        </Grid>
                                        <Grid item xs={12} sm={1} md={1} lg={1} className="edit">
                                            <IconButton aria-label="edit" size="small" onClick={openDrillTimeModal}>
                                                <EditIcon/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid container lg={12} className="line">
                                        <Grid item xs={6} sm={6} md={6} lg={6} className="label">
                                            <TypographySpan>Drilling start time</TypographySpan>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6} className="data">
                                            {drillTime ? (<TypographySpan>{ISOToTime(drillTime)}</TypographySpan>) : <TypographySpan><i>Not found</i></TypographySpan>}
                                        </Grid>
                                    </Grid>
                                )}
                                
                            </Grid>
                        </Grid>
                        
                        {/* <Grid>
                            <Grid className="patterns">
                                <Grid container lg={12} className="line">
                                    <Grid item xs={6} sm={6} md={6} lg={6} className="label">
                                        <TypographySpan>Number of patterns worked on</TypographySpan>
                                    </Grid>
                                    <Grid item xs={6} sm={5} md={5} lg={5} className="data">
                                        <TypographySpan>{patternsWorkedOn.length}</TypographySpan>
                                    </Grid>
                                    <Grid item xs={12} sm={1} md={1} lg={1} className="edit">
                                        <IconButton aria-label="edit" size="small" onClick={openPatternModal}>
                                            <ModeOutlinedIcon/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className="patterns">
                                <Grid container lg={12} className="line">
                                    <Grid item xs={6} sm={6} md={6} lg={6} className="label">
                                        <TypographySpan>Pattern IDs</TypographySpan>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} className="data">
                                        <TypographySpan>{patternsWorkedOnView}</TypographySpan>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid> */}
                        <Grid>
                            <Grid className="patterns">
                                <Grid container lg={12} className="line">
                                    <Grid item xs={6} sm={6} md={6} lg={6} className="label">
                                        <TypographySpan>Number of patterns worked on</TypographySpan>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} className="data">
                                        <TypographySpan>{patternsWorkedOn?.length}</TypographySpan>
                                    </Grid>
                                    {/* <Grid item xs={12} sm={1} md={1} lg={1} className="edit">
                                        <IconButton aria-label="edit" size="small" onClick={openPatternModal}>
                                            <ModeOutlinedIcon/>
                                        </IconButton>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                            <Grid className="patterns">
                                <Grid container lg={12} className="line">
                                    <Grid item xs={6} sm={6} md={6} lg={6} className="label">
                                        <TypographySpan>Pattern IDs</TypographySpan>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} className="data">
                                        <TypographySpan>{patternsWorkedOn?.join(', ')}</TypographySpan>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                }
                canCollapse={true}
                collapsed={collapsed}>
            </Card>
        )}
        
        {/* Drill Time Modal */}
        <Dialog
            className="drill-modal"
            fullWidth={true}
            maxWidth='sm'
            open={drillTimeModal}
            onClose={closeDrillTimeModal}>
            <DialogTitle className="dialog-title">
            <AccessTimeOutlinedIcon/> 
            <TypographySpan>Edit Drilling Start Time</TypographySpan>
            </DialogTitle>
            <DialogContent>
            <DialogContentText className="dialog-content">
                <Grid container xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="picker-title">
                        <span>Drilling start time <span className="required">*</span></span>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="time-picker">
                        <TimePicker 
                        inputProps={{readOnly: true}}
                        PaperProps={{sx: {minWidth: '400px'}}}
                        onChange={drillTimeChange}
                        renderInput={(params) => (
                            <TextField variant="standard" {...params} />
                        )}
                        value={updatedDrillTime}
                        />
                    </Grid>
                </Grid>
            </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-actions">
            <Button variant="contained" color="cancel" onClick={closeDrillTimeModal}>Cancel</Button>
            <Button variant="contained" onClick={saveDrillTime} autoFocus disabled={isDisabled()}>
                Save
            </Button>
            </DialogActions>
        </Dialog>

        {/* Patterns Modal */}
        <Dialog
            className="patterns-modal"
            fullWidth={true}
            open={patternModal}
            onClose={closePatternModal}>
            <DialogTitle className="dialog-title">
                <>
                    <ViewCompactIcon/> 
                    <TypographySpan>Edit Patterns Worked On</TypographySpan>
                </>
            </DialogTitle>
            <DialogContent>
            <DialogContentText className="dialog-content">
                <FormGroup>
                    {availablePatterns?.map((pattern) => (
                        <FormControlLabel
                            key={pattern}
                            control={
                            <Checkbox
                                name={pattern}
                                checked={checkboxState[pattern]}
                                onChange={handleCheckboxChange}
                                color="primary"
                            />
                            }
                            label={pattern}
                        />
                        ))}
                </FormGroup>
            </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-actions">
            <Button variant="contained" color="cancel" onClick={closePatternModal}>Cancel</Button>
            <Button variant="contained" onClick={savePattern} autoFocus>
                Save
            </Button>
            </DialogActions>
        </Dialog>
    </>
  );
}

export default GeneralInformation;
