import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "helper/config/axiosConfig";
import { modalError, modalClose, addToast } from "redux/commonRedux/commonActions";
import { apiBaseURL } from "helper/config/baseURLconfig";

export const createShiftReport = createAsyncThunk(
  "shift/createShiftReport",
  async (shiftReport, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().post("/shiftreport", shiftReport);
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const deleteShiftReport = createAsyncThunk(
  "shift/deleteShiftReport",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().delete("/shiftreport/" + id);
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const fetchShiftData = createAsyncThunk(
  "shift/shiftdata",
  async (pits, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().post("/shiftdata", { pits });
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const fetchShiftReportData = createAsyncThunk(
  "shift/fetchShiftReportData/shiftreport/byid",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().get("/shiftreport/byid/" + id);
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const fetchShiftReportDataWithoutLoader = createAsyncThunk(
  "shift/fetchShiftReportDataWithoutLoader/shiftreport/byid",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().get("/shiftreport/byid/" + id);
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const fetchAllShiftsForVehicleBySerial = createAsyncThunk(
  "shift/fetchAllShiftsForVehicleBySerial/shiftreport/all/byvehicleserial",
  async (serial, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().get("/shiftreport/all/byvehicleserial/" + serial);
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const fetchFullShiftData = createAsyncThunk(
  "shift/fetchFullShiftData/shiftreport",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().get("/reports/" + id);
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const fetchFullShiftDataWithoutLoader = createAsyncThunk(
  "shift/fetchFullShiftDataWithoutLoader/shiftreport",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().get("/reports/" + id);
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const fetchShiftDrillTasks = createAsyncThunk(
  "shift/fetchShiftDrillTasks",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().get("/reports/tasks/" + id);
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const fetchAvailableDrillTasks = createAsyncThunk(
  "shift/fetchAvailableDrillTasks",
  async (model, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().get("/reports/tasks");
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const fetchDrillTask = createAsyncThunk(
  "shift/fetchDrillTask",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().get("/reports/task/" + id);
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const fetchPrestartData = createAsyncThunk(
  "shift/fetchPrestartData/prestartreport",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().get("/reports/prestartdata/" + id);
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const updateShiftNotes = createAsyncThunk(
  "shift/updateShiftNotes",
  async ({shiftId, supervisorNotes}, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().put("/reports/notes/" + shiftId, {supervisorNotes});
      dispatch(
        addToast({
          type: "success",
          message: "Notes updated successfully !",
        })
      );
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const updatePrestartNotes = createAsyncThunk(
  "shift/updatePrestartNotes",
  async ({prestartId, notes}, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().put("/reports/prestartnotes/" + prestartId, {notes});
      dispatch(
        addToast({
          type: "success",
          message: "Notes updated successfully !",
        })
      );
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const updateShiftDrillingTime = createAsyncThunk(
  "shift/updateShiftDrillingTime",
  async ({shiftId, time}, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().put("/reports/starttime/" + shiftId, {time});
      dispatch(
        addToast({
          type: "success",
          message: "Drilling start time updated successfully !",
        })
      );
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const updateShiftRockTools= createAsyncThunk(
  "shift/updateShiftRockTools",
  async ({shiftId, rockTools}, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().put("/reports/rocktools/" + shiftId, {rockTools});
      dispatch(
        addToast({
          type: "success",
          message: "Rock tools updated successfully !",
        })
      );
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const updateShiftApproval= createAsyncThunk(
  "shift/updateShiftApproval",
  async ({shiftId, approval, user}, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().put("/reports/approval/" + shiftId, {approval, user});
      if (approval) {
        dispatch(
          addToast({
            type: "success",
            message: "Shift has been approved !",
          })
        );
      }
      else {
        dispatch(
          addToast({
            type: "success",
            message: "Shift has been re-opened !",
          })
        );
      }
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const updatePrestartApproval= createAsyncThunk(
  "shift/updatePrestartApproval",
  async ({prestartId, approval, user}, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().put("/reports/prestartapproval/" + prestartId, {approval, user});
      if (approval) {
        dispatch(
          addToast({
            type: "success",
            message: "Prestart has been approved !",
          })
        );
      }
      else {
        dispatch(
          addToast({
            type: "success",
            message: "Prestart has been re-opened !",
          })
        );
      }
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const updateShiftDelays= createAsyncThunk(
  "shift/updateShiftDelays",
  async ({shiftId, delays, type}, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().put("/reports/operationaldelays/" + shiftId, {delays, type});
      dispatch(
        addToast({
          type: "success",
          message: "Delays updated successfully !",
        })
      );
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const updateShiftMachineHours= createAsyncThunk(
  "shift/updateShiftMachineHours",
  async ({shiftId, hours}, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().put("/reports/machinehours/" + shiftId, {hours});
      dispatch(
        addToast({
          type: "success",
          message: "Machine hours updated successfully !",
        })
      );
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const updatePrestartMachineHours= createAsyncThunk(
  "shift/updatePrestartMachineHours",
  async ({prestartId, engine, percussive}, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().put("/reports/prestartmachinehours/" + prestartId, {engine, percussive});
      dispatch(
        addToast({
          type: "success",
          message: "Machine hours updated successfully !",
        })
      );
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const updateShiftChecklist= createAsyncThunk(
  "shift/updateShiftChecklist",
  async ({prestartId, checklist, type}, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().put("/reports/checklist/" + prestartId, {checklist, type});
      dispatch(
        addToast({
          type: "success",
          message: "Checklist updated successfully !",
        })
      );
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const updateShiftFitness= createAsyncThunk(
  "shift/updateShiftFitness",
  async ({prestartId, fitness}, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().put("/reports/fitness/" + prestartId, {fitness});
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const updatePrestartFluidLevels= createAsyncThunk(
  "shift/updatePrestartFluidLevels",
  async ({prestartId, fuel, oil, water}, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().put("/reports/fluidlevels/" + prestartId, {fuel, oil, water});
      dispatch(
        addToast({
          type: "success",
          message: "Fluid levels updated successfully !",
        })
      );
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const updateDrillingHole= createAsyncThunk(
  "shift/updateDrillingHole",
  async ({taskId, shiftId, hole}, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().put("/reports/drilling/hole/" + taskId + "/" + shiftId, {hole});
      dispatch(
        addToast({
          type: "success",
          message: "Hole updated successfully !",
        })
      );
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const updateExtraHole= createAsyncThunk(
  "shift/updateExtraHole",
  async ({taskId, shiftId, extra}, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().put("/reports/extra/hole/" + taskId + "/" + shiftId, {extra});
      dispatch(
        addToast({
          type: "success",
          message: "Additional holes updated successfully !",
        })
      );
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const removeExtraHole= createAsyncThunk(
  "shift/removeExtraHole",
  async ({taskId, shiftId, extra}, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().put("/reports/extra/remove/" + taskId + "/" + shiftId, {extra});
      dispatch(
        addToast({
          type: "success",
          message: "Additional holes removed successfully !",
        })
      );
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const updateApproval= createAsyncThunk(
  "shift/removeExtupdateApprovalraHole",
  async ({shiftId, prestartId, user, reportType}, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().put(`/reports/approval/${shiftId}/${prestartId}/${user}/${reportType}`);
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const generateHTML= createAsyncThunk(
  "shift/generateHTML",
  async ({report, id}, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().post(`/generatehtml/${report}/${id}`);
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);

export const generatePDF= createAsyncThunk(
  "shift/generatePDF",
  async (html, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosConfig().post(`/generatepdf`, { html }, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/pdf',
        },
      });
      dispatch(
        addToast({
          type: "success",
          message: "PDF generated successfully !",
        })
      );
      return response.data;
    } catch (err) {
      dispatch(modalError(err));
      return rejectWithValue([], { data: err.response.data });
    }
  }
);