import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  Grid,
  Link,
  MenuItem,
  Select,
  Typography,
  Container,
  Chip,
  Tab,
  Pagination,
  IconButton,
  TablePagination
} from "@mui/material";
import {
  TabContext,
  TabList,
  TabPanel
  }
from '@mui/lab';
import { CheckCircle, ChevronLeft, Error, Warning } from "@mui/icons-material";
import { Info } from "@mui/icons-material";
import MachineState from "components/machines/machinestate";
import NotesMachine from "components/machines/notesmachine";
import DataPopupMachine from "components/machines/datapopupmachine";
import { getDateTime } from "utils/tools";
//import actions
import {
  fetchVehicle,
  modifyVehicleState,
  updateStateVehicle,
  fetchVehicleArchives,
  fetchVehicleWithoutLoader
} from "redux/machinesRedux/machinesActions";
import { openModalMaintenance } from "redux/machinesRedux/machinesActions";
//import components
import FormMachine from "components/machines/formmachine";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TableMaintenance from "components/machines/tablemaintenance";
//import scss
import "./infomachine.scss";
import MachineHours from "components/reports/machinehours";
import Fluid from "components/reports/fluid";
import Card from "components/card";
import EditIcon from "static/icons/editicon";
import TrashIcon from "static/icons/trashicon";

function InfoMachine(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // // ----------redux store useDispatch & useSelector --------------------
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  let vehicle = state.machines.displayedVehicle;
  const [originalVehicle, setOriginalVehicle] = React.useState({ state: 0 });

  const [vehicleStatus, setVehicleStatus] = useState(0);
  const [displayedVehicle, setDisplayedVehicle] = useState(undefined);
  const [tab, setTab] = useState('details');
  const [show, setShow] = useState(false);
  const [hours, setHours] = useState({mode: '', id: '', engine: '', percussive: '', updated: ''});
  const [history, setHistory] = useState();
  const [archives, setArchives] = useState();
  const [archivesSize, setArchivesSize] = useState(0);
  const [page, setPage] = useState(0);
  const [historyPage, setHistoryPage] = useState(0);

  const handleTabChange = (event, tab) => {
    if (tab === "details") {
      setShow(false);
      dispatch(fetchVehicleWithoutLoader(displayedVehicle?.id));
    }
    setTab(tab);
    setPage(0);
  }

  useEffect(() => {
    if ('prestartCheck' in vehicle || 'state' in vehicle) {
      setShow(false);
      setDisplayedVehicle(vehicle);

      // if (displayedVehicle === undefined) {
      //   setDisplayedVehicle(vehicle);
      // }
    }
  }, [vehicle])

  React.useEffect(() => {
    if (!vehicle.serial) {
      dispatch(fetchVehicle(props.id));
    }
    if (!originalVehicle.serial && vehicle.serial) {
      setOriginalVehicle(vehicle);
    }
    //console.log(vehicle);
    // eslint-disable-next-line
  }, [vehicle.serial]);

  const goToMachineList = (e) => {
    if (e) e.preventDefault();
    navigate("/machines/list");
    return;
  };

  const updateState = (val) => {
    dispatch(updateStateVehicle({ ...vehicle, state: val }));
  };

  const updateStatus = (event) => {
    const { value } = event?.target;
    setVehicleStatus(value);
  }

  const handleMachineInfoClick = async () => {
    try {
      await dispatch(
        modifyVehicleState({ id: props.id, state: vehicleStatus })
      ).unwrap();
      navigate("/machines/list");
    } catch (error) {
      console.log(error);
    }
  };

  function getVehicleHours(shiftReport) {
    let hours = {
      mode: '',
      id: '',
      engine: 0,
      percussive: 0,
      updated: '',
    };
  
    var engineHoursStartObj,
      engineHoursEndObj,
      percussiveHoursStartObj,
      percussiveHoursEndObj;
    var foundEngineHoursStart = 0;
    var foundEngineHoursEnd = 0;
    var foundPercussiveHoursStart = 0;
    var foundPercussiveHoursEnd = 0;
  
    if (shiftReport && shiftReport.info && shiftReport.info.length > 0) {
      engineHoursStartObj = shiftReport.info.find(
        (x) => x.key === "engineHoursStart"
      );
      engineHoursEndObj = shiftReport.info.find(
        (x) => x.key === "engineHoursEnd"
      );
      percussiveHoursStartObj = shiftReport.info.find(
        (x) => x.key === "percussiveHoursStart"
      );
      percussiveHoursEndObj = shiftReport.info.find(
        (x) => x.key === "percussiveHoursEnd"
      );
    }
  
    if (engineHoursStartObj) {
      foundEngineHoursStart = engineHoursStartObj.value;
    }
    if (engineHoursEndObj) {
      foundEngineHoursEnd = engineHoursEndObj.value;
    }
    if (percussiveHoursStartObj) {
      foundPercussiveHoursStart = percussiveHoursStartObj.value;
    }
    if (percussiveHoursEndObj) {
      foundPercussiveHoursEnd = percussiveHoursEndObj.value;
    }

    hours.mode = foundEngineHoursEnd > 0 ? 'vehicle-shift' : 'vehicle-prestart';
    hours.updated = hours.mode === 'vehicle-shift' ? vehicle?.shiftReport?.updatedAt : vehicle?.prestartCheck?.updatedAt;
    hours.id = foundEngineHoursEnd > 0 ? vehicle?.shiftReport?._id : vehicle?.prestartCheck?.ID;
  
    hours.engine =
      foundEngineHoursEnd > 0 ? foundEngineHoursEnd : foundEngineHoursStart;
    hours.percussive =
      foundPercussiveHoursEnd > 0
        ? foundPercussiveHoursEnd
        : foundPercussiveHoursStart;
  
    setHours(hours);
  }

  const chunkify = function* (itr, size) {
    let chunk = [];
    for (const v of itr) {
      chunk.push(v);
      if (chunk.length === size) {
        yield chunk;
        chunk = [];
      }
    }
    if (chunk.length) yield chunk;
  };

  const handleHolePageChange = (e, page) => {
    setPage(page-1);
  }

  const handleHistoryPageChange = (e, page) => {
    setHistoryPage(page);
  }

  const formatDateToDateTime = (iso) => {
    const date = new Date(iso);
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
    return date.toLocaleString('en-US', options).replaceAll(',', '');
  }

  useEffect(() => {
    if (displayedVehicle) {
      // console.log(displayedVehicle);
      setVehicleStatus(displayedVehicle?.state);
      getVehicleHours(displayedVehicle?.shiftReport);

      if (displayedVehicle?.serviceList?.length > 0) {
        setHistory([...chunkify(displayedVehicle?.serviceList, 5)]);
      }
      else {
        setHistory([])
      }
      dispatch(fetchVehicleArchives(displayedVehicle?.serial)).then((res) => {
        const data = res?.payload;
        setArchivesSize(data?.length);
        if (data?.length > 0) {
          setArchives([...chunkify(sortedArray(data), 10)]);
        }
        else {
          setArchives([]);
        }
      })
    }
  }, [displayedVehicle])

  useEffect(() => {
    if (hours?.mode && history && archives) {
      setShow(true);
    }
  }, [hours, archives])

  const sortedArray = (arr) => {
    return [...arr].sort(
      (a, b) =>
        (a.endedAt > "2000-01-01T01:01:00Z"
          ? a.endedAt
          : a.startedAt > "2000-01-01T01:01:00Z"
          ? a.strtedAt
          : a.createdAt) -
        (b.endedAt > "2000-01-01T01:01:00Z"
          ? b.endedAt
          : b.startedAt > "2000-01-01T01:01:00Z"
          ? b.strtedAt
          : b.createdAt)
    )
    .reverse();
  }

  const getColour = (state) => {
    switch(state) {
      case 0:
        return 'green';
      case 1:
        return 'orange';
      case 2:
        return 'red';
      default:
        return 'black';
    }
  }

  const getStateIcon = (state) => {
    switch (state) {
      case 0:
        return <CheckCircle small="size" sx={{ fontSize: "18px", color: getColour(state)}} />;
      case 1:
        return <Warning small="size" sx={{ fontSize: "18px", color: getColour(state)}} />;
      case 2:
        return <Error small="size" sx={{ fontSize: "18px", color: getColour(state)}} />;
      default:
        break;
    }
  }

  return (
    <Grid container className="info-container">

      <Container lg={12} className="topbar-machine">
          <Grid item lg>
              <Grid item lg={3} xs={12} className="chip">
                  <Chip style={{display: vehicle ? '' : 'none'}} label={vehicle ? vehicle.name : '...'} />
              </Grid>
              <Grid className="details" container gap={8} lg={9} xs={12}></Grid>
          </Grid>
      </Container>

      {show && (
        <Container className="tabs">
          <TabContext value={tab}>
                  <TabList onChange={handleTabChange}>
                      <Tab label={
                          <Typography sx={{fontSize: '18px'}} variant="body2">Machine Details</Typography>
                      } 
                      value="details" />
                      <Tab label={
                          <Typography sx={{fontSize: '18px'}} variant="body2">Machine Event History</Typography>
                      }  value="history" />
                  </TabList>

              <TabPanel value="details" className={tab !== 'details' ? 'details no-pad' : 'details'}>
                <Grid container lg direction="column" className="infomachineGrid">
                  <Grid item xs={12} className="linkBack">
                    <Typography variant="body2">
                      <Link href="#" onClick={goToMachineList}>
                        <ChevronLeft size="small" />{" "}
                        <b>{t("screens.machines_info.text_back_to_machines")}</b>
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={12} className="status">
                    <Grid container direction="row">
                      <Grid item xs={4} lg={4}>
                        <Typography variant="body1">
                          {t("screens.machines_info.text_machine_status")}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Select
                          id="machine_info-select_state"
                          value={vehicleStatus ?? 0}
                          onChange={updateStatus}
                          fullWidth
                          variant="standard"
                        >
                          <MenuItem
                            id="machine_state_menu-operational_state"
                            value={0}
                            className="stateSelect"
                          >
                            <CheckCircle sx={{ fontSize: "18px" }} />
                            <Typography id="machine-status-text" variant="caption">
                              {t("common.machinestates.state_0")}
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            id="machine_state_menu-warning_state"
                            value={1}
                            className="stateSelect"
                          >
                            <Warning sx={{ fontSize: "18px" }} />
                            <Typography id="machine-status-text" variant="caption">
                              {t("common.machinestates.state_1")}
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            id="machine_state_menu-down_state"
                            value={2}
                            className="stateSelect"
                          >
                            <Error sx={{ fontSize: "18px" }} />
                            <Typography id="machine-status-text" variant="caption">
                              {t("common.machinestates.state_2")}
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            id="machine_state_menu-maintenance_state"
                            value={3}
                            className="stateSelect"
                          >
                            <CheckCircle sx={{ fontSize: "18px" }} />
                            <Typography id="machine-status-text" variant="caption">
                              {t("common.machinestates.state_3")}
                            </Typography>
                          </MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={2} className="saveButton">
                        {vehicleStatus !== displayedVehicle?.state && (
                          <Button
                            id="machine_info-save_state_button"
                            variant="contained"
                            color="primary"
                            onClick={handleMachineInfoClick}
                          >
                            {t("screens.machines_info.button_save")}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={12} className="data">
                    <Grid container lg={12} direction="row">
                      <Grid item xs={12} lg={12}>
                        <Grid container direction="column" className="formContainer">
                          <Grid item xs={12} lg={12} className="formtitle">
                            <Typography variant="h6">
                              {t("screens.machines_info.text_info_machine")}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} lg={12} className="formmachine">
                            <FormMachine
                              mode="info"
                              vehicle={vehicle}
                              dataChanged={() => {}}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container lg={12} className="maintenance" gap={4}>
                  <Grid item xs={11} sm={11} md={11} lg={11}>
                    <Card title={'Maintenance History'}
                    content={
                      <Grid container lg={12}>
                          <Grid item xs={12} sm={12} md={12} lg={12} display={'flex'} flexDirection={'column'} className="table">
                              <Grid item className="header" xs={12} sm={12} md={12} lg={12} display={'flex'} flexDirection={'row'}>
                                  <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                      <Typography variant="caption">Date/Time</Typography>
                                  </Grid>
                                  <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                      <Typography variant="caption">Type</Typography>
                                  </Grid>
                                  <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                      <Typography variant="caption">Mechanic</Typography>
                                  </Grid>
                                  <Grid item xs sm md lg display={'flex'} justifyContent={'right'} alignItems={'center'}>
                                      <Typography variant="caption">Actions</Typography>
                                  </Grid>
                              </Grid>
                                {history[page]?.map((h) => {
                                    return (
                                      <Grid item className="header" xs={12} sm={12} md={12} lg={12} display={'flex'} flexDirection={'row'}>
                                        <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'} title={formatDateToDateTime(h?.createdAt)}>
                                            <Typography variant="caption">{formatDateToDateTime(h?.createdAt)}</Typography>
                                        </Grid>
                                        <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'} title={t("common.vehicleevents." + h?.eventType)}>
                                            <Typography variant="caption">{t("common.vehicleevents." + h?.eventType)}</Typography>
                                        </Grid>
                                        <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'} title={h?.reportedBy}>
                                            <Typography variant="caption">{h?.reportedBy}</Typography>
                                        </Grid>
                                        <Grid item xs sm md lg display={'flex'} justifyContent={'center'} alignItems={'center'} className="actions">
                                            <IconButton
                                                title='Info'
                                                onClick={() =>
                                                  dispatch(
                                                    openModalMaintenance({
                                                      vehicle: displayedVehicle,
                                                      maintenanceData: h,
                                                      mode: "info",
                                                    })
                                                  )
                                                }
                                                className="buttonView"
                                              >
                                                <Info className="info" color="primary"/>
                                            </IconButton>
                                            <IconButton
                                                title='Edit'
                                                onClick={() =>
                                                  dispatch(
                                                    openModalMaintenance({
                                                      vehicle: displayedVehicle,
                                                      maintenanceData: h,
                                                      mode: "edit",
                                                    })
                                                  )
                                                }
                                                className="buttonView"
                                              >
                                                <EditIcon color="primary"/>
                                            </IconButton>
                                            <IconButton
                                                title='Delete'
                                                onClick={() =>
                                                  dispatch(
                                                    openModalMaintenance({
                                                      vehicle: displayedVehicle,
                                                      maintenanceData: h,
                                                      mode: "delete",
                                                    })
                                                  )
                                                }
                                                className="buttonView"
                                              >
                                                <TrashIcon/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    )
                                  })}
                          </Grid>
                          <Grid item lg={12} display={'flex'} justifyContent={'center'} marginTop={'8px'}>
                            <Pagination count={history ? history.length : 1} onChange={handleHolePageChange}/>
                          </Grid>
                          <Grid item lg={12} display={'flex'} justifyContent={'right'}>
                            <Button variant="contained"
                            onClick={() =>
                              dispatch(
                                openModalMaintenance({
                                  vehicle: displayedVehicle,
                                  maintenanceData: {},
                                  mode: "add",
                                })
                              )
                            }>
                              <AddCircleOutlineIcon/> Add New Event
                            </Button>
                          </Grid>
                      </Grid>
                    }>
                    </Card>
                  </Grid>
                  
                  {displayedVehicle?.prestartCheck?.ID !== "000000000000000000000000" && (
                    <Grid item xs={11} sm={11} md={11} lg={11}>
                      <Fluid mode='vehicle' typeId={displayedVehicle?.prestartCheck?.ID} fuelLevel={displayedVehicle?.prestartCheck?.fuelLevel} oilLevel={displayedVehicle?.prestartCheck?.rockOilLevel} waterLevel={displayedVehicle?.prestartCheck?.waterLevel} editable={true} collapse={false}></Fluid>
                    </Grid>
                  )}
                  
                  {displayedVehicle?.prestartCheck?.ID !== "000000000000000000000000" && (
                    <Grid item xs={11} sm={11} md={11} lg={11}>
                      <MachineHours mode={hours?.mode} engine={hours?.engine} percussive={hours?.percussive} updated={hours?.updated} editable={true} collapse={false} typeId={hours?.id}></MachineHours>
                    </Grid>
                  )}
                  
                </Grid>
              </TabPanel>

              <TabPanel value="history" className="history">
                  <Grid container lg height={'100%'}>
                    <Grid item lg={12}>
                      <Typography>{vehicle ? vehicle?.name : '...'} Event History</Typography>
                    </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} display={'flex'} flexDirection={'column'} className="table" marginTop={'8px'}>
                        <Grid item className="header" xs={12} sm={12} md={12} lg={12} display={'flex'} flexDirection={'row'} paddingLeft={'12px'}>
                            <Grid item xs sm md lg display={'flex'} justifyContent={'left'} alignItems={'center'}>
                                <Typography variant="caption">Machine Status</Typography>
                            </Grid>
                            <Grid item xs sm md lg display={'flex'} justifyContent={'left'} alignItems={'center'}>
                                <Typography variant="caption">Date/Time</Typography>
                            </Grid>
                            <Grid item xs sm md lg display={'flex'} justifyContent={'left'} alignItems={'center'}>
                                <Typography variant="caption">Type</Typography>
                            </Grid>
                            <Grid item xs sm md lg display={'flex'} justifyContent={'left'} alignItems={'center'}>
                                <Typography variant="caption">Details</Typography>
                            </Grid>
                            <Grid item xs sm md lg display={'flex'} justifyContent={'left'} alignItems={'center'}>
                                <Typography variant="caption">User</Typography>
                            </Grid>
                            <Grid item xs sm md lg display={'flex'} justifyContent={'left'} alignItems={'center'}>
                                <Typography variant="caption">Notes</Typography>
                            </Grid>
                        </Grid>
                          {archives[historyPage]?.map((a) => {
                              return (
                                <Grid item className="header" xs={12} sm={12} md={12} lg={12} display={'flex'} flexDirection={'row'} paddingLeft={'12px'}>
                                  <Grid item xs sm md lg display={'flex'} justifyContent={'left'} alignItems={'center'} title={t("common.machinestates.state_"+a?.state)} gap={3}>
                                      {getStateIcon(a?.state)}
                                      <Typography variant="caption" color={getColour(a?.state)}>{t("common.machinestates.state_"+a?.state)}</Typography>
                                  </Grid>
                                  <Grid item xs sm md lg display={'flex'} justifyContent={'left'} alignItems={'center'} title={formatDateToDateTime(a?.startedAt)}>
                                      <Typography variant="caption" color={a?.state === 2 ? getColour(a?.state) : ''}>{formatDateToDateTime(a?.startedAt)}</Typography>
                                  </Grid>
                                  <Grid item xs sm md lg display={'flex'} justifyContent={'left'} alignItems={'center'} title={t("common.vehicleevents." + a?.eventType)}>
                                      <Typography variant="caption" color={a?.state === 2 ? getColour(a?.state) : ''}>{t("common.vehicleevents." + a?.eventType)}</Typography>
                                  </Grid>
                                  <Grid item xs sm md lg display={'flex'} justifyContent={'left'} alignItems={'center'} title={t("common.vehicleevents." + a?.reportedFaults[0])}>
                                      <Typography variant="caption" color={a?.state === 2 ? getColour(a?.state) : ''}>{t("common.vehicleevents." + a?.reportedFaults[0])}</Typography>
                                  </Grid>
                                  <Grid item xs sm md lg display={'flex'} justifyContent={'left'} alignItems={'center'} title={a?.reportedBy}>
                                      <Typography variant="caption">{a?.reportedBy}</Typography>
                                  </Grid>
                                  <Grid item xs sm md lg display={'flex'} justifyContent={'left'} alignItems={'center'} title={a?.notes}>
                                      <Typography variant="caption">{a?.notes}</Typography>
                                  </Grid>
                              </Grid>
                              )
                            })}
                    </Grid>
                    <Grid item lg={12} display={'flex'} justifyContent={'center'} marginTop={'12px'} className="pagination">
                      <TablePagination sx={{
                        '& .MuiTablePagination-selectLabel, & .MuiTablePagination-select': {
                          display: 'none',
                        },
                        '& .MuiToolbar-root': {
                          paddingLeft: '0px',
                        },
                      }} count={archivesSize} page={historyPage} rowsPerPage={[10]} onPageChange={handleHistoryPageChange}/>
                    </Grid>
                  </Grid>
              </TabPanel>
          </TabContext>
      </Container>
      )}
    </Grid>
  );
}

export default InfoMachine;
