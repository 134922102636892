import styles from "../../styles/_sys.scss"

export default function DrillIcon({ fillColor }) {
    return (
        <svg fill={fillColor ? fillColor : styles.black} width='50px' height='50px' version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 463 463">
            <path d="M311.5,0h-160c-4.143,0-7.5,3.358-7.5,7.5v32c0,8.547,6.953,15.5,15.5,15.5H176v17h-8.5c-4.143,0-7.5,3.358-7.5,7.5v40
                c0,0.338,0.03,0.668,0.073,0.995c0.01,0.075,0.022,0.149,0.034,0.224c0.052,0.318,0.119,0.631,0.21,0.935
                c0.002,0.008,0.004,0.017,0.007,0.025c0.099,0.325,0.223,0.638,0.363,0.943c0.026,0.056,0.053,0.111,0.08,0.166
                c0.144,0.294,0.302,0.581,0.481,0.852c0.004,0.007,0.007,0.014,0.011,0.02l0.01,0.015c0.007,0.011,0.014,0.021,0.021,0.032
                l31.97,47.953c1.391,2.086,3.732,3.34,6.24,3.34H208v280.5c0,2.841,1.605,5.438,4.146,6.708c1.062,0.531,2.21,0.792,3.354,0.792
                c1.594,0,3.178-0.507,4.501-1.5l32-24c1.889-1.417,3-3.639,3-6V175h8.501c2.508,0,4.85-1.253,6.24-3.34l31.968-47.953
                c0.007-0.01,0.014-0.021,0.021-0.032l0.01-0.015c0.004-0.006,0.007-0.014,0.011-0.02c0.18-0.271,0.338-0.558,0.482-0.853
                c0.027-0.055,0.054-0.109,0.079-0.165c0.14-0.305,0.265-0.619,0.363-0.944c0.002-0.008,0.004-0.016,0.006-0.024
                c0.091-0.304,0.158-0.617,0.21-0.936c0.012-0.074,0.024-0.148,0.034-0.223c0.043-0.326,0.073-0.657,0.073-0.995v-40
                c0-4.142-3.357-7.5-7.5-7.5H287V55h16.5c8.547,0,15.5-6.953,15.5-15.5v-32C319,3.358,315.643,0,311.5,0z M240,307.75l-17,12.75
                v-21.25l17-12.75V307.75z M223,339.25l17-12.75v21.25l-17,12.75V339.25z M240,267.75l-17,12.75v-21.25l17-12.75V267.75z M223,379.25
                l17-12.75v21.25l-17,12.75V379.25z M240,227.75l-17,12.75v-21.25l17-12.75V227.75z M223,440.5v-21.25l17-12.75v21.25L223,440.5z
                M240,187.75l-17,12.75V175h17V187.75z M259.487,160h-55.973l-22-33h99.972L259.487,160z M288,112H175V87h113V112z M272,72h-81V55
                h81V72z M304,39.5c0,0.276-0.225,0.5-0.5,0.5h-144c-0.275,0-0.5-0.224-0.5-0.5V15h145V39.5z"/>
        </svg>
    );
  }