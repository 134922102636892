import React, {Fragment, useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";

import Card from "components/card";
import { Typography, IconButton, Grid, Button } from "@mui/material";

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import adobePdfLogo from 'static/images/adobe-pdf-icon.jpg';
import "./approval.scss";
import styles from "../../styles/_sys.scss";
import { TypographySpan } from "components/styledComponents";
import { updateShiftApproval, updatePrestartApproval, generatePDF, generateHTML } from "redux/shiftRedux/shiftActions";

function Approval({approved, approvalHistory, editable, mode, onApprovalClick}) {
    const dispatch = useDispatch();
    const reducerState = useSelector((state) => state);
    const nullDate = "0001-01-01T00:00:00Z";

    const [updatedApproved, setUpdatedApproved] = useState();
    const [updatedApprovalHistory, setUpdatedApprovalHistory] = useState();

    const [showCard, setShowCard] = useState(false);

    useEffect(() => {
        setUpdatedApproved(approved);
        setUpdatedApprovalHistory(approvalHistory);
        setShowCard(true);
    }, []);


    const ISOToTime = (date) => {
        const isoDate = new Date(date);
      
        const timeString = isoDate?.toLocaleTimeString('en-au', { 
          hour12: false,
          hour: '2-digit',
          minute: '2-digit',
        });

        return timeString;
    };

    const ISOToDate = (isoDateString) => {
        const dateObject = new Date(isoDateString);
        
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1;
        const day = dateObject.getDate();
        
        const formattedDate = `${day}/${month}/${year}`;
        
        return formattedDate;
    }

    const handleGeneratePDF = async () => {
        try {
            let shiftDate = new Date(reducerState?.shift?.shiftData?.shiftReport?.createdAt);
            let shiftDateStr = `_${shiftDate.getDate()}-${shiftDate.getMonth()+1}-${shiftDate.getFullYear()}`;
            let vehicleSerial = reducerState?.shift?.shiftData?.shiftReport?.vehicleSerial.toUpperCase();
            let filename = mode === "shift" ? "Shift_Report_" + vehicleSerial + shiftDateStr : "Pre_Start_Report_" + vehicleSerial + shiftDateStr;
            dispatch(generateHTML({report: mode, id: reducerState?.shift?.shiftData?.shiftReport?._id})).then(async (res) => {
                const htmlContent = formatIfIncludesDate(res?.payload);
                dispatch(generatePDF(htmlContent)).then((data) => {
                    const pdfData = data?.payload;
                    const url = URL.createObjectURL(new Blob([pdfData], { type: "application/pdf" }));
                    downloadPDF(url, filename);
                })
            });
        } catch (error) {
          console.error('Error generating PDF:', error);
        }
      };

      const downloadPDF = (url, filename) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = `${filename}.pdf`;
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }

    const approve = () => {
        if (mode === 'shift') {
            dispatch(updateShiftApproval({shiftId: reducerState?.shift?.shiftData?.shiftReport?._id, approval: true, user: reducerState?.common?.user?.username}));
        }
        if (mode === 'pre-start') {
            dispatch(updatePrestartApproval({prestartId: reducerState?.shift?.shiftData?.preStartChecklist?.ID, approval: true, user: reducerState?.common?.user?.username}));
        }
        setUpdatedApproved(true);
        let history = [];
        if (updatedApprovalHistory) {
            history = [...updatedApprovalHistory];
        }
        history.unshift({description: "Report Approved", user: reducerState?.common?.user?.username, createdAt: new Date().toISOString()});
        setUpdatedApprovalHistory([...history]);
        onApprovalClick({approval: true, mode: mode});
    }

    const reOpen = () => {
        if (mode === 'shift') {
            dispatch(updateShiftApproval({shiftId: reducerState?.shift?.shiftData?.shiftReport?._id, approval: false, user: reducerState?.common?.user?.username}));
        }
        if (mode === 'pre-start') {
            dispatch(updatePrestartApproval({prestartId: reducerState?.shift?.shiftData?.preStartChecklist?.ID, approval: false, user: reducerState?.common?.user?.username}));
        }
        setUpdatedApproved(false);
        let history = [];
        if (updatedApprovalHistory) {
            history = [...updatedApprovalHistory];
        }
        history.unshift({description: "Report Re-opened", user: reducerState?.common?.user?.username, createdAt: new Date().toISOString()});
        setUpdatedApprovalHistory([...history]);
        onApprovalClick({approval: false, mode: mode});
    };

    const formatDate = (iso) => {
        if (iso === nullDate) {
          return 'N/A';
        }
        const date = new Date(iso);
        return `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`;
      }
  
      const formatTime = (iso) => {
        if (iso === nullDate) {
          return 'N/A';
        }
        const date = new Date(iso);
        const timeString = date?.toLocaleTimeString('en-au', { 
          hour12: false,
          hour: '2-digit',
          minute: '2-digit',
        });
        return timeString;
      }

    const formatIfIncludesDate = (str) => {
        const regex = /\b([DT])(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}(?:\.\d{1,3})? [+-]\d{4} \w{3})\b/g;
        const matches = [...str.matchAll(regex)];

        if (matches.length > 0) {
            let updated = str;
            matches.forEach((match) => {
                if (match[0][0] === "D") {
                    updated = updated.replace(match[0], formatDate(match[0].substring(1)));
                } 
                if (match[0][0] === "T") {
                    updated = updated.replace(match[0], formatTime(match[0].substring(1)));
                } 
            });
            return updated;
          } else {
              return str;
          }
      };

  return (
    <>
        {showCard && (
            <Card title='Shift Report Status and History' 
            content={
                <div className="approval">
                    <Grid className={updatedApprovalHistory?.length > 0 ? '' : 'disabled'}>
                        <Grid container className="status">
                            <Grid container className="line">
                                <Grid xs={9} sm={9} md={9} lg={9} item className="label" sx={{backgroundColor: updatedApprovalHistory?.length > 0 ? (updatedApproved ? styles.approvalGreen : styles.approvalOrange) : styles.approvalGrey}}>
                                    <TypographySpan>Report Status: &nbsp;&nbsp;  
                                        {updatedApprovalHistory?.length > 0 ? (
                                            <Fragment>
                                                {updatedApproved ? (
                                                    <Grid item alignItems={'center'} display={'flex'}>
                                                        <TypographySpan fontWeight={600}>Approved</TypographySpan>
                                                        <CheckBoxIcon sx={{width: '25px', height: '25px', color: 'green !important', marginTop: '8px'}}/> 
                                                    </Grid>
                                                ) : (
                                                    <Grid item alignItems={'center'} display={'flex'}>
                                                        <TypographySpan fontWeight={600}>Awaiting Approval</TypographySpan>
                                                        <InsertDriveFileOutlinedIcon sx={{width: '25px', height: '25px', marginTop: '6px', color: 'black !important'}}/>
                                                    </Grid>
                                                )}
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <TypographySpan fontWeight={600}>Not Yet Submitted</TypographySpan>
                                            </Fragment>
                                        )}
                                    </TypographySpan></Grid>
                                <Grid xs={3} sm={3} md={3} lg={3} item className="data" sx={{backgroundColor: updatedApprovalHistory?.length > 0 ? (updatedApproved ? styles.approvalGreen : styles.approvalOrange) : styles.approvalGrey}}>
                                    {updatedApproved ? (
                                        <Button
                                            onClick={reOpen}
                                            key='reopen-report'
                                            id='reopen-report'
                                            variant="contained"
                                            size="small">
                                            <TypographySpan>Re-open Report</TypographySpan>
                                        </Button>
                                    ) 
                                    : (
                                        <Button
                                            onClick={approve}
                                            key='approve-report'
                                            id='approve-report'
                                            variant="contained"
                                            size="small">
                                            <TypographySpan>Approve Report</TypographySpan>
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={updatedApprovalHistory?.length > 0 ? '' : 'disabled'}>
                        <Grid container className="history">
                            <Grid container className="line">
                                <Grid xs={12} sm={12} md={12} lg={12} item className="header"><TypographySpan>Status History</TypographySpan></Grid>
                            </Grid>
                        </Grid>
                        {updatedApprovalHistory?.map((x) => {
                            return (
                                <Grid container className="history">
                                    <Grid container className="line">
                                        <Grid xs={4} sm={4} md={4} lg={4} item className="label"><TypographySpan>{x?.description}</TypographySpan></Grid>
                                        <Grid xs={4} sm={4} md={4} lg={4} item className="data"><TypographySpan>{x?.user}</TypographySpan></Grid>
                                        <Grid xs={4} sm={4} md={4} lg={4} item className="data"><TypographySpan>{ISOToDate(x?.createdAt)} {ISOToTime(x?.createdAt)}</TypographySpan></Grid>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                    <Grid>
                        <Grid container className={updatedApproved ? 'generate' : 'generate disabled'}>
                            <Grid container className="line">
                                <Grid xs={10} sm={10} md={10} lg={10} item className="label">
                                    <img alt="adobe-pdf-logo" src={adobePdfLogo}/>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a onClick={handleGeneratePDF}>
                                        <TypographySpan sx={{textDecoration: 'underline', cursor: 'pointer'}}>
                                            Generate and download an Adobe PDF version of this report
                                        </TypographySpan>
                                    </a>
                                </Grid>
                                <Grid xs={2} sm={2} md={2} lg={2} item className="data">
                                    <IconButton onClick={handleGeneratePDF}>
                                        <FileDownloadOutlinedIcon color="primary" sx={{width: '60px', height: '60px'}}/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            }
            backgroundColour='#98a7c7'
            titleColour="white"
            canCollapse={false}>
            </Card>
        )}
    </>
  );
}

export default Approval;
